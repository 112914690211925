import { logger } from '@unitoio/sherlock';

/**
 * Helper function to report an exception to DD.
 * @param {Error} error
 * @param {string} [message]
 * @param {{[key:string]: any}} [context]
 */
function reportException(error, message, context) {
  // This is meant to be used casually and frequently, so typing as not async,
  // even if it is, to not have to `eslint-ignore no-dangling-promises` each call.
  const enrichedContext = {
    ...(context ?? {}),
    correlationId: error.correlationId,
  };

  logger.error(`Error: ${message || ''}\n${error.message}\n${error.stack} ${JSON.stringify(enrichedContext)}`);
}

function reportInfo(message, context) {
  // This is meant to be used casually and frequently, so typing as not async,
  // even if it is, to not have to `eslint-ignore no-dangling-promises` each call.
  logger.info(message, context || {});
}

function reportWithFunnel(funnelName) {
  return {
    reportException: (error, message, context = {}) =>
      reportException(error, message, { ...context, funnel: { name: funnelName, ...context.funnel } }),
    reportInfo: (message, context = {}) =>
      reportInfo(message, { ...context, funnel: { name: funnelName, ...context.funnel } }),
  };
}

export { logger, reportException, reportInfo, reportWithFunnel };
