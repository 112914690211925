import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import { color } from '../../theme';

const StyleDefault = styled.div`
  margin: 0.5rem 0;
`;

const StyleError = styled(StyleDefault)`
  border-color: ${color.warning};
`;

const WrapperDropdownIndicator = styled.div`
  height: 100%;
`;

const customStyles = {
  control: (styles) => ({
    ...styles,
    height: '3rem',
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, top: '36%' }),
  singleValue: (styles) => ({ ...styles, top: '36%' }),
};

const IndicatorSeparator = (props) => {
  components.IndicatorSeparator = null;
  return components.IndicatorSeparator;
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <WrapperDropdownIndicator>
        <components.DropdownIndicator {...props} />
      </WrapperDropdownIndicator>
    )
  );
};

export default class SelectInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.object,
    formatGroupLabel: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onMenuOpen: PropTypes.func,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.object,
  };

  render() {
    const { disabled, error, ...rest } = this.props;
    const StyleWrapper = error ? StyleError : StyleDefault;

    return (
      <StyleWrapper>
        <Select
          {...rest}
          components={{
            DropdownIndicator,
            IndicatorSeparator,
          }}
          styles={customStyles}
          isDisabled={disabled}
        />
      </StyleWrapper>
    );
  }
}
