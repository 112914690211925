import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, tokens, Typography } from '@unitoio/mimics';

import { getBoardSyncedTasksWithCountCached } from '../../util/api';
import * as TrelloUtils from '../../util/trello';
import { FieldsCheckList, CheckListItem, Button, Tooltip } from '../index';
import withStorage from '../withStorage';
import { trackEvent, EVENTS } from '../../util/tracking';
import { INTERNAL_SOURCES, PLAN_FEATURE_UNLIMITED } from '../../consts';
import { getQueryParameter } from '../../util/fetch';
import { isFeatureValueUnlimited, getColorMode } from '../../util/helpers';
import { color } from '../../theme';

const DisplayWrapper = styled(Box)`
  display: ${(props) => (props.defaultListName === null ? 'none' : 'block')};
`;

const DefaultListInput = styled.input`
  height: 48px;
  margin-top: 2px;
`;

const QuestionMark = styled.div`
  padding: 0.25rem;
  border: 1px solid black;
  display: inline-flex;
  color: black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PlanRejectionReasons = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 2px;
  border: 1px solid ${color.warning};
  font-size: 0.9rem;
`;

const DEFAULT_DESTINATION_LIST = 'DEFAULT_DESTINATION_LIST';
const BUTTON_CAPTIONS = {
  SAVED: 'Saved',
  SAVE: 'Save',
};

const TWO_WAY_BOARD_SYNC_ENABLED = 'TWO_WAY_BOARD_SYNC_ENABLED';

class SettingsPopupComponent extends Component {
  state = {
    fields: {},
    isFieldsDirty: false,
    isCurrentMemberAdmin: false,
    defaultListName: null,
    savingDefaultListName: false,
    buttonCaption: BUTTON_CAPTIONS.SAVE,
    showBoardSyncButton: false,
    showTooltip: false,
    nbMaxFields: 0,
    planRejectionReasons: [],
  };

  async componentDidMount() {
    this.props.t.render(this.mapTtoState);
    this.setState({
      showBoardSyncButton: await TrelloUtils.is2WayBoardSyncEnabled(),
    });
  }

  componentDidUpdate() {
    this.props.t.sizeTo('#root');

    if (this.state.buttonCaption === BUTTON_CAPTIONS.SAVED) {
      this.buttonCaptionTimeout = setTimeout(() => {
        this.setState({ buttonCaption: BUTTON_CAPTIONS.SAVE });
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.buttonCaptionTimeout);
  }

  buildFields = (fields) => {
    const visibleFields = {};
    for (const [key, value] of Object.entries(fields)) {
      if (!value.isHiddenFromUsers) {
        visibleFields[key] = value;
      }
    }

    return { visibleFields };
  };

  mapTtoState = async () => {
    const { defaultListName, providerIdentityDisplayName } = await TrelloUtils.getSyncAccountData();
    const fields = await TrelloUtils.getFieldSettings();
    const { visibleFields, planRejectionReasons = [] } = this.buildFields(fields);

    this.setState({
      fields: visibleFields,
      isCurrentMemberAdmin: await TrelloUtils.isCurrentMemberAdmin(),
      defaultListName,
      syncAccountName: providerIdentityDisplayName,
      nbMaxFields: PLAN_FEATURE_UNLIMITED,
      planRejectionReasons,
    });
  };

  getNbCheckedFields = () => Object.values(this.state.fields).reduce((sum, field) => (field.sync ? sum + 1 : sum), 0);

  isOverMaxFieldsLimit = () => {
    const { nbMaxFields } = this.state;
    const nbCheckedFields = this.getNbCheckedFields();
    return !isFeatureValueUnlimited(nbMaxFields) && nbCheckedFields >= nbMaxFields;
  };

  onFieldToggle = async (key) => {
    const isTogglingOn = !this.state.fields[key].sync;
    const overMappedFieldsLimit = this.isOverMaxFieldsLimit();

    const blockToggleOn = isTogglingOn && overMappedFieldsLimit;
    if (blockToggleOn) {
      return null;
    }

    await TrelloUtils.toggleFieldToSync(key);

    const { syncedTasks } = await getBoardSyncedTasksWithCountCached(this.props.t);
    const boardHasSyncs = Object.entries(syncedTasks).length > 0;
    this.setState({ isFieldsDirty: boardHasSyncs });
  };

  onDefaultDestinationListToggle = async () => {
    const { defaultListName } = this.state;
    const newDefaultListName = defaultListName === null ? '' : null;
    if (newDefaultListName === null) {
      await TrelloUtils.saveDefaultListName(this.props.t, null);
    }
    trackEvent(
      EVENTS.USER_MENU_SETTINGS_ACTION,
      {
        action_name: `turned enable default destination list ${newDefaultListName === null ? 'OFF' : 'ON'}`,
      },
      this.props.t,
    );
    this.setState({ defaultListName: newDefaultListName });
  };

  onToggleUnitoSyncButton = async () => {
    const { showBoardSyncButton } = this.state;
    this.setState(
      {
        showBoardSyncButton: !showBoardSyncButton,
      },
      async () => {
        await TrelloUtils.set2WayBoardSyncEnabled(this.props.t, this.state.showBoardSyncButton);
        trackEvent(
          EVENTS.USER_MENU_SETTINGS_ACTION,
          {
            action_name: `turned 2 way board sync ${this.state.showBoardSyncButton ? 'ON' : 'OFF'}`,
          },
          this.props.t,
          INTERNAL_SOURCES.MIRROR,
          () => {
            // needs to be a callback to tracking, otherwise the trello modal call unmounts the originator and cancels the tracking call
            if (this.state.showBoardSyncButton) {
              this.props.t.modal({
                fullscreen: false,
                height: 500,
                title: 'Discover 2-Way Board Syncing with Unito',
                url: './discover-board-sync',
              });
            }
          },
        );
      },
    );
  };

  handleOnChange = (event) => {
    this.setState({ defaultListName: event.target.value });
  };

  handleOnSave = async () => {
    this.setState({ savingDefaultListName: true });
    trackEvent(
      EVENTS.USER_MENU_SETTINGS_ACTION,
      {
        action_name: 'saved a default destination list',
      },
      this.props.t,
    );
    const { defaultListName } = this.state;
    const trimmedListName = defaultListName.trim();
    await TrelloUtils.saveDefaultListName(this.props.t, trimmedListName === '' ? null : trimmedListName);
    this.setState({
      savingDefaultListName: false,
      buttonCaption: BUTTON_CAPTIONS.SAVED,
    });
  };

  getPlanRejectionReasonsAlert = () => {
    const { planRejectionReasons } = this.state;
    if (planRejectionReasons?.length === 0) {
      return null;
    }

    return (
      <PlanRejectionReasons>
        <ul>
          {planRejectionReasons.map((rejectionText, index) => (
            <li key={index}>{rejectionText}</li>
          ))}
        </ul>
      </PlanRejectionReasons>
    );
  };

  render() {
    const fields = Object.entries(this.state.fields);
    const { defaultListName, isCurrentMemberAdmin, showBoardSyncButton, showTooltip } = this.state;
    const provider = getQueryParameter('provider');
    const colorMode = getColorMode();
    const twoWaySyncTerm = provider?.toLowerCase() === 'trello' ? 'Board' : 'Unito';

    return (
      <div>
        {this.state.isFieldsDirty && (
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
            <Typography color={tokens.colors.content.destructive.default}>
              Heads up! The new fields settings will only apply to cards Mirrored on new boards.
            </Typography>
          </Box>
        )}
        <Box m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3]}>
            <Typography variant="h5" color={colorMode}>
              Mirrored fields
            </Typography>
          </Box>

          <FieldsCheckList items={fields} isAdmin={isCurrentMemberAdmin} handleToggle={this.onFieldToggle} />
          {this.getPlanRejectionReasonsAlert()}
        </Box>

        <Box m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3]}>
            <Typography variant="h5" color={colorMode}>
              Default destination
            </Typography>
          </Box>

          <CheckListItem
            value={DEFAULT_DESTINATION_LIST}
            displayName={
              defaultListName || defaultListName === ''
                ? 'Default destination list enabled'
                : 'Enable default destination list'
            }
            checked={defaultListName !== null}
            disabled={!isCurrentMemberAdmin}
            handleToggle={this.onDefaultDestinationListToggle}
          />
          <DisplayWrapper
            m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3]}
            defaultListName={defaultListName}
          >
            <DefaultListInput
              type="text"
              disabled={defaultListName === null || !isCurrentMemberAdmin}
              value={defaultListName === null ? '' : defaultListName}
              placeholder="Enter destination list title..."
              maxLength="50"
              onChange={this.handleOnChange}
              style={{
                width: '240px',
                border: 'none',
                marginBottom: '0px',
              }}
            />
            <Box m={[tokens.spacing.s3, tokens.spacing.s0]}>
              <Typography variant="body2" color={colorMode}>
                All cards in this board will mirror to the above list in all destination boards. If no list is found,
                the card will mirror to the list on the far-left.
              </Typography>
            </Box>

            <Button
              disabled={defaultListName === null || !isCurrentMemberAdmin}
              onClick={this.handleOnSave}
              type="button"
              btnStyle="primary"
              size="sm"
              submitting={false}
            >
              {this.state.buttonCaption}
            </Button>
          </DisplayWrapper>
        </Box>

        <Box m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3]}>
            <Typography variant="h5" color={colorMode}>
              2-Way Board syncing enabled{' '}
              <QuestionMark
                onClick={() => {
                  this.setState((newState) => ({ showTooltip: !newState.showTooltip }));
                }}
              >
                ?
              </QuestionMark>
              {showTooltip && (
                <Tooltip>
                  Ensure you don't also have the 2-Way Board Syncing power-up installed separately, otherwise enabling
                  this option will cause a conflict between them.
                </Tooltip>
              )}
            </Typography>
          </Box>

          <CheckListItem
            value={TWO_WAY_BOARD_SYNC_ENABLED}
            displayName={
              !showBoardSyncButton ? `Show ${twoWaySyncTerm} Sync button` : `Hide ${twoWaySyncTerm} Sync button`
            }
            checked={showBoardSyncButton}
            disabled={!isCurrentMemberAdmin}
            handleToggle={this.onToggleUnitoSyncButton}
          />
        </Box>

        <Box m={[tokens.spacing.s5, tokens.spacing.s0, tokens.spacing.s2, tokens.spacing.s0]}>
          <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s3]}>
            <Typography variant="h5" color={colorMode}>
              Your sync account is:
            </Typography>{' '}
            {this.state.syncAccountName}
          </Box>
        </Box>
      </div>
    );
  }
}

export const SettingsPopup = withStorage(SettingsPopupComponent);
