import React, { Component } from 'react';
import { withFormik } from 'formik';

import { ErrorBoundary } from '@unitoio/sherlock';

import { BackOfCardSyncInner } from './BackOfCardSyncInner';
import withStorage from '../withStorage';
import { closePopup, createSyncPlaceholder, getSyncAccountData } from '../../util/trello';
import { reportWithFunnel } from '../../util/logger';
import { trackEvent, EVENTS } from '../../util/tracking';
import { createCardSync } from '../../util/api';
import { API_ERRORS, MIRROR_CREATION } from '../../consts';

const mirrorCreationLogger = reportWithFunnel(MIRROR_CREATION);

function BackOfCardSyncWithErrorBoundary(props) {
  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error, { componentStack }, errMessageContext) =>
        mirrorCreationLogger.reportException(error, error.message, { ...errMessageContext, componentStack })
      }
    >
      <BackOfCardSyncInner {...props} />
    </ErrorBoundary>
  );
}

const FormikSyncForm = withFormik({
  validate: (values, props) => {
    const errors = {};
    return errors;
  },

  mapPropsToValues: (props) => ({
    boards: props.boards,
    lists: props.lists,
  }),

  handleSubmit: async (values, { props, setSubmitting, resetForm, setErrors }) => {
    const boardUniqueId = values.board.value;
    const columnId = values.list.value;
    const boardName = values.board.label;
    const listName = values.list.label;
    trackEvent(EVENTS.NEW_SYNC_SUBMIT, { link: { kind: 'taskSync' } });

    try {
      const sync = await createCardSync(boardUniqueId, columnId);
      await createSyncPlaceholder(sync.link._id, boardUniqueId, boardName, listName);
      await closePopup();
    } catch (error) {
      // The only reason why we want to fetch the sync account data is to add more data to the error log.
      // Therefore, if getting the sync account data fails, simply swallow the error and keep going.
      let syncAccountData;
      try {
        syncAccountData = await getSyncAccountData(props.t);
      } catch (err) {}

      const { name } = error;
      const { message } = API_ERRORS[name] || API_ERRORS.DefaultCreateError;
      mirrorCreationLogger.reportException(error, 'Error while issuing card sync request', {
        syncAccountData,
        boardName,
        boardUniqueId,
        listName,
        columnId,
      });
      trackEvent(EVENTS.NEW_SYNC_BLOCKED, {
        link: {
          kind: 'taskSync',
        },
        reason: message,
      });
      setErrors({ name, message });
    } finally {
      setSubmitting(false);
    }
  },
  displayName: 'BackOfCardSyncInner',
})(BackOfCardSyncWithErrorBoundary);

export default class BackOfCardSyncFormComponent extends Component {
  render() {
    return (
      <div className="back-of-card">
        <FormikSyncForm />
      </div>
    );
  }
}

export const BackOfCardSyncForm = withStorage(BackOfCardSyncFormComponent);
