import React, { Component } from 'react';
import styled from 'styled-components';

import { getModalForUnitoURL, getSyncAccountData } from '../../util/trello';
import { getUnitoPricingRouteForOrg } from '../../util/api';
import { trackEvent, EVENTS } from '../../util/tracking';
import { Button, Text } from '../index';
import { delay, formatDate, getPowerUpName } from '../../util/helpers';
import withStorage from '../withStorage';
import URL_UNITO_CHOOSE_PLAN_IMG from '../../images/choose-plan-image.png';
import TREE_SWING_IMG from '../../images/Tree-swing.png';
import { UNITO_WORKSPACE_STATUSES, INTERNAL_SOURCES } from '../../consts';

const LogoWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const TextWrapper = styled.div`
  padding: 0.4rem 1.5rem 1.5rem 1.5rem;
`;

const ButtonWrapper = styled.div`
  padding: 0 1.5rem 1.5rem 1.5rem;
`;

const EVENT_ACTION_NAMES = {
  churned: 'viewed Mirror account churned - choose a plan',
  'trial-expired': 'viewed Mirror trial expired - choose a plan',
};

const LOGO_WIDTH = 110;
const LOGO_HEIGHT = 160;
const IMG_WIDTH = 195;
const IMG_HEIGHT = 154;

export class PowerupBlockerComponent extends Component {
  state = {
    isLoading: true,
  };

  async componentDidMount() {
    trackEvent(EVENTS.USER_MENU_ACTION, {
      action_name: EVENT_ACTION_NAMES[this.props.t.arg('subscriptionStatus')],
    });
    try {
      this.fetchResources();
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate() {
    this.props.t.sizeTo('#root');
  }

  fetchResources = async () => {
    const { workspaceId, validUntil } = await getSyncAccountData();
    this.setState({
      workspaceId,
      validUntil,
    });
  };

  openChoosePlanWindow = async () => {
    trackEvent(EVENTS.USER_MENU_ACTION, {
      action_name: 'clicked Choose a plan',
    });
    // opening a modal kills the iframe in which this component lives and stalls the request to segment
    // takes approx. 250ms to go through, put 300 to be safe. Not perceptible to the user.
    await delay(300);
    getModalForUnitoURL(this.props.t, getUnitoPricingRouteForOrg(this.state.workspaceId));
  };

  renderChurnedStatus = () => {
    const { validUntil } = this.state;
    const isMirrorPowerUp = this.props.t.arg('source') === INTERNAL_SOURCES.MIRROR;

    return (
      <div>
        <LogoWrapper>
          <img width={IMG_WIDTH} height={IMG_HEIGHT} src={TREE_SWING_IMG} alt="Mirror by Unito" />
        </LogoWrapper>
        <TextWrapper>
          <Text type="centered">
            <p>
              <strong>Is this goodbye?</strong>
            </p>
            <p>
              Your subscription was canceled on <strong>{formatDate(validUntil, { longFormat: true })}</strong>, but you
              can still renew to get access to all your {isMirrorPowerUp ? 'mirrors' : 'flows'} and keep collaborating.
            </p>
          </Text>
        </TextWrapper>
      </div>
    );
  };

  renderTrialExpiredStatus = () => {
    const powerUpName = getPowerUpName(this.props.t.arg('source'));
    return (
      <div>
        <LogoWrapper>
          <img width={LOGO_WIDTH} height={LOGO_HEIGHT} src={URL_UNITO_CHOOSE_PLAN_IMG} alt="Mirror by Unito" />
        </LogoWrapper>
        <TextWrapper>
          <Text type="centered">Choose a plan to keep enjoying {powerUpName}</Text>
        </TextWrapper>
      </div>
    );
  };

  renderFromStatus = (status) => {
    switch (status) {
      case UNITO_WORKSPACE_STATUSES.CHURNED:
        return this.renderChurnedStatus();
      case UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED:
        return this.renderTrialExpiredStatus();
      default:
        return;
    }
  };

  render() {
    const { isLoading } = this.state;
    const status = this.props.t.arg('subscriptionStatus');

    return (
      <div>
        {!isLoading && this.renderFromStatus(status)}
        <ButtonWrapper>
          <Button size="block" onClick={this.openChoosePlanWindow} btnStyle="primary">
            See the plans
          </Button>
        </ButtonWrapper>
      </div>
    );
  }
}

export const PowerupBlocker = withStorage(PowerupBlockerComponent);
