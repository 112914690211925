import { Component } from 'react';
import PropTypes from 'prop-types';

import withStorage from '../withStorage';

export class RedirectToComponent extends Component {
  static propTypes = {
    t: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { t } = this.props;
    window.location.replace(t.arg('url'));
  }

  render() {
    return null;
  }
}

export const RedirectTo = withStorage(RedirectToComponent);
