import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from '@unitoio/mimics';

import { color, fontSize, borderRadius } from '../../theme';

const Check = styled.div`
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  font-size: ${fontSize.medium};
  color: ${(props) => (props.readOnly || props.blocked || props.disabled ? color.whisper : props.colorMode)};

  &:hover {
    cursor: ${(props) => (props.readOnly || props.blocked || props.disabled ? 'default' : 'pointer')};
    border-radius: ${borderRadius.standard};
    background-color: ${(props) => (props.readOnly ? color.light : color.secondary)};
    color: ${(props) => (props.readOnly ? color.whisper : color.light)};

    & > .icon-check {
      color: ${(props) => (props.readOnly ? color.whisper : color.light)};
    }
  }

  & > .icon-check {
    float: right;
  }
`;

function CheckListItem({ checked, disabled, displayName, handleToggle, readOnly, value, blocked, colorMode }) {
  // TODO migrate to using a *mimics* icon
  return (
    <Check
      colorMode={colorMode}
      readOnly={readOnly}
      blocked={blocked}
      disabled={disabled}
      onClick={readOnly || blocked || disabled ? undefined : () => handleToggle(value)}
    >
      {displayName}
      {checked && (
        <span className="icon-check">
          <Icon name="check" kind="solid" size="lg" />
        </span>
      )}
      {blocked && (
        <span className="icon-check">
          <Icon name="ban" kind="solid" size="lg" />
        </span>
      )}
    </Check>
  );
}

CheckListItem.propTypes = {
  checked: PropTypes.bool,
  blocked: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

CheckListItem.defaultProps = {
  blocked: false,
  checked: false,
  readOnly: false,
  disabled: false,
};

export default CheckListItem;
