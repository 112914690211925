import { v4 } from 'uuid';

import { ID_SEPARATOR, REQUEST_METHODS, INTERNAL_SOURCES, DEFAULT_CONTAINER_TYPE, DEFAULT_ITEM_TYPE } from '../consts';
import { getProductNameBySource } from '../util/tracking';
import { callMaestro } from './fetch';
import * as Cache from './cache';
import {
  forceFrontOfCardsUpdate,
  getSyncAccountData,
  getContext,
  getSyncsCountForCurrentCard,
  getFieldSettings,
  T,
  getSyncAccountDataAtSecret,
  getSyncPlaceholders,
  updateSyncPlaceholders,
  deleteSyncPlaceholder,
} from './trello';
import { logger, reportException, reportInfo } from './logger';
import { BACKEND_SYNC_STATES, UNITO_APP_URL, SYNC_STATE_INPROGRESS, MAESTRO_BASE_API } from '../consts';

export const getUnitoBillingRouteForOrg = (orgId) =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/organizations/${orgId}/billing/task-sync`;
export const getUnitoPricingRouteForOrg = (orgId) =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/organizations/${orgId}/pricing/task-sync`;

export const getUnitoPricingRouteForOrgForProjectSync = (orgId) =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/organizations/${orgId}/pricing/project-sync`;

export const getUnitoCreateFlowsRouteForProjectSync = ({ containerId, profileId }) =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/flow-builder/add/tool-selection?containerId=${containerId}&itemType=card&containerType=board&profileId=${profileId}&providerNameA=trello`;

export const getUnitoCreateFlowsRouteForReportSync = () => `${UNITO_APP_URL}/#/embed/trello/dashboard/one-click-export`;

export const getUnitoMyFlowsRoute = () => `${UNITO_APP_URL}/#/embed/trello/dashboard`;

export const getUnitoAccountRouteForOrgForProjectSync = (orgId) =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/organizations/${orgId}/billing/project-sync/overview`;

export const getUnitoAccountItemsInSyncRouteForOrgForProjectSync = (orgId) =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/organizations/${orgId}/items-kept-in-sync`;

export const getUnitoAccountChangesRouteForOrgForProjectSync = () =>
  `${UNITO_APP_URL}/#/embed/trello/dashboard/activityLogs`;

// Something expiring fast, caching is not the point here, we just want
// to 'cache' between backsection init and backsection component
const CARDSYNC_CACHE_TTL = 2000;
const TASK_COUNT_CACHE_TTL = 5000;
const PLAN_INFO_CACHE_TTL = 60000; // Arbitrary number for now. We'll see how it goes...
const ORGANIZATION_CACHE_TTL = 60000;
const REHYDRATE_CACHE_TTL = 60000;
const BOARD_SYNC_CACHE_NAMESPACE = 'boardCardSyncs';
const CARDSYNC_CACHE_NAMESPACE = 'cardSyncs';
const PLAN_INFO_CACHE_NAMESPACE = 'planInfo';
const ORGANIZATION_CACHE_NAMESPACE = 'organization';
const TASK_COUNT_CACHE_NAMESPACE = 'taskCount';
const REHYDRATE_CACHE_NAMESPACE = 'refreshToken';

export const getAuthRoute = async (source = INTERNAL_SOURCES.MIRROR, otherProviderName) => {
  const productName = getProductNameBySource(source);

  let { member, organization, board } = await getContext();
  // in the case where the user is not part of the team to which the board belongs, but is still
  // a member of the board, fetch the organization from the syncAccountData
  if (!organization) {
    const syncAccountData = await getSyncAccountData();
    organization = syncAccountData.organization;
  }

  const uuid = v4();
  const params = {
    authenticationTool: 'trello',
    authorizationMethod: 'oauth2',
    authenticationEmbedOptions: {
      accountId: organization || '',
      containerId: boardToUniqueId(board) || '',
      productName,
      otherProviderName,
      popup: true,
      userId: member,
      isEmbed: true,
    },
    authenticationOptions: {
      popup: uuid,
    },
  };

  // product_name is used to identify the powerUp app in embed providers
  const url = new URL(
    encodeURI(
      `${process.env.REACT_APP_MAESTRO_BASE_URL}/${MAESTRO_BASE_API}/authenticate?payload=${JSON.stringify(params)}`,
    ),
  );
  const authenticationUrl = url.toString();
  return authenticationUrl;
};

export function boardToUniqueId(boardNativeId) {
  return `trello${ID_SEPARATOR}${boardNativeId}`;
}

export function cardToUniqueId(cardNativeId) {
  return `trello${ID_SEPARATOR}${cardNativeId}`;
}

export function boardToNativeId(boardUniqueId) {
  return boardUniqueId.split(ID_SEPARATOR)[1];
}

export function cardToNativeId(cardUniqueId) {
  return cardUniqueId.split(ID_SEPARATOR)[1];
}

export async function getMirrorCountCached(t, timeoutDelay) {
  const { board, card } = await getContext(t);
  // the fetch for task count can happen either on the board or card level
  // if the card is undefined it means we're calling from the board
  const key = card || board;

  return await Cache.sessionStorageWithLock(
    () => getMirrorCount(t, timeoutDelay),
    TASK_COUNT_CACHE_NAMESPACE,
    key,
    TASK_COUNT_CACHE_TTL,
  );
}

export async function getLinkCountCached(t, timeoutDelay) {
  const { board, card } = await getContext(t);

  const key = card || board;

  return await Cache.sessionStorageWithLock(
    () => getLinkCount(t, timeoutDelay),
    TASK_COUNT_CACHE_NAMESPACE,
    key,
    TASK_COUNT_CACHE_TTL,
  );
}

export async function getLinkCount(t, timeoutDelay) {
  const { workspaceId } = await getSyncAccountData(t);
  const { linkCount } = await callMaestro(
    'links/count',
    {
      method: REQUEST_METHODS.POST,
      options: {
        body: {
          organizationId: workspaceId,
        },
      },
      t,
    },
    timeoutDelay,
  );
  return linkCount;
}
export async function getMirrorCount(t, timeoutDelay) {
  const { workspaceId } = await getSyncAccountData(t);
  const { taskCount, taskCountLimit } = await callMaestro(
    'links/taskSync/count',
    {
      method: REQUEST_METHODS.POST,
      options: {
        body: {
          kind: 'taskSync',
          organizationId: workspaceId,
        },
      },
      t,
    },
    timeoutDelay,
  );
  return {
    taskUsage: taskCount.all - (taskCount.closed + taskCount.filteredOut),
    taskLimit: taskCountLimit,
  };
}

export async function patchOrganization(t, accountId, unitoOrgId, workspaceName) {
  const options = {
    body: {
      partnerInfo: {
        providerName: 'trello',
        accountId,
        workspaceName,
      },
    },
  };
  const { organization } = await callMaestro(`organizations/${unitoOrgId}`, {
    method: REQUEST_METHODS.PATCH,
    options,
    t,
  });

  return organization;
}

export async function createOrganization(t, providerIdentityId, partnerInfo, source) {
  const { board } = await getContext();
  const productName = getProductNameBySource(source);

  const options = {
    body: {
      intentProductName: productName,
      providerIdentityId,
      containerId: boardToUniqueId(board),
      partnerInfo,
    },
    t,
  };

  const { organization } = await callMaestro('organizations', {
    method: REQUEST_METHODS.POST,
    options,
    t,
  });

  return organization;
}
export async function getPlan(t, unitoOrgId) {
  const { planProfile } = await callMaestro(`organizations/${unitoOrgId}/currentPlan`, { t });
  return planProfile;
}

export async function getSyncActivity(t, unitoOrgId) {
  const activity = await callMaestro(`organizations/${unitoOrgId}/syncActivity`, { method: 'post', t });
  return activity;
}

export async function getItemsUsage(t, unitoOrgId) {
  const itemUsage = await callMaestro(`organizations/${unitoOrgId}/items-usage`, { method: 'post', t });
  return itemUsage;
}

export async function getMetrics(t, unitoOrgId) {
  const metrics = await callMaestro(`organizations/${unitoOrgId}/metrics`, { t });
  return metrics;
}

export async function getPlanCached(t, unitoOrgId) {
  return await Cache.sessionStorageWithLock(
    () => getPlan(t, unitoOrgId),
    PLAN_INFO_CACHE_NAMESPACE,
    `planInfo-${unitoOrgId}`,
    PLAN_INFO_CACHE_TTL,
  );
}

export async function getContainers(t, providerIdentityId, shouldGetCachedContainers) {
  const trello = t || T.iframe();
  // If there is no organization, the board is part of the personal boards
  const { organization = 'PersonalBoards', member, board } = await getContext();

  const params = {
    providerIdentityId,
    profileId: member,
    sourceContainerId: boardToUniqueId(board),
    containerType: DEFAULT_CONTAINER_TYPE,
    itemType: DEFAULT_ITEM_TYPE,
  };
  if (shouldGetCachedContainers) {
    params.shouldGetCachedContainers = shouldGetCachedContainers;
  }

  const { containers: containersByOrg } = await callMaestro('containers', {
    options: {
      params,
    },
    t: trello,
  });

  const containersCurrentOrg = containersByOrg.find((org) => org.id === organization);
  return containersCurrentOrg || {};
}

// Retrieve the unito workspace that belongs to the Trello Team
// PersonalBoards should have a partnerAccountId of undefined, so default to empty string
export async function getWorkspaces(t, partnerAccountId = '') {
  const { organizations } = await callMaestro('organizations', {
    options: {
      params: {
        partnerAccountId,
      },
    },
    t,
  });

  return organizations;
}

export async function getWorkspaceLinkedToAccountId(t) {
  const { organization: accountId, board } = t.getContext();
  const { providerIdentityId } = await getSyncAccountData(t);
  const { organization } = await callMaestro(`organizations/account/${accountId}`, {
    options: {
      params: {
        providerName: 'trello',
        containerId: boardToUniqueId(board),
        providerIdentityId,
        containerType: DEFAULT_CONTAINER_TYPE,
        itemType: DEFAULT_ITEM_TYPE,
      },
    },
    t,
  });

  return organization;
}

export async function getUnitoOrgById(t, workspaceId) {
  const { organization } = await callMaestro(`organizations/${workspaceId}`, {
    t,
  });

  return organization;
}

export async function getUnitoOrgByIdCached(t, workspaceId) {
  return await Cache.sessionStorageWithLock(
    () => getUnitoOrgById(t, workspaceId),
    ORGANIZATION_CACHE_NAMESPACE,
    `organization-${workspaceId}`,
    ORGANIZATION_CACHE_TTL,
  );
}

export async function getLists(t, providerIdentityId, containerId, shouldFetchCachedFieldValues) {
  const trello = t || T.iframe();
  const { board, member } = await getContext();
  const params = {
    providerIdentityId,
    containerId,
    fieldId: 'column',
    kind: 'pcdField',
    category: 'default',
    profileId: member,
    sourceContainerId: boardToUniqueId(board),
    containerType: DEFAULT_CONTAINER_TYPE,
    itemType: DEFAULT_ITEM_TYPE,
    status: 'open',
  };

  if (shouldFetchCachedFieldValues) {
    params.shouldFetchCachedFieldValues = shouldFetchCachedFieldValues;
  }

  const { fieldValues } = await callMaestro('fieldValues', {
    options: {
      params,
    },
    t: trello,
  });

  return fieldValues;
}

export async function createCardSync(destBoardUniqueId, destColumnId) {
  const trello = T.iframe();
  const { board, card, member } = await getContext();
  const { workspaceId, providerIdentityId, providerIdentityUserId } = await getSyncAccountData();

  // TODO cleanup board settings and fields to sync
  const fieldSettings = await getFieldSettings();
  const fieldsToSync =
    Object.entries(fieldSettings)
      .filter(([, setting]) => setting.pcd && setting.sync)
      .map(([key]) => key) || [];

  const options = {
    body: {
      initiator: {
        containerId: boardToUniqueId(board),
        taskId: cardToUniqueId(card),
        profileId: member,
      },
      destination: {
        columnId: destColumnId,
        containerId: destBoardUniqueId,
      },
      fieldsToSync,
      organizationId: workspaceId,
      providerIdentityId,
      providerIdentityUserId,
      syncSubtasks: fieldSettings.checklists.sync,
      syncCustomFields: fieldSettings.customFields.sync,
    },
  };
  logger.info(
    `Sending taskSync creation request with options:
    containerId: ${options.body.initiator.containerId},
    taskId: ${options.body.initiator.taskId},
    columnId: ${options.body.destination.columnId},
    containerId: ${options.body.destination.containerId}
    `,
  );
  const link = await callMaestro('taskSyncs', {
    method: 'POST',
    options,
    t: trello,
  });

  const cardSyncsCount = await getSyncsCountForCurrentCard();
  await forceFrontOfCardsUpdate({
    additionalCardCounts: { [card]: cardSyncsCount + 1 },
  });

  return link;
}

export async function deleteCardSync(t, linkId) {
  const trello = t || T.iframe();
  const { board, card, member } = await getContext();
  const containerId = boardToUniqueId(board);
  const taskId = cardToUniqueId(card);
  const options = {
    body: {
      containerId,
      taskId,
      profileId: member,
    },
  };

  let link;
  try {
    ({ link } = await callMaestro(`taskSyncs/${linkId}`, {
      method: 'DELETE',
      options,
      t: trello,
    }));
  } catch (err) {
    if (err.name !== 'BadRequestError' || !err.message.includes(`The task ${taskId} is not synced by task sync`)) {
      throw err;
    }
  }

  await deleteSyncPlaceholder(linkId);

  // If deleting the last sync for the card, the card and its count will
  // be evicted from the backend response, and we'll fail to update the
  // front-of-card to 0 syncs. -> We pass the info manually.
  const cardSyncsCount = await getSyncsCountForCurrentCard();

  // Pre-populate cache, so that card-back-section init finds syncs in cache
  // and doesn't call our backend twice (caused by forceFrontOfCardsUpdate
  // doing two t.set calls)
  try {
    await getSyncsForCard();
  } catch (err) {
    reportException(err, 'Failed to fetch sister tasks when deleting when deleting card sync');
  }

  await forceFrontOfCardsUpdate({
    additionalCardCounts: { [card]: Math.max(cardSyncsCount - 1, 0) },
  });

  return link;
}

/**
 *  Returns a list of "active" card syncs (fetched from backend)
 */
export async function getBackendSyncsForCard(t) {
  const trello = t || T.iframe();
  const { board, card, member } = await getContext(trello);
  const boardUniqueId = boardToUniqueId(board);
  const cardUniqueId = cardToUniqueId(card);
  const { providerIdentityId } = await getSyncAccountData(trello);

  const { sisterTasks } = await callMaestro(`containers/${boardUniqueId}/tasks/${cardUniqueId}/sisterTasks`, {
    options: { params: { providerIdentityId, profileId: member } },
    t: trello,
  });

  const cardSyncs = sisterTasks
    .filter(
      (sister) =>
        sister.state !== BACKEND_SYNC_STATES.archived.displayName &&
        sister.sync.state !== BACKEND_SYNC_STATES.archived.displayName,
    )
    .map((sister) => ({
      destinationContainerNativeId: boardToNativeId(sister.container.id),
      destinationContainerUniqueId: sister.container.id,
      linkId: sister.sync.id,
      boardName: sister.container.displayName,
      listName: sister.column.displayName,
      state: sister.state,
      url: sister.url,
      cardUniqueId,
    }));
  return cardSyncs;
}

export async function getBackendCardSyncsCached(t) {
  const trello = t || T.iframe();
  const { card } = await getContext(trello);
  return await Cache.sessionStorageWithLock(
    () => getBackendSyncsForCard(trello),
    CARDSYNC_CACHE_NAMESPACE,
    card,
    CARDSYNC_CACHE_TTL,
  );
}

export async function refreshBackendCardSyncsCached(card, t) {
  const trello = t || T.iframe();
  return await Cache.sessionStorageWithLock(
    () => getBackendSyncsForCard(trello),
    CARDSYNC_CACHE_NAMESPACE,
    card,
    CARDSYNC_CACHE_TTL,
  );
}

export async function getSyncsForCard(t) {
  const trello = t || T.iframe();
  const backendSyncs = await getBackendCardSyncsCached(trello);

  const placeholderSyncs = await getSyncPlaceholders(trello);
  const { card } = await getContext(trello);
  const cardUniqueId = cardToUniqueId(card);

  let placeholdersNeedUpdate = false;
  /* eslint-disable no-unused-vars */
  for (const sync of backendSyncs) {
    if (sync.linkId in placeholderSyncs) {
      placeholdersNeedUpdate = true;
      delete placeholderSyncs[sync.linkId];
    }
  }
  if (placeholdersNeedUpdate) {
    await updateSyncPlaceholders(t, placeholderSyncs);
  }

  const localSyncs = Object.entries(placeholderSyncs).map(([linkId, wipSync]) => ({
    ...wipSync,
    linkId,
    destinationContainerNativeId: boardToNativeId(wipSync.destinationContainerUniqueId),
    state: SYNC_STATE_INPROGRESS,
    cardUniqueId,
  }));
  const allSyncs = [...localSyncs, ...backendSyncs];
  allSyncs.forEach((sync) => {
    if (!sync.boardName) {
      reportInfo(`Sync ${sync.linkId} has no board name`);
    }
  });
  allSyncs.sort((sync1, sync2) => {
    const sync1Name = sync1.boardName;
    const sync2Name = sync2.boardName;

    if (sync1Name && sync2Name && sync1Name.toUpperCase() < sync2Name.toUpperCase()) {
      return -1;
    }
    return 1;
  });
  return allSyncs;
}

/**
 * @param {Trello} t
 * @param {Date} includeFilteredOutSince
 * @returns {Promise<{date: Date, syncedTasks: [nativeTaskId: string]: string}>}
 */
export async function getBoardSyncedTasksWithCount(t, includeFilteredOutSince) {
  const { board, member } = await getContext(t);
  const boardUniqueId = boardToUniqueId(board);

  const options = { params: { profileId: member } };
  if (includeFilteredOutSince) {
    options.params.includeFilteredOutSince = includeFilteredOutSince.toISOString();
  }
  const { syncedTasks, date } = await callMaestro(`containers/${boardUniqueId}/taskSyncs`, {
    t,
    options,
  });
  const tasksWithNativeId = Object.entries(syncedTasks).reduce((acc, [uniqueCardId, count]) => {
    acc[cardToNativeId(uniqueCardId)] = count;
    return acc;
  }, {});

  return { syncedTasks: tasksWithNativeId, date: new Date(date) };
}

/**
 * @param {Trello} t
 * @param {Date} includeFilteredOutSince
 * @returns {Promise<{date: Date, syncedTasks: [nativeTaskId: string]: string}>}
 */
export async function getBoardSyncedTasksWithCountCached(t, includeFilteredOutSince) {
  const trello = t || T.iframe();
  const { board } = await getContext(trello);
  return await Cache.sessionStorageWithLock(
    () => getBoardSyncedTasksWithCount(trello, includeFilteredOutSince),
    BOARD_SYNC_CACHE_NAMESPACE,
    board,
    CARDSYNC_CACHE_TTL,
  );
}

/**
 * @param {Trello} t
 * @param {Date} date
 * @returns {Promise<void>}
 */
export async function acknowledgeFrontOfCardCountPersisted(t, date) {
  const trello = t || T.iframe();
  const { board, member } = await getContext(trello);
  const boardUniqueId = boardToUniqueId(board);

  await callMaestro(`containers/${boardUniqueId}/taskSyncs/acknowledge`, {
    t,
    method: 'POST',
    options: { body: { profileId: member, date: date.toISOString() } },
  });
}

export async function setUserKeepInformedMarketing(t, keepInformed = false) {
  const { providerIdentityUserId } = await getSyncAccountDataAtSecret(t);
  await callMaestro(`users/${providerIdentityUserId}`, {
    method: 'PATCH',
    options: {
      body: { keepInformed },
    },
    t,
  });
}

export async function deleteBoardSyncs(t, token, board, member) {
  const trello = t || T.iframe();
  const boardUniqueId = boardToUniqueId(board);
  return callMaestro(`containers/${boardUniqueId}/taskSyncs`, {
    method: 'DELETE',
    options: { body: { profileId: member }, token },
    t: trello,
  });
}

export async function getOrganizationFlags(t) {
  const { workspaceId } = await getSyncAccountData(t);
  const response = await callMaestro('flags', {
    options: {
      params: {
        organizationId: workspaceId,
      },
    },
    t,
  });

  return response.flags;
}

export async function rehydrate(t) {
  const { token } = await callMaestro('auth/rehydrate', {
    method: 'GET',
    t,
    baseApi: 'api',
  });

  return token;
}

export async function rehydrateCached(t) {
  return await Cache.sessionStorageWithLock(
    () => rehydrate(t),
    REHYDRATE_CACHE_NAMESPACE,
    `rehydrate`,
    REHYDRATE_CACHE_TTL,
  );
}
