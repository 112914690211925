// function to get the document hidden boolean value and visibilityChange event name
// in order to determine if the user is viewing the cuurent page and whether we should poll the backend
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
export function getPageVisibility() {
  let hidden = null;
  let visibilityChange = null;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
  return { hidden, visibilityChange };
}
