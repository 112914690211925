import { jwtDecode } from 'jwt-decode';

import {
  FIELD_SETTINGS_DEFAULT,
  BOARD_MEMBER_TYPES,
  UNITO_WORKSPACE_STATUSES,
  FRONT_OF_CARD_BACKEND_REFRESH_PERIOD_MS,
  ORG_REVIVED_MODAL_TYPES,
  UNITO_BOARD_SYNC_POWERUP_ID,
  PLAN_FEATURES,
  INTERNAL_SOURCES,
  PROVIDER_NAMES,
  POWERUP_DISPLAY_NAMES,
} from '../consts';
import * as Api from './api';
import { delay, hasAccessToPlanFeature } from './helpers';
import { reportException, logger } from './logger';
import * as Tracking from './tracking';
import { getQueryParameter } from './fetch';
import * as Trello from './trello'; // for tests
import { getPageVisibility } from './visibility';
import * as Flags from './flags';

import URL_GRAY_ICON from '../images/unito-mirror-powerup-logo.svg';

// BE CAREFUL!! Everything stored in trello secret will be converted to string.
// If comparing this const with what's in trello storage,
// don't forget to Number.parseFloat what's in the trello storage.
export const MIRROR_DATA_VERSION = 1.0;
export const ADMIN_BLOOMBERG_ID = '5c9e24487c35f9228c86bff5';

const mockStore = {
  secret: {},
  organization: { shared: {}, private: {} },
  board: { shared: {}, private: {} },
  card: { shared: {}, private: {} },
  member: { shared: {}, private: {} },
};

export const STRIPE_DISPLAY_STATUSES = {
  paying: '',
  resold: '',
  suspended: '',
  delinquent: 'Last payment failed',
  'trial-expired': 'Trial Expired',
  trialing: 'Trial',
  canceled: '',
  churned: 'canceled',
};

export const AUTH_POPUP = {
  title: 'Authorize Unito Account',
  url: './auth',
  height: 320,
};

export const TRIAL_EXPIRED_MODAL = {
  title: 'Trial expired',
  url: './trial-expired',
  height: 200,
};

export const ORG_PICKER_MODAL = {
  url: './configure-account',
  fullScreen: false,
  height: 420,
  title: 'Account Settings',
};

export const BLOCKED_USER_POPUP = {
  title: 'Authorize Unito Account',
  url: './blocked-user',
  height: 400,
};

export const ORG_REVIVED_MODAL = {
  title: 'Account Settings',
  url: './org-revived',
  height: 320,
};

export const LOCAL_STORAGE_UNAVAILABLE_POPUP = {
  title: "Browser's local storage unavailable",
  url: './unavailable-powerup',
  height: 150,
  args: {
    message: 'Oops! A browser feature (Local Storage), required by Mirror, is unavailable. Turn it on to enjoy Mirror',
    reason: 'local storage',
  },
};

export const PERSONAL_BOARDS_NOT_SUPPORTED_MODAL = {
  url: './personal-boards',
  fullScreen: false,
  height: 490,
};

export const getSetupIncompletePopup = (syncAccountDisplayName) => ({
  title: 'Incomplete Setup',
  url: './setup-incomplete',
  height: 400,
  args: { syncAccountDisplayName },
});

export const UNITO_SYNC_SETTINGS_POPUP = {
  title: 'Settings',
  url: './settings',
  height: 100,
};

export const UNITO_SYNC_ACCOUNT_POPUP = {
  title: 'Account',
  url: './account',
  height: 140,
};

export const UNITO_GET_HELP_POPUP = {
  title: 'Get help',
  url: './get-help',
  height: 100,
};

export const MIRROR_SETTINGS_POPUP = {
  title: 'Settings',
  url: './field-settings',
  height: 350,
};

export const MIRROR_CARD_TO_POPUP = {
  title: 'Mirror Card to',
  url: './sync-setup',
  height: 250,
};

const trelloClientLibraryFns = {
  isMemberSignedIn: () => true,
  getContext: function () {
    return {
      board: '5c461502a494f50647a10437',
      card: '5c4616ec9f07015f64d6b9df',
      command: 'callback',
      el: 'C7eP5uZvE2irPcQO',
      member: '5a846bc3ee917e7a7a284830',
      organization: '585170aefa9d97754f5699f2',
      permissions: {
        board: 'write',
        card: 'write',
        organization: 'write',
      },
    };
  },
  member: function () {
    return {
      id: '5a846bc3ee917e7a7a284830',
    };
  },
  board: function () {
    return {
      id: '5c461502a494f50647a10437',
      name: 'mockBoard',
      memberships: [
        {
          id: '5df6d479f7cefaf989289c05',
          idMember: '5a846bc3ee917e7a7a284830',
          memberType: BOARD_MEMBER_TYPES.ADMIN,
          unconfirmed: false,
          deactivated: false,
        },
      ],
    };
  },
  organization: function () {
    return {
      id: '585170aefa9d97754f5699f2',
      name: 'mockTeam',
    };
  },
  loadSecret: (key) => {
    return mockStore.secret[key];
  },
  storeSecret: (key, value) => {
    mockStore.secret[key] = value;
  },
  clearSecret: (key) => {
    delete mockStore.secret[key];
  },
  get: (scope, visibility, key) => {
    return mockStore[scope][visibility][key];
  },
  set: (scope, visibility, key, value) => {
    mockStore[scope][visibility][key] = value;
  },
  remove: (scope, visibility, key) => {
    delete mockStore[scope][visibility][key];
  },
  modal: () => {},
  render: () => {},
  sizeTo: () => {},
  args: [
    {
      context: {
        board: '585170aefa9d97754f5699f0',
        member: '585170aefa9d97754f5699f1',
      },
    },
    { source: INTERNAL_SOURCES.BOARDSYNC, providerName: PROVIDER_NAMES.GOOGLE_SHEETS },
  ],
};

// Depending on where the client library is called, the different functions will be in a different place:
// When in a modal or some other kind of UI element, the client functions are avaialble through iframe()
// Everywhere else the client functions are available at the root of the trello client.
const mockT = {
  initialize: function () {
    return mockT;
  },
  iframe: function () {
    return trelloClientLibraryFns;
  },
  ...trelloClientLibraryFns,
};

// TODO use Jest __mocks__ folder, https://jestjs.io/docs/en/manual-mocks
export const T = process.env.NODE_ENV === 'test' ? mockT : window.TrelloPowerUp;

export const closePopup = async (t) => {
  const trello = t || T.iframe();
  await trello.closePopup();
};

export const getCriticalInitializationError = async () => {
  try {
    localStorage.getItem('beautiful-test-key');
    sessionStorage.getItem('beautiful-test-key');
  } catch (err) {
    logger.info('Powerup failed to initialize due to disabled local/sessionStorage');
    return "Mirror needs access to your browser's local & session storage. If you're using an adblocker, you will need to pause or disable it to use the Power-up. Click here to learn more";
  }
};

export const forceBackOfCardUpdate = async (t) => {
  const trello = t || T.iframe();
  const { card } = await getContext(trello);
  if (!card) {
    return;
  }
  await Api.refreshBackendCardSyncsCached(card, t);

  await Trello.setCardSharedAttribute(trello, 'card', 'dirtyAt', new Date().toISOString());
};

export const shouldAllowMultipleAdmin = async (t) => {
  const flags = await Api.getOrganizationFlags(t);
  return flags?.[Flags.SHOULD_ALLOW_MULTIPLE_ADMINS] ?? true;
};

export const shouldAllowAnyoneToClearData = async (t) => {
  const flags = await Api.getOrganizationFlags(t);
  return flags?.[Flags.SHOULD_ALLOW_ANYONE_TO_CLEAR_DATA] ?? false;
};

export const canExposeBoardSyncFromMirror = async (t) => {
  const flags = await Api.getOrganizationFlags(t);
  return flags?.[Flags.CAN_EXPOSE_BOARD_SYNC_FROM_MIRROR] ?? false;
};

export const fetchAllOrganizationFlags = async (t) => {
  return await Api.getOrganizationFlags(t);
};

export const isFirstUser = async (t, syncAccountData) => {
  const trello = t || T.iframe();
  const { providerIdentityId, version, workspaceId } = syncAccountData || (await Trello.getSyncAccountData(trello));
  return !providerIdentityId || !workspaceId || Number.parseFloat(version, 10) !== MIRROR_DATA_VERSION;
};

// We don't want somebody who's a member of the board, but not a member of the trello team to
// which this board belongs to become the sync account.
export const isUserPartOfTeam = async (t) => {
  const trello = t || T.iframe();
  const { permissions } = await getContext(trello);
  return [permissions.organization, permissions.board].every((p) => p === 'write');
};

export const isPersonalBoard = async (t) => {
  const trello = t || T.iframe();
  const { organization } = await getContext(trello);
  return !organization;
};

export const isCurrentMemberAdmin = async (t) => {
  const trello = t || T.iframe();

  const syncAccountData = await Trello.getSyncAccountData(trello);
  if (await Trello.isFirstUser(t, syncAccountData)) {
    return true;
  }

  const { member } = await getContext(trello);
  const { cardSyncAdminsTrelloIds = [] } = syncAccountData;
  return cardSyncAdminsTrelloIds.includes(member);
};

/**
 * A `t.loadSecret` wrapper that, instead of throwing a `Decryption failed` error,
 * will return `null` (mimicking the return value of a non-existent secret) in
 * legit cases of decrypt failure. Never directly call `t.loadSecret`; call this.
 *
 * Motivational use case: invoke `Remove Personal Settings` on the powerup and
 * re-authorize. That will cause Trello to generate & store a *new* encryption
 * key, causing failures to `loadSecret` an existing secret. Flaw is acknowledged
 * by Trello and left unfixed, for backwards compatibility of undocumented cases.
 *
 * @param {*} key the name of the secret to load.
 * @param {*} t an optional instance of trello. Mandatory if in an init handler.
 */
// ⚠️ Never call T.iframe() in the function below, this may break the Trello PU
// as this function can be called outside of the Trello iframe context.
export const loadSecretSafe = async (key, t) => {
  let secret = null;

  try {
    secret = await t.loadSecret(key);
  } catch (err) {
    reportException(err, `Error while loading secret ${key}`);
  }

  return secret;
};

export const isUserAuthorized = async (t) => {
  const trello = t || T.iframe();
  if (!trello.isMemberSignedIn()) {
    return false;
  }

  try {
    // checks if plugin is installed on current board, if it's not it will return an error
    await trello.board('all');
  } catch (e) {
    return false;
  }

  const secret = await loadSecretSafe('token', trello);
  const userCurrentOrg = await loadSecretSafe('lastAuthorizedOrganization', trello);
  const userVersion = await loadSecretSafe('version', trello);
  const { organization } = await Trello.getContext(trello);

  // If the data we store in the power-up has changed, users need to re-authorize to have
  // up-to-date data to ensure good power-up behaviour.
  if (Number.parseFloat(userVersion, 10) !== MIRROR_DATA_VERSION) {
    return false;
  }

  // If a user is authorized on a board belonging to a certain organization (trello team), and goes on another
  // board that belongs to another organization (trello team), he will have to be authorized again
  // This is to make sure we create an organizationMember for the user linked to the workspace (unito orgId)
  // with the right partnerAccountId (trello team)
  if (!userCurrentOrg || userCurrentOrg !== organization) {
    return false;
  }

  return !!secret;
};

export const powerUpIsUsable = async (t, data) => {
  const trello = t || T.iframe();
  const { providerIdentityId, workspaceId, version } = data || (await Trello.getSyncAccountData(trello));

  return providerIdentityId && workspaceId && Number.parseFloat(version, 10) === MIRROR_DATA_VERSION;
};

export const powerUpIsUsableByUser = async (t, data) => {
  return (await Trello.isUserAuthorized(t)) && (await Trello.powerUpIsUsable(t, data));
};

export const needToAssociateTeamWithUnitoOrg = async (t, data) => {
  const trello = t || T.iframe();
  const { providerIdentityId, workspaceId, version } = data || (await Trello.getSyncAccountData(trello));

  return providerIdentityId && Number.parseFloat(version, 10) === MIRROR_DATA_VERSION && !workspaceId;
};

export const powerUpHasUnitoWorkspace = async (t, data) => {
  const trello = t || T.iframe();
  const { workspaceId } = data || (await Trello.getSyncAccountData(trello));

  return !!workspaceId;
};

export const initPowerUpDataOnAuth = async (authData) => {
  const t = T.iframe();

  await Trello.setCurrentUserDataAtSecret(t, authData);
  await Trello.setSyncAccountDataOnAuth(t, authData);
  await Trello.forceBackOfCardUpdate(t); // Load cards if authenticating from card button
};

export async function getOrganizationFieldSettings(t) {
  const trello = t || T.iframe();
  const storedSettings = await trello.get('organization', 'shared', 'fieldSettings');
  return storedSettings;
}

export async function getBoardFieldSettings(t) {
  const trello = t || T.iframe();
  const storedSettings = await trello.get('board', 'shared', 'fieldSettings');
  return storedSettings;
}

export function getPlanLimitedFieldSettings(planFeatures) {
  let fields = { ...FIELD_SETTINGS_DEFAULT };
  for (const [key, value] of Object.entries(fields)) {
    const planFeature = planFeatures[value.planFeature];
    if (planFeature !== undefined && !hasAccessToPlanFeature(planFeature)) {
      const fieldSetting = {
        ...fields[key],
        sync: false,
      };

      fields = {
        ...fields,
        [key]: fieldSetting,
      };
    }
  }

  return fields;
}

export const cleanupPowerupData = async (t) => {
  const trello = t || T.iframe();
  await trello.clearSecret('token');
  await trello.clearSecret('providerIdentityUserId');
  await trello.clearSecret('lastAuthorizedOrganization');
  await trello.clearSecret('version');
  await trello.remove('organization', 'shared', 'syncAccountData');
  await trello.remove('board', 'shared', 'syncAccountData');
};

export async function getFieldSettings(t) {
  const trello = t || T.iframe();

  const settings = { ...FIELD_SETTINGS_DEFAULT };
  const storedSettings = (await getBoardFieldSettings(trello)) || (await getOrganizationFieldSettings(trello)) || {};

  /* eslint-disable no-unused-vars */
  for (const [key, setting] of Object.entries(storedSettings)) {
    settings[key].sync = setting.sync;
  }

  return settings;
}

export function getModalForUnitoURL(t, url) {
  const trello = t || T.iframe();
  return trello.modal({
    fullscreen: true,
    title: 'Unito',
    url: '/console',
    args: { url },
  });
}

const setFieldSettings = async (fieldSettings, t) => {
  const trello = t || T.iframe();

  // Ensure we only store the "sync" key of each setting, space is limited
  const settingsToStore = Object.entries(fieldSettings)
    .map(([key, setting]) => {
      return [key, { sync: setting.sync }];
    })
    .reduce((acc, [key, setting]) => {
      acc[key] = setting;
      return acc;
    }, {});

  await trello.set('organization', 'shared', 'fieldSettings', settingsToStore);
  await trello.set('board', 'shared', 'fieldSettings', settingsToStore);
};

export const toggleFieldToSync = async (key, t) => {
  const trello = t || T.iframe();

  const settings = await getFieldSettings();
  settings[key].sync = !settings[key].sync;
  Tracking.trackEvent(
    Tracking.EVENTS.USER_MENU_SETTINGS_ACTION,
    { action_name: `turned ${key} ${settings[key].sync ? 'ON' : 'OFF'}` },
    trello,
  );

  await setFieldSettings(settings, trello);
};

export const getContext = async (t) => {
  const trello = t || T.iframe();

  const context = await trello.getContext();
  return context;
};

export async function upsertSyncAccountDataAdmins(t) {
  const trello = t || T.iframe();

  const syncAccountData = await Trello.getSyncAccountData(trello);
  const { cardSyncAdminsTrelloIds = [] } = syncAccountData;

  // Temporary measure for BB; revisit and remove when we revamp user roles and permissions
  const shouldAllowMultipleAdmin = await Trello.shouldAllowMultipleAdmin(t);
  const { id: currentMemberId } = await trello.member('id');
  let adminIdsToAdd = [];
  if (!shouldAllowMultipleAdmin) {
    logger.info(
      'Current admins: ',
      JSON.stringify(cardSyncAdminsTrelloIds, null, 2),
      `Keeping user ${ADMIN_BLOOMBERG_ID} as admin`,
    );
    adminIdsToAdd = [ADMIN_BLOOMBERG_ID];
    syncAccountData.cardSyncAdminsTrelloIds = [ADMIN_BLOOMBERG_ID];
  } else {
    if (cardSyncAdminsTrelloIds.includes(currentMemberId)) {
      return;
    }
    const { memberships = [] } = await trello.board('memberships');
    adminIdsToAdd = memberships
      .filter(
        (m) =>
          m.memberType === BOARD_MEMBER_TYPES.ADMIN &&
          !m.unconfirmed &&
          !m.deactivated &&
          !cardSyncAdminsTrelloIds.includes(m.idMember),
      )
      .map((m) => m.idMember);

    logger.info(`Adding ${adminIdsToAdd} to powerup admins`);
    syncAccountData.cardSyncAdminsTrelloIds = [...cardSyncAdminsTrelloIds, ...adminIdsToAdd];
  }

  if (adminIdsToAdd.length > 0) {
    await Trello.setSyncAccountData(trello, syncAccountData);
  }
}

export async function setSyncAccountDataOnAuth(t, authData) {
  const trello = t || T.iframe();

  if (await Trello.isUserPartOfTeam(trello)) {
    await Trello.upsertSyncAccountDataAdmins(t);
  }

  const {
    providerIdentityId,
    providerIdentityUserId,
    providerIdentityDisplayName,
    cardSyncAdminsTrelloIds,
    status,
    workspaceId,
    validUntil,
    version,
  } = await Trello.getSyncAccountData(trello);
  if (
    (await powerUpIsUsable(trello, { providerIdentityId, workspaceId, version })) &&
    ![UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED, UNITO_WORKSPACE_STATUSES.CHURNED].includes(status)
  ) {
    return;
  }

  const { member, organization } = await getContext(trello);
  const decodedToken = jwtDecode(authData.token);

  // re-use syncAccountData if any in case there is only the workspaceId that is not set
  // this could happen if for some reason we can't retrieve the Unito org in updateWorkspaceInfo.
  // When we can't find the org, we wipe the workspaceId value in storage and the user data (token)
  // so we have to go through setting the syncAccountData again.
  const syncAccountData = {
    providerIdentityId: providerIdentityId || authData.providerIdentityId || decodedToken.loginProviderIdentity?._id,
    providerIdentityUserId: providerIdentityUserId || decodedToken._id,
    providerIdentityDisplayName: providerIdentityDisplayName || decodedToken.loginProviderIdentity?.displayName,
    cardSyncAdminsTrelloIds: cardSyncAdminsTrelloIds || [member],
    organization,
    status,
    workspaceId,
    validUntil,
    defaultListName: null,
    version: MIRROR_DATA_VERSION,
  };

  let workspace;
  if (!workspaceId) {
    try {
      workspace = (await Api.getWorkspaces(t, organization))[0];
    } catch (err) {
      const context = await getContext();
      reportException(
        err,
        `An error occured while fetching unito workspace for trello account ${organization}`,
        context,
      );
    }

    // if no workspace was found for the trello team id user will be offered
    // to associate an org or create a new one later on in the flow.
    if (workspace) {
      syncAccountData.workspaceId = workspace.id;
      syncAccountData.validUntil = workspace.validUntil;
      syncAccountData.status = workspace.status;
    }
  } else {
    try {
      workspace = await Api.getUnitoOrgByIdCached(t, workspaceId);
    } catch (err) {
      const context = await getContext();
      reportException(err, `An error occured while fetching unito organization ${workspaceId}`, context);
    }
  }

  await Trello.setSyncAccountData(trello, syncAccountData);

  const isOrgRevived =
    workspace && workspace.status === UNITO_WORKSPACE_STATUSES.TRIALING && workspace.previousOrganizations?.length;
  const isFirstUserSettingUp = workspace && !workspaceId;
  const hasOrgStatusChanged =
    workspace &&
    !workspaceId &&
    workspace.status === UNITO_WORKSPACE_STATUSES.TRIALING &&
    status !== UNITO_WORKSPACE_STATUSES.TRIALING;

  // we can't have those `ifs` in the above block because calling t.modal will stop the execution of this function and we'll never call Trello.setSyncAccountData
  if (isOrgRevived && (isFirstUserSettingUp || hasOrgStatusChanged)) {
    // Show a modal explaining the user that their organization has been revived. This happens when:
    // - A colleague tried Unito a long time ago and churned / expired.
    // - The power-up data was cleared meaning the current user is the "first one" using the PU since a long time.
    // - OR the power-up data wasn't clear
    t.modal({ ...Trello.ORG_REVIVED_MODAL, args: { modalType: ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG } });
  }

  if (
    workspace &&
    [UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED, UNITO_WORKSPACE_STATUSES.CHURNED].includes(workspace.status)
  ) {
    t.modal({
      ...Trello.ORG_REVIVED_MODAL,
      args: { modalType: ORG_REVIVED_MODAL_TYPES.ADDED_TO_EXPIRED_ORG, expiredOrgId: workspace.id },
    });
  }
}

// The user data to store
export async function setCurrentUserDataAtSecret(t, authData) {
  const trello = t || T.iframe();
  const { organization } = trello.getContext();
  const { token } = authData;
  const { _id: userId } = jwtDecode(token);
  try {
    await trello.storeSecret('token', token);
  } catch (err) {
    reportException(err, `Error while setting token secret for user=${userId}`);
  }
  await trello.storeSecret('providerIdentityUserId', userId);
  await trello.storeSecret('lastAuthorizedOrganization', organization);
  await trello.storeSecret('version', MIRROR_DATA_VERSION);
  try {
    // we also set it in the member private scope so we have access to it outside the trello.initialize
    // as we don't have access to the secret outside of it and we need the user id to identify the user.
    await trello.set('member', 'private', 'unitoUserId', userId);
  } catch (err) {
    reportException(err, 'Problem while trying to set the unito user id');
  }
  Tracking.identify(userId);
}

// Get secret data
export async function getSyncAccountDataAtSecret(t) {
  const trello = t || T.iframe();
  const token = await Trello.loadSecretSafe('token', trello);
  const providerIdentityUserId = await Trello.loadSecretSafe('providerIdentityUserId', trello);
  const lastAuthorizedOrganization = await Trello.loadSecretSafe('lastAuthorizedOrganization', trello);
  return { token, providerIdentityUserId, lastAuthorizedOrganization };
}

export const getBoardSyncPowerUpUrl = async (t) => {
  const trello = t || T.iframe();
  const { shortLink: boardLink, name: boardName } = await trello.board('shortLink', 'name');
  return `https://trello.com/b/${boardLink}/${boardName}/power-up/${UNITO_BOARD_SYNC_POWERUP_ID}`;
};

export const getSyncAccountData = async (t) => {
  const trello = t || T.iframe();
  // this will return undefined if the member trying to get the info is not a member of the team
  const orgSyncAccountData = await trello.get('organization', 'shared', 'syncAccountData');

  if (orgSyncAccountData && Object.keys(orgSyncAccountData).length > 0) {
    return orgSyncAccountData;
  }
  return (await trello.get('board', 'shared', 'syncAccountData')) || {};
};

export const setSyncAccountData = async (t, syncAccountData) => {
  const trello = t || T.iframe();
  try {
    await trello.set('organization', 'shared', 'syncAccountData', syncAccountData);
  } catch (err) {
    reportException(err, 'failed to set organization sync account data');
  }
  await trello.set('board', 'shared', 'syncAccountData', syncAccountData);
};

export const getSyncsCountForCurrentCard = async (t) => {
  const trello = t || T.iframe();
  const syncsCount = (await trello.get('card', 'shared', 'syncsCount')) || 0;

  return syncsCount;
};

export async function setCardSharedAttribute(t, cardId, key, value) {
  const trello = t || T.iframe();

  try {
    await trello.set(cardId, 'shared', key, value);
  } catch (err) {
    reportException(err, `Error while in setCardSharedAttribute for card ${cardId}, key ${key} and value ${value}`);
    if (!err.message.toLowerCase().includes('card not found')) {
      throw err;
    }
  }
}

/**
 * Get a shared attribute for a card.
 * Remember to try/catch based on your needs! (e.g. handling `card not found`)
 */
export async function getCardSharedAttribute(t, cardId, key) {
  const trello = t || T.iframe();

  return trello.get(cardId, 'shared', key);
}

export async function fetchAndSetWorkspaceInfo(t) {
  const trello = t || T.iframe();
  // Not using getSyncAccountData in case the first person using the power-up
  // after a change of plan is a member of the board, but not a member of the team.
  // this will force the next member of the team to go through this piece of code
  // again to be able to set the data at org level.
  const scope = (await isUserPartOfTeam(trello)) ? 'organization' : 'board';
  const syncAccountData = await trello.get(scope, 'shared', 'syncAccountData');
  let unitoOrg;
  try {
    unitoOrg = await Api.getUnitoOrgByIdCached(trello, syncAccountData.workspaceId);
  } catch (err) {
    reportException(err, `Unable to get Unito org ${syncAccountData.workspaceId}`);
    // At that point we know there is a Unito org with this partnerInfo, but if for some reason
    // we can't find it anymore (most likely it's been transferred), we will try to fetch the new org
    unitoOrg = (await Api.getWorkspaces(trello, syncAccountData.organization))[0];

    // If the two orgIds don't match:
    // - syncAccountData.workspaceId, the old org but still cached;
    // - unitoOrg.id, the new good org returned from the getWorkspaces call using the partnerInfo;
    // Then we probably have two orgs being merged together and can't based our calls on the cached orgId.
    // In this case, let's clear the cached data and reset the SyncAccountData.
    if (unitoOrg) {
      if (syncAccountData.workspaceId !== unitoOrg.id) {
        logger.warn(
          `Mismatch between cached orgId: ${syncAccountData.workspaceId} and the partnerInfos orgId: ${unitoOrg.id}`,
        );
        try {
          unitoOrg = await Api.getUnitoOrgByIdCached(trello, unitoOrg.id);
        } catch (err) {
          reportException(err, `Unable to refresh Unito org ${unitoOrg.id}`);
          return;
        }

        await Trello.setSyncAccountData(trello, { ...syncAccountData, workspaceId: unitoOrg.id });
      }
    }
  }
  if (!unitoOrg) {
    // This should never happen anyway, but if we can't find the unitoOrg.
    // Let's wipe it so the user goes through the org picker modal again!
    logger.warn(`Could not find organization with id: ${syncAccountData.workspaceId}`);
    delete syncAccountData.workspaceId;
    await Trello.setSyncAccountData(trello, syncAccountData);
    return;
  }

  // 3 possibilities as why these values would not be equal:
  // 1. It is the first authorize (meaning syncAccountData.planId === undefined).
  // 2. The org changed their plan via the pricing page.
  // 3. The status changed. (trialing to trial-expired, for instance)
  let planInfo = {
    planId: syncAccountData.planId,
    status: syncAccountData.status,
    planName: syncAccountData.planName,
    nbMirrorsMax: syncAccountData.nbMirrorsMax,
  };
  if (unitoOrg.planId !== planInfo.planId || unitoOrg.status !== planInfo.status) {
    try {
      planInfo = await Trello.getPlanInfo(trello, unitoOrg);
    } catch (err) {
      reportException(err, `Failed when fetch org ${unitoOrg.id}'s new plan`);
      return;
    }
  }
  const updatedSyncAccountData = {
    ...syncAccountData,
    ...planInfo,
    workspaceId: unitoOrg.id, // override the workspaceId in case it has changed
    validUntil: unitoOrg.validUntil,
  };

  await Trello.setSyncAccountData(trello, updatedSyncAccountData);
}

export const saveDefaultListName = async (t, defaultListName) => {
  const trello = t || T.iframe();
  const syncAccountData = await getSyncAccountData(trello);
  const updatedSyncAccountData = {
    ...syncAccountData,
    defaultListName,
  };
  await setSyncAccountData(trello, updatedSyncAccountData);
};

export const set2WayBoardSyncEnabled = async (t, enabled) => {
  const trello = t || T.iframe();
  const updated2Way = {
    mirror2WayBoardSyncEnabled: enabled,
  };
  await trello.set('board', 'shared', updated2Way);
};

export const is2WayBoardSyncEnabled = async (t) => {
  const trello = t || T.iframe();
  const isEnabled = await trello.get('board', 'shared', 'mirror2WayBoardSyncEnabled');
  return isEnabled;
};

export const set2WayMirrorEnabled = async (t, enabled) => {
  const trello = t || T.iframe();
  const updated2Way = {
    unitoSync2WayMirrorEnabled: enabled,
  };
  await trello.set('board', 'shared', updated2Way);
};

export const is2WayMirrorEnabled = async (t) => {
  const trello = t || T.iframe();
  const isEnabled = await trello.get('board', 'shared', 'unitoSync2WayMirrorEnabled');
  return isEnabled;
};

export const getScreenToDisplay = async (t, accountData, defaultScreen, isBoardSyncPowerup = false) => {
  const screenToDisplay = {
    function: 'popup',
    screen: defaultScreen,
  };

  if (!window.localStorage || !window.sessionStorage) {
    screenToDisplay.screen = LOCAL_STORAGE_UNAVAILABLE_POPUP;
    return screenToDisplay;
  }

  if (isBoardSyncPowerup && (await Trello.isPersonalBoard(t))) {
    return screenToDisplay;
  }

  if (await Trello.isPersonalBoard(t)) {
    screenToDisplay.function = 'modal';
    screenToDisplay.screen = PERSONAL_BOARDS_NOT_SUPPORTED_MODAL;
    return screenToDisplay;
  }

  if (!(await Trello.isUserPartOfTeam(t))) {
    screenToDisplay.screen = BLOCKED_USER_POPUP;
    return screenToDisplay;
  }

  if ((await Trello.needToAssociateTeamWithUnitoOrg(t, accountData)) && !(await Trello.isUserAuthorized(t))) {
    screenToDisplay.screen = getSetupIncompletePopup(accountData.providerIdentityDisplayName);
    return screenToDisplay;
  }

  if ((await Trello.needToAssociateTeamWithUnitoOrg(t, accountData)) && (await Trello.isUserAuthorized(t))) {
    screenToDisplay.function = 'modal';
    screenToDisplay.screen = ORG_PICKER_MODAL;
    return screenToDisplay;
  }

  if (!(await Trello.powerUpIsUsableByUser(t, accountData))) {
    screenToDisplay.screen = AUTH_POPUP;
    return screenToDisplay;
  }

  if (
    [UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED, UNITO_WORKSPACE_STATUSES.CHURNED].includes(accountData?.status) &&
    !isBoardSyncPowerup
  ) {
    const subscriptionStatus = accountData?.status;
    const title =
      subscriptionStatus === UNITO_WORKSPACE_STATUSES.CHURNED
        ? 'Subscription canceled'
        : `Mirror - ${STRIPE_DISPLAY_STATUSES[subscriptionStatus]}`;
    screenToDisplay.screen = {
      title,
      url: './block-powerup',
      args: { subscriptionStatus },
      height: 300,
    };
    return screenToDisplay;
  }

  return screenToDisplay;
};

export const getPlanInfo = async (t, unitoOrg) => {
  const trello = t || T.iframe();
  const plan = await Api.getPlanCached(trello, unitoOrg.id);

  const planFeatures = plan.features
    .filter((feature) => Object.values(PLAN_FEATURES).includes(feature.id))
    .reduce((features, feature) => {
      return {
        ...features,
        [feature.id]: feature,
      };
    }, {});

  return {
    planId: unitoOrg.planId,
    status: unitoOrg.status,
    planName: plan.nickname,
    nbMirrorsMax: planFeatures[PLAN_FEATURES.MAX_MIRROR_SYNCS]?.limit,
    features: {
      [PLAN_FEATURES.MAPPED_FIELDS]: planFeatures[PLAN_FEATURES.MAPPED_FIELDS],
      [PLAN_FEATURES.CUSTOM_FIELD]: planFeatures[PLAN_FEATURES.CUSTOM_FIELD],
      [PLAN_FEATURES.SUBTASK]: planFeatures[PLAN_FEATURES.SUBTASK],
    },
  };
};

export async function replicateOrgDataToBoard(t) {
  const syncAccountDataAtOrgLevel = await t.get('organization', 'shared', 'syncAccountData');

  // That means it's the first time that the power-up is setup on any of the boards
  // of the team or the member loading the board is not part of the trello team.
  // We should proceed with the normal authorize flow.
  if (!syncAccountDataAtOrgLevel) {
    return;
  }

  // Update the data at board level. This is in case we already have data at org level, but this
  // board's power-up has never been setup. Without this, board level data will never be set because
  // we'll never go through setSyncAccountDataOnAuth that will also set the data at board level
  // because we consider this power-up already setup. We need board level for member of the board
  // that are not member of the team to which the board belongs.
  await t.set('board', 'shared', 'syncAccountData', syncAccountDataAtOrgLevel);
}

/**
 * Get sync counts from backend, and update front-of-cards. Test cases:
 * - In boards A & B, open DevTools / {Network, Console} to keep an eye on errors
 * - In board A, create a mirror
 *     - Check that counts appears in A
 *     - Enable mirror in board B, check that count appears too
 * - Delete mirror from A
 *     - Check that counts disappear from both A & B tasks
 * - Re-mirror, still from A. This time, delete mirror from B
 *     - Check that counts disappear from both A & B tasks
 * - Re-mirror. This time, delete link from standalone app
 *     - Check that counts disappear from all tasks in both A & B
 * - Don't archive the previous card, and create a new mirror from it on side A.
 *     - Check that count appears again in B.
 * - Create two mirrors, one from A, one from B. Disable powerup in side B.
 *     - In B, check that all counts disappear
 *     - In A, check that counts disappears for the task mirrored from B.
 * - Re-enable powerup in side B
 *     - Check that task formerly mirrored before disable isn't mirrored
 *     - Check that mirroring again from B works
 * - Hard-delete card from B
 *     - Check that sister task in A is "inaccessible" and can be removed
 * - ~Move card to another board~ Currently unsupported
 */
export const forceFrontOfCardsUpdate = async ({ t, additionalCardCounts = {}, forceFullRefresh = false } = {}) => {
  // Dear fellow dev, the `cardsSeenSynced` code handles legacy client-side data
  // whose presence is totally out of our control. It should be maintained to
  // cover cases of users opening their board after months, or years.
  const trello = t || T.iframe();
  const boardHasLegacyCardsData = ((await trello.get('board', 'shared', 'cardsSeenSynced')) || []).length > 0;
  const cardSyncsLastFetch = await trello.get('board', 'shared', 'cardSyncsLastFetch');
  // If changing this threshold, you MUST change it in maestro too! resources/links/index.js @ acknowledgeTaskSyncUnwhitelistedAt
  const approxSixMonthsAgo = new Date(Date.now() - 6 * 30 * 24 * 3600 * 1000);
  const lastFetchIsOld = cardSyncsLastFetch && new Date(cardSyncsLastFetch) < approxSixMonthsAgo;

  // A. If we have legacy client-side card data, we need to make one costly
  //    backend call to get deleted mirrors since forever (thus date=0).
  // B. If not (i.e. if legacy client-side card data already got cleaned up),
  //    then we ask since the last fetch, or since forever if no last fetch.
  const timestampForBackendCall =
    forceFullRefresh || boardHasLegacyCardsData || lastFetchIsOld
      ? new Date(0)
      : (cardSyncsLastFetch && new Date(cardSyncsLastFetch)) || new Date(0);

  let syncedTasksFromBackend = {};
  let syncedTasksFromBackendDate;
  const newCardSyncsFetchDate = new Date();
  try {
    const syncedTasksForBoard = await Api.getBoardSyncedTasksWithCountCached(trello, timestampForBackendCall);
    syncedTasksFromBackend = syncedTasksForBoard.syncedTasks;
    syncedTasksFromBackendDate = syncedTasksForBoard.date;
  } catch (err) {
    reportException(err, `Was not able to get syncs for board ${trello.getContext().board}`);
    if (err.name === 'InvalidCookieTokenError') {
      // don't absord the error if token is invalid
      throw err;
    }
  }
  const syncs = { ...additionalCardCounts, ...syncedTasksFromBackend };
  const syncsEntries = Object.entries(syncs);

  let updatedSomeCardData = false;
  for (const [cardId, count] of syncsEntries) {
    // Checking for the current count and comparing is prone to race conditions.
    // Ideally we'd not care and only `set`, but Trello seems to not compare
    // previous/new, and will fire `t.render` even when the value didn't change,
    // so we have to be cautious. Thus, we set only when necessary, and accept
    // that race conditions will happen. Values will be updated eventually.
    let currentCount;
    let cardExists = true;
    try {
      currentCount = await Trello.getCardSharedAttribute(t, cardId, 'syncsCount');
    } catch (err) {
      if (err.message.toLowerCase().includes('card not found')) {
        cardExists = false;
      } else {
        reportException(err, `Error getting syncCount for card ${cardId}`);
        throw err;
      }
    }
    if (cardExists && count !== currentCount) {
      await Trello.setCardSharedAttribute(trello, cardId, 'syncsCount', count);
      updatedSomeCardData = true;
    }
  }

  // Housekeeping: persist fetch date, trigger updates, do cleanup
  const boardDataUpdate = {};
  if (syncedTasksFromBackendDate) {
    // Update the last fetch date, to not re-fetch counts for old deleted tasks next time
    boardDataUpdate.cardSyncsLastFetch = newCardSyncsFetchDate.toISOString();

    // Cleanup behind us legacy board-level data now provided by backend
    if (boardHasLegacyCardsData) {
      boardDataUpdate.cardsSeenSynced = undefined;
    }

    let updatedBoardData = false;
    try {
      await trello.set('board', 'shared', boardDataUpdate);
      updatedBoardData = true;
    } catch (err) {
      let errStringify;
      try {
        errStringify = JSON.stringify(err);
      } catch (e) {
        // it's not an object
      }
      logger.error(`Failed to update board ${boardDataUpdate} Error: ${errStringify ?? err}`);
    }
    // Acknowledging front-of-card data is done conditionally to limit calls to maestro
    if (updatedBoardData && updatedSomeCardData) {
      try {
        await Api.acknowledgeFrontOfCardCountPersisted(trello, syncedTasksFromBackendDate);
      } catch (err) {
        logger.error(
          `Failed to acknowledge front-of-card count of ${syncsEntries.length} tasks for container ${
            (await getContext(trello)).board
          } at date ${timestampForBackendCall} with error ${err.message}`,
        );
      }
    }
  }
};

export const associateTrelloTeamWithUnitoOrg = async (t, unitoOrgId) => {
  const trello = t || T.iframe();
  const context = trello.getContext();
  const trelloTeamId = context.organization;
  const trelloTeamName = (await trello.organization('name')).name;
  const syncAccountData = await Trello.getSyncAccountData(trello);

  let patchedOrg;
  let planInfo;
  try {
    patchedOrg = await Api.patchOrganization(trello, trelloTeamId, unitoOrgId, trelloTeamName);
    planInfo = await Trello.getPlanInfo(trello, patchedOrg);
  } catch (err) {
    reportException(err, `Was unable to associate trelloTeamId ${trelloTeamId} with unitoOrg ${unitoOrgId}`);
    return;
  }
  const updatedSyncAccountData = {
    ...syncAccountData,
    ...planInfo,
    workspaceId: patchedOrg.id,
  };
  await Trello.setSyncAccountData(trello, updatedSyncAccountData);
  logger.info(`Associated trelloTeamId ${trelloTeamId} with unitoOrg ${unitoOrgId}`);
};

export const createUnitoOrgForTrelloTeam = async (t) => {
  const trello = t || T.iframe();
  const context = trello.getContext();
  const trelloTeamId = context.organization;
  const trelloTeamName = (await trello.organization('name')).name;
  const source = t.arg ? t.arg('source') : null;

  const partnerInfo = {
    providerName: 'trello',
    accountId: trelloTeamId,
    workspaceName: trelloTeamName,
  };

  const syncAccountData = await Trello.getSyncAccountData(trello);

  let newOrg;
  let planInfo;
  try {
    newOrg = await Api.createOrganization(trello, syncAccountData.providerIdentityId, partnerInfo, source);
    planInfo = await Trello.getPlanInfo(trello, newOrg);
  } catch (err) {
    reportException(err, `Was unable to create new unitoOrg for trelloTeamId ${trelloTeamId}`);
    return;
  }

  const updatedSyncAccountData = {
    ...syncAccountData,
    ...planInfo,
    workspaceId: newOrg.id,
  };
  await Trello.setSyncAccountData(trello, updatedSyncAccountData);
  logger.info(`Created unitoOrg ${newOrg.id} for trelloTeamId ${trelloTeamId}`);
};

export const getSyncPlaceholders = async (t) => {
  const trello = t || T.iframe();
  return (await trello.get('card', 'shared', 'syncPlaceholders')) || {};
};

export const createSyncPlaceholder = async (linkId, destinationContainerUniqueId, boardName, listName) => {
  const trello = T.iframe();
  const syncPlaceholders = await getSyncPlaceholders(trello);
  syncPlaceholders[linkId] = {
    destinationContainerUniqueId,
    boardName,
    listName,
  };
  await trello.set('card', 'shared', 'syncPlaceholders', syncPlaceholders);
};

export const deleteSyncPlaceholder = async (linkId) => {
  const trello = T.iframe();
  const syncPlaceholders = await getSyncPlaceholders(trello);

  if (linkId in syncPlaceholders) {
    delete syncPlaceholders[linkId];
    await trello.set('card', 'shared', 'syncPlaceholders', syncPlaceholders);
    await forceFrontOfCardsUpdate();
  }
};

export const updateSyncPlaceholders = async (t, syncPlaceholders) => {
  const trello = t || T.iframe();
  await trello.set('card', 'shared', 'syncPlaceholders', syncPlaceholders);
};

export const copyUnitoUserIdToMemberScope = async (t) => {
  const trello = t || T.iframe();

  let unitoUserId = await trello.get('member', 'private', 'unitoUserId');
  if (!unitoUserId) {
    unitoUserId = await Trello.loadSecretSafe('providerIdentityUserId', trello);
    try {
      trello.set('member', 'private', 'unitoUserId', unitoUserId);
    } catch (err) {
      reportException(err, 'Problem while trying to set the unito user id');
    }
  }
};

export async function getJwtData(t) {
  const trello = t || T.iframe();
  if (!trello.isMemberSignedIn()) {
    return {};
  }

  const jwt = await trello.jwt();
  return jwtDecode(jwt);
}

const getFullUrl = (relUrl) => window.TrelloPowerUp.util.relativeUrl(relUrl);

export const cardButtonsInit = async (t) => {
  const { organization } = await t.getContext();
  if (!organization) {
    return [];
  }

  return [
    {
      icon: getFullUrl(URL_GRAY_ICON),
      text: 'Mirror',
      callback: onCardButtonClick,
    },
  ];
};

// https://developers.trello.com/v1.0/reference#card-badges
export const cardBadgesHandler = async (t, _options) => {
  return [
    {
      dynamic: async () => {
        const count = await getSyncsCountForCurrentCard(t);

        return {
          text: count || '',
          icon: count ? getFullUrl(URL_GRAY_ICON) : '',
          color: null,
          // We don't use refresh, as we work at *board* level for efficiency. See fetchBackendCardsyncs
          // refresh: 1, // in seconds, minimum 10s
        };
      },
    },
  ];
};

export const onCardButtonClick = async (t) => {
  const defaultScreen = MIRROR_CARD_TO_POPUP;
  const syncAccountData = await getSyncAccountData(t);
  const screenToDisplay = await getScreenToDisplay(t, syncAccountData, defaultScreen);
  return t[screenToDisplay.function](screenToDisplay.screen);
};

/**
 * Careful, this initialization code is called:
 * - On initial load, of course...
 * - ... but also when writing card attributes with `trello.set(cardId, ...)` !
 * We use this to hide the powerup when it makes no sense to display it, to save
 * vertical space for the user, only displaying the powerup when the card has mirrors.
 */
export const cardBackSectionInit = async (t, _options) => {
  const syncsCount = await getSyncsCountForCurrentCard(t);
  const powerUpIsUsableByCurrentUser = await powerUpIsUsableByUser(t);

  if (!powerUpIsUsableByCurrentUser || syncsCount === 0) {
    // We may end up here in cases where work is still happening, maybe with
    // HTTP requests scheduled. In particular, adding/removing a Mirror needs to
    // call Maestro *after* the Trello Storage write. But when we write 0, given
    // that init is called on `t.set()`, we'll end up here. At that point,
    // immediately returning undefined would cause Trello to nuke our iframe,
    // the browser will (logically) cancel HTTP requests associated to it, and
    // we'll have failed to make our last maestro call.
    // -> Delaying the nuking a bit to let work finish.
    //
    // Note 1: It is fine to delay "returning undefined which cause iframe nuking",
    //         I confirmed that delaying a nuke, then setting the count to 1
    //         (thus restoring the iframe!) and waiting for the nuke doesn't
    //         undesirably nuke the newly-desired iframe.
    //         Trello frontend does a proper job at powerup iframe management.
    //
    // Note 2: How long to wait? Given above Note 1, it's safe to wait a long time,
    //         and the more we wait, the less we risk cases of HTTP ack not being sent yet.
    //         But also, there is a Trello-enforced limit to iframe init duration.
    //         Contrarily to `on-disable` (500ms), `card-back-section` isn't documented,
    //         but waiting for a stupid amount of time here shows Trello limits to 5s.
    //         -> Waiting a few seconds, but less than five-plus-buffer seems reasonable.
    //
    // Note 3: Why not a lock / global var to take note of pending requests,
    //         and "properly" wait for them to finish? Because it'd be complicado
    //         and prone to race conditions. A delay is simple, and safe given Note 1.
    await delay(2000);

    return;
  }

  return {
    title: 'Mirror',
    icon: getFullUrl(URL_GRAY_ICON),
    content: {
      type: 'iframe',
      url: t.signUrl('./sister-tasks'),
      height: 75,
    },
  };
};

export const stopBackGroundFrontOfCardFetcher = (fetchBackendInterval) => {
  if (fetchBackendInterval.length) {
    fetchBackendInterval.forEach((interval) => {
      clearInterval(interval);
    });
    fetchBackendInterval = [];
  }
};

export const setupBackgroundFrontOfCardFetcher = async (t, fetchBackendInterval) => {
  await Trello.forceFrontOfCardsUpdate({ t });
  // clear any existing interval before creating a new one
  // we need this check because fast alt-tabbing can break the window visibility check
  if (fetchBackendInterval.length) {
    stopBackGroundFrontOfCardFetcher(fetchBackendInterval);
  }
  const fetchBackendIntervalInner = setInterval(
    async () => {
      try {
        await fetchBackendCardsyncs(t);
      } catch (err) {
        if (
          err.message &&
          (err.message.includes('disabled on board') || err.name === 'InvalidCookieTokenError') // stop the loop if the clients cookie token is invalid
        ) {
          stopBackGroundFrontOfCardFetcher(fetchBackendInterval);
        } else {
          throw err;
        }
      }
    },
    Math.max(FRONT_OF_CARD_BACKEND_REFRESH_PERIOD_MS / 6, 10 * 1000), // refresh a few times per period
  );
  fetchBackendInterval.push(fetchBackendIntervalInner);
};
// Sync states per card for front-of-card must be fetched from backend for
// reliable sister side. We have no "push" infrastructure yet, so we refresh
// periodically. So, to avoid load, we ensure to refresh at most once per
// refresh period per board (and not once per refresh period per user).
export const fetchBackendCardsyncs = async (t) => {
  const lastFetch = new Date((await t.get('board', 'shared', 'cardSyncsLastFetch')) || 0);
  const lastFetchExpiry = new Date(lastFetch.getTime() + FRONT_OF_CARD_BACKEND_REFRESH_PERIOD_MS);
  const isLastFetchExpired = new Date() > lastFetchExpiry;

  if (isLastFetchExpired) {
    await forceFrontOfCardsUpdate({ t });
  }
};

export const handlePageVisibilityChange = async (trelloClient, fetchBackendInterval) => {
  const { hidden } = getPageVisibility();

  if (!trelloClient) {
    return;
  }

  const isPowerUpUsableByUser = await powerUpIsUsableByUser(trelloClient);
  if (!isPowerUpUsableByUser) {
    return;
  }

  if (document[hidden]) {
    stopBackGroundFrontOfCardFetcher(fetchBackendInterval);
  } else {
    setupBackgroundFrontOfCardFetcher(trelloClient, fetchBackendInterval);
  }
};

export const onDisableMirrorPowerUp = async (t, fetchBackendInterval, _fromBoardSyncOnDisable = false) => {
  // We only have 500ms before the power-up closes, so we have to get all we
  // need quickly. https://developers.trello.com/v1.0/reference#on-disable
  t.remove('board', 'shared', 'syncAccountData');
  t.remove('board', 'shared', 'cardSyncsLastFetch');
  t.remove('board', 'shared', 'cardsSeenSynced');
  t.remove('board', 'shared', 'mirror2WayBoardSyncEnabled');
  t.remove('board', 'shared', 'unitoSync2WayMirrorEnabled');

  const token = await loadSecretSafe('token', t);
  const { board, member } = await getContext(t);
  stopBackGroundFrontOfCardFetcher(fetchBackendInterval);
  if (!token || !board || !member) {
    return;
  }

  try {
    return await Api.deleteBoardSyncs(t, token, board, member);
  } catch (err) {
    reportException(err, 'Failed while disabling Mirror powerup');
  }
};

export const onEditFieldSettingsClick = async (t) => {
  return t.popup(Trello.MIRROR_SETTINGS_POPUP);
};

export const clearBoardAndOrgData = async (t) => {
  t.remove('board', 'shared', 'syncAccountData');
  t.remove('organization', 'shared', 'syncAccountData');
  t.remove('organization', 'shared', 'fieldSettings');
  t.remove('board', 'shared', 'fieldSettings');
  t.alert({
    message: 'Sync account data cleared',
    display: 'success',
  });
};

export const refreshFrontOfCardSyncCounts = async (t) => {
  const cardIds = await t.cards('id');

  for (const { id } of cardIds) {
    const count = await Trello.getCardSharedAttribute(t, id, 'syncsCount');
    if (count !== undefined) {
      Trello.setCardSharedAttribute(t, id, 'syncsCount', 0);
    }
  }

  await Trello.forceFrontOfCardsUpdate({ t, forceFullRefresh: true });
};

export const onShowSettings = async (t) => {
  // We don't need to check if the user is authorized in here
  // the authorizationStatus callback determines if this function is accessible or not
  if (!window.localStorage || !window.sessionStorage) {
    return t.popup(Trello.LOCAL_STORAGE_UNAVAILABLE_POPUP);
  }

  const providerName = getQueryParameter('provider');

  const powerUpTitle =
    providerName?.toLowerCase() === 'trello' ? POWERUP_DISPLAY_NAMES.BOARDSYNC : POWERUP_DISPLAY_NAMES.MIRROR;

  const isAdmin = await Trello.isCurrentMemberAdmin(t);
  const shouldAllowAnyoneToClearData = await Trello.shouldAllowAnyoneToClearData(t);

  return isAdmin || shouldAllowAnyoneToClearData
    ? t.popup({
        title: `${powerUpTitle} settings`,
        items: [
          {
            text: 'Edit Mirrored fields...',
            callback: onEditFieldSettingsClick,
          },
          {
            text: 'Clear sync account data',
            callback: clearBoardAndOrgData,
          },
          {
            text: 'Force update mirror badge on cards',
            callback: refreshFrontOfCardSyncCounts,
          },
        ],
      })
    : onEditFieldSettingsClick(t);
};

export const authorizationStatus = async (t, _options) => {
  return {
    authorized: (await Trello.powerUpIsUsableByUser(t)) || (await Trello.needToAssociateTeamWithUnitoOrg(t)),
  };
};

export const refreshToken = async (t) => {
  const freshToken = await Api.rehydrateCached(t);
  await setCurrentUserDataAtSecret(t, { token: freshToken });
};
