import React, { Component } from 'react';
import styled from 'styled-components';

import * as TrelloUtils from '../../util/trello';
import { CheckListItem, Text, Tooltip } from '../index';
import withStorage from '../withStorage';
import { trackEvent, EVENTS } from '../../util/tracking';
import { INTERNAL_SOURCES } from '../../consts';

const Section = styled.div`
  margin-top: 24px;
`;

const SectionTitle = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`;

const QuestionMark = styled.div`
  padding: 0.25rem;
  border: 1px solid black;
  display: inline-flex;
  color: black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const TWO_WAY_MIRROR_SYNC_ENABLED = 'TWO_WAY_MIRROR_SYNC_ENABLED';

class SettingsPopupComponent extends Component {
  state = {
    isCurrentMemberAdmin: false,
    showMirrorSyncButton: false,
    showTooltip: false,
  };

  async componentDidMount() {
    await this.setInitialTrelloSettings();
    this.props.t.render(this.setInitialTrelloSettings);
  }

  componentDidUpdate() {
    this.props.t.sizeTo('#root');
  }

  componentWillUnmount() {
    clearTimeout(this.buttonCaptionTimeout);
  }

  async setInitialTrelloSettings() {
    this.setState({
      showMirrorSyncButton: await TrelloUtils.is2WayMirrorEnabled(),
      isCurrentMemberAdmin: await TrelloUtils.isCurrentMemberAdmin(),
    });
  }

  onToggleMirrorSyncButton = async () => {
    const { showMirrorSyncButton } = this.state;
    this.setState(
      {
        showMirrorSyncButton: !showMirrorSyncButton,
      },
      async () => {
        await TrelloUtils.set2WayMirrorEnabled(this.props.t, this.state.showMirrorSyncButton);
        trackEvent(
          EVENTS.USER_MENU_SETTINGS_ACTION,
          {
            action_name: `turned 2 way mirror sync ${this.state.showMirrorSyncButton ? 'ON' : 'OFF'}`,
          },
          this.props.t,
          INTERNAL_SOURCES.BOARDSYNC,
          async () => {
            // needs to be a callback to tracking, otherwise the trello modal call unmounts the originator and cancels the tracking call
            if (this.state.showMirrorSyncButton) {
              await TrelloUtils.set2WayBoardSyncEnabled(this.props.t, true);
              this.props.t.modal({
                fullscreen: false,
                height: 500,
                title: 'Discover 2-Way Card Mirroring with Unito',
                url: './discover-mirror',
              });
            }
          },
        );
      },
    );
  };

  render() {
    const { isCurrentMemberAdmin, showMirrorSyncButton, showTooltip } = this.state;

    return (
      <div>
        <Section>
          <SectionTitle>
            <Text type="bold">
              2-Way Card Mirroring enabled{' '}
              <QuestionMark
                onClick={() => {
                  this.setState((newState) => ({ showTooltip: !newState.showTooltip }));
                }}
              >
                ?
              </QuestionMark>
            </Text>
            {showTooltip && (
              <Tooltip>
                Ensure you don't also have the 2-Way Card Mirroring power-up installed separately, otherwise enabling
                this option will cause a conflict between them.
              </Tooltip>
            )}
          </SectionTitle>

          <CheckListItem
            value={TWO_WAY_MIRROR_SYNC_ENABLED}
            displayName={!showMirrorSyncButton ? `Show Mirror button` : `Hide Mirror button`}
            checked={showMirrorSyncButton}
            readOnly={!isCurrentMemberAdmin}
            handleToggle={this.onToggleMirrorSyncButton}
          />
        </Section>
      </div>
    );
  }
}

export const SettingsPopup = withStorage(SettingsPopupComponent);
