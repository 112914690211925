import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { RadioButton } from '../index';
import { color as themeColor } from '../../theme';

const InlineGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default class RadioButtonGroup extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    inline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedColor: PropTypes.string,
    selectedValue: PropTypes.any,
  };

  static defaultProps = {
    inline: false,
    selectedColor: themeColor.primary,
  };

  handleChange = (newSelection) => {
    this.props.onChange(newSelection);
  };

  renderOptions = () => {
    const { children } = this.props;

    return React.Children.map(
      children,
      (option) => {
        const { color, label, value, ...rest } = option.props;

        return (
          <RadioButton
            {...rest}
            color={color || this.props.selectedColor}
            isChecked={value === this.props.selectedValue}
            key={value}
            label={label}
            name={this.props.name}
            check={this.handleChange}
            value={value}
          />
        );
      },
      this,
    );
  };

  render() {
    const Element = this.props.inline ? InlineGroup : 'div';

    return <Element className="radio-button-group"> {this.renderOptions()} </Element>;
  }
}
