import React, { Component } from 'react';
import styled from 'styled-components';

import { Href, Text } from '../index';
import { trackEvent, EVENTS } from '../../util/tracking';
import MIRROR_ILLUSTRATION_MOONLIGHT from '../../images/mirror_illustration_moonlight.svg';
import withStorage from '../withStorage';
import { getPowerUpName } from '../../util/helpers';

const LogoWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const TextWrapper = styled.div`
  padding: 1.5rem;
`;

const IMG_SIZE = 230;
const JOIN_TEAM_ACTION = 'BLOCKED: clicked on join the team';
class BlockedUserPopup extends Component {
  componentDidMount() {
    trackEvent(EVENTS.SIGNUP_START, { label: 'embed-trello' }, this.props.t, this.props.t.arg('source'));
    trackEvent(EVENTS.SIGNUP_BLOCKED, { reason: 'not a team member' }, this.props.t, this.props.t.arg('source'));
  }

  render() {
    const powerupName = getPowerUpName(this.props.t.arg('source'));
    return (
      <div>
        <LogoWrapper>
          <img
            style={{ width: `${IMG_SIZE}px`, height: `${IMG_SIZE}px` }}
            width={IMG_SIZE}
            height={IMG_SIZE}
            src={MIRROR_ILLUSTRATION_MOONLIGHT}
            alt="Mirror by Unito"
          />
        </LogoWrapper>
        <TextWrapper>
          <Text type="centered">
            <Text type="bold">Sorry, you must be part of this Trello team to use {powerupName}</Text>
            You can ask for a team member to{' '}
            <Href
              href="https://help.trello.com/article/715-inviting-people-to-an-organization"
              onClick={() =>
                trackEvent(EVENTS.SIGNUP_ACTION, {
                  action_name: JOIN_TEAM_ACTION,
                })
              }
            >
              invite you to the team
            </Href>
            .
          </Text>
        </TextWrapper>
      </div>
    );
  }
}

export default withStorage(BlockedUserPopup);
