import React, { Component } from 'react';
import styled from 'styled-components';

import { trackEvent, EVENTS } from '../../util/tracking';
import { Text } from '../index';
import withStorage from '../withStorage';
import URL_UNITO_LOGO from '../../images/Unito_logo.svg';

const LogoWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const TextWrapper = styled.div`
  padding: 0.4rem 1.5rem 1.5rem 1.5rem;
`;

const LOGO_WIDTH = 60;

export default class PowerUpUnavailableComponent extends Component {
  async componentDidMount() {
    trackEvent(EVENTS.SIGNUP_START, { label: 'embed-trello' });
    trackEvent(EVENTS.SIGNUP_BLOCKED, { reason: this.props.t.arg('reason') });
  }

  componentDidUpdate() {
    this.props.t.sizeTo('#root');
  }

  render() {
    return (
      <div>
        <LogoWrapper>
          <img
            style={{ width: `${LOGO_WIDTH}px`, height: `${LOGO_WIDTH}px` }}
            width={LOGO_WIDTH}
            height={LOGO_WIDTH}
            src={URL_UNITO_LOGO}
            alt="Mirror by Unito"
          />
        </LogoWrapper>
        <TextWrapper>
          <Text type="centered">
            <Text type="bold">{this.props.t.arg('message')}</Text>
          </Text>
        </TextWrapper>
      </div>
    );
  }
}

export const PowerUpUnavailable = withStorage(PowerUpUnavailableComponent);
