import { getMaestroEmbedUrl } from '../util/fetch';
import { INTERNAL_SOURCES, POWERUP_DISPLAY_NAMES, TASK_COUNT_FETCH_TIMEOUT, UNITO_WORKSPACE_STATUSES } from '../consts';
import * as Trello from '../util/trello';
import * as Api from '../util/api';
import { logger } from 'src/util/logger';

export const onShowAuthorization = async (t, source = INTERNAL_SOURCES.BOARDSYNC) => {
  const syncAccountData = await Trello.getSyncAccountData(t);
  const screenToDisplay = await Trello.getScreenToDisplay(t, syncAccountData, Trello.AUTH_POPUP);
  screenToDisplay.screen.args = { ...screenToDisplay.screen.args, source };
  return t[screenToDisplay.function](screenToDisplay.screen);
};

export const onShowPowerup = async (t, other_provider_name, source_product = undefined, providerName, powerupName) => {
  const lists = await t.lists('id');
  const { board, member, organization = '' } = t.args[0].context;
  const canWriteToBoard = t.memberCanWriteToModel('board');
  const title = powerupName;
  const maestroUrl = getMaestroEmbedUrl({
    accountId: organization,
    account_id: organization,
    container_id: Api.boardToUniqueId(board),
    other_provider_name: other_provider_name,
    source_product: source_product,
    setup: providerName,
    user_id: member,
  });

  const listCount = lists.length;
  if (listCount === 0 || !canWriteToBoard) {
    const { message, reason } = !canWriteToBoard
      ? {
          message: 'Whoops! You will need to be a member of this board to activate the Power-Up.',
          reason: 'not member of board',
        }
      : {
          message: 'Whoops! If you want to sync this board, it first needs to have a list.',
          reason: 'board has no list',
        };
    return t.popup({
      title,
      url: './unavailable-powerup',
      args: { message, reason },
    });
  }

  return t.modal({
    fullscreen: true,
    title,
    url: maestroUrl,
  });
};

export const getFullUrl = (relUrl) => window.TrelloPowerUp.util.relativeUrl(relUrl);

export const onPowerupButtonClick = async (t, powerupName, providerName) => {
  if (!t.isMemberSignedIn()) {
    return [];
  }

  const jwtData = await Trello.getJwtData(t);
  const source =
    powerupName === POWERUP_DISPLAY_NAMES.BOARDSYNC || powerupName === POWERUP_DISPLAY_NAMES.UNITOSYNC
      ? INTERNAL_SOURCES.BOARDSYNC
      : INTERNAL_SOURCES.REPORT;

  logger.setContext({
    userId: await t.get('member', 'private', 'unitoUserId'),
    trelloPluginId: jwtData.idPlugin,
    trelloPowerName: `${powerupName} ${providerName}`.trim(),
    nativeContainerId: jwtData.idBoard,
    nativeUserId: jwtData.idMember,
  });

  let linkCount = 0;
  try {
    linkCount = await Api.getLinkCountCached(t, TASK_COUNT_FETCH_TIMEOUT);
  } catch (err) {
    let errStringify;
    try {
      errStringify = JSON.stringify(err);
    } catch (e) {
      // it's not an object
    }
    logger.error(`Failed to getLinkCountCached with Err: ${errStringify ?? err}`);
  }

  const syncAccountData = await Trello.getSyncAccountData(t);
  const title = `${powerupName} ${syncAccountData.planName ? `| ${syncAccountData.planName}` : ''}`;

  // this will be removed when we add the Board Sync settings menu into the account sub menu
  const getHeight = () => {
    let min = 110;

    if (linkCount > 0) {
      min += 25;
    }

    if (powerupName === POWERUP_DISPLAY_NAMES.BOARDSYNC) {
      min += 25;
    }
    return min;
  };

  let defaultScreen = {
    title,
    url: './menu',
    height: getHeight(),
  };

  // Setting boolean isBoardSyncPowerup to true regardless of Board, Unito, or Report Sync
  // rather than adding booleans for each as a param
  // since the logic is the same for all 3
  const screenToDisplay = await Trello.getScreenToDisplay(t, syncAccountData, defaultScreen, true);

  if ([UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED].includes(syncAccountData?.status)) {
    screenToDisplay.screen = Trello.TRIAL_EXPIRED_MODAL;
  }

  screenToDisplay.screen.args = {
    ...screenToDisplay.screen.args,
    source,
    workspaceId: syncAccountData.workspaceId,
    linkCount,
    providerName,
    powerupName,
  };
  return t[screenToDisplay.function](screenToDisplay.screen);
};
