import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../theme';

const LinkButton = styled.button`
  border-color: transparent;
  background: transparent;
  color: ${color.whisper};
  box-shadow: none;
  text-decoration: underline;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal} !important;
  padding: 0;
  margin: 0;

  &:not([disabled]):active,
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    background: transparent;
    box-shadow: none;
    color: ${color.whisper};
    text-decoration: underline;
  }
`;

const DarkLinkButton = styled.button`
  border-color: transparent;
  background: transparent;
  color: ${color.black};
  box-shadow: none;
  text-decoration: underline;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.bold} !important;
  padding: 0;
  margin: 0;

  &:not([disabled]):active,
  &:not([disabled]):focus,
  &:not([disabled]):hover {
    background: transparent;
    box-shadow: none;
    color: ${color.black};
    text-decoration: underline;
  }
`;

const mapSizesToWidth = {
  sm: '5rem',
  md: '8rem',
  lg: '12rem',
  block: '100%',
};

const BaseButton = styled.button`
  color: ${color.light};
  width: ${(props) => mapSizesToWidth[props.size]};
`;

const PrimaryButton = styled(BaseButton)`
  background-color: ${color.primary};
  box-shadow: 0 1px 0 0 ${color.primaryDark};
  border: none;

  &:not([disabled]):hover {
    color: ${color.light};
    background-color: ${color.primaryLightDark};
    box-shadow: 0 1px 0 0 ${color.primaryDark};
    border: none;
  }

  &[disabled] {
    color: ${color.dark};
    background-color: ${color.lightGray};
    border: none;
  }
}
`;

const DangerButton = styled(BaseButton)`
  background-color: ${color.danger};
  box-shadow: 0 1px 0 0 ${color.dangerDark};

  &:not([disabled]):hover {
    color: ${color.light};
    background-color: ${color.danger};
  }
  margin: 0;
`;

const mapStyleToButton = {
  primary: PrimaryButton,
  danger: DangerButton,
  link: LinkButton,
  darkLink: DarkLinkButton,
  default: BaseButton,
};

export default class Button extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
    btnStyle: PropTypes.oneOf(Object.keys(mapStyleToButton)),
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'block']),
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    btnStyle: 'default',
    size: 'md',
  };

  render() {
    const { children, disabled, onClick, type, btnStyle, size, submitting } = this.props;

    const Button = mapStyleToButton[btnStyle];

    return (
      <Button onClick={onClick} disabled={disabled || submitting} type={type} size={size}>
        {children}
      </Button>
    );
  }
}
