import React, { Component } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { ErrorBoundary } from '@unitoio/sherlock';
import { Icon } from '@unitoio/mimics';

import * as Trello from '../../util/trello';
import * as Api from '../../util/api';
import { delay } from '../../util/helpers';

import * as Tracking from '../../util/tracking';
import { Button, CardSyncItem, Href, LoadingIcon, Text } from '../index';
import withStorage from '../withStorage';
import {
  deleteCardSync,
  getSyncsForCard,
  getUnitoPricingRouteForOrg,
  getUnitoBillingRouteForOrg,
} from '../../util/api';
import { forceBackOfCardUpdate, getModalForUnitoURL, getSyncAccountData } from '../../util/trello';
import { reportException, reportWithFunnel } from '../../util/logger';
import { color } from '../../theme';
import { MIRROR_BACK_OF_CARD, SYNC_STATE_INPROGRESS, UNITO_APP_URL } from '../../consts';

const Header = styled.div`
  text-align: center;
  position: relative;
  color: ${color.whisper};

  hr {
    margin: 0.7rem 0;
  }
`;

const Content = styled.div`
  margin: 1rem 0;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
  position: absolute;
  right: 0.25rem;
`;

const BackgroundPlaceholder = styled.div`
  background-color: ${color.lightGray};
  border-radius: 4px;
  color: ${color.whisper};
  padding: 1rem;
  text-align: center;
`;

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '300px',
    padding: '.75rem',
  },
  overlay: {
    backgroundColor: null,
  },
};

const backOfCardLogger = reportWithFunnel(MIRROR_BACK_OF_CARD);

const Processing = () => (
  <BackgroundPlaceholder>
    <LoadingIcon /> Processing...
  </BackgroundPlaceholder>
);

export class BackOfCardSectionComponent extends Component {
  inprogressSyncsBackoffFactor = 1;

  state = {
    errorLoadingSyncs: false,
    isLoading: false,
    cardSyncs: [],
    showRemoveSyncModal: false,
    removeSyncModalLinkId: null,
    removingCardLinkId: null,
    uniqueId: null,
  };

  async componentDidMount() {
    backOfCardLogger.reportInfo(`START ${MIRROR_BACK_OF_CARD} funnel`, { funnel: { action: 'START' } });
    this.setState({ isLoading: true });
    const { workspaceId, status } = await getSyncAccountData();
    const isCurrentMemberAdmin = await Trello.isCurrentMemberAdmin();
    const { card } = await Trello.getContext(this.props.t);
    const uniqueId = Api.cardToUniqueId(card);

    let errorLoadingSyncs = false;
    let cardSyncs = [];
    try {
      cardSyncs = await getSyncsForCard();
    } catch (error) {
      backOfCardLogger.reportException(error, 'Failed at fetching syncs for back of card');
      errorLoadingSyncs = true;
    } finally {
      this.setState({
        workspaceId,
        status,
        errorLoadingSyncs,
        isLoading: false,
        cardSyncs,
        isCurrentMemberAdmin,
        uniqueId,
      });
    }

    this.props.t.render(this.getResources);
  }

  componentDidUpdate() {
    const { showRemoveSyncModal, cardSyncs } = this.state;
    // Here we test for the presence of cardSyncs to avoid the logging of a resizing error
    if (!showRemoveSyncModal && cardSyncs.length !== 0) {
      this.props.t.sizeTo('#root');
    }
  }

  getResources = async () => {
    let cardSyncs;
    let isCurrentMemberAdmin;
    try {
      cardSyncs = await getSyncsForCard();
      isCurrentMemberAdmin = await Trello.isCurrentMemberAdmin();
    } catch (err) {
      cardSyncs = [];
      backOfCardLogger.reportException(err, 'Failed to fetch sister tasks in BackOfCardSection');
    }

    try {
      isCurrentMemberAdmin = await Trello.isCurrentMemberAdmin();
    } catch (err) {
      backOfCardLogger.reportException(err, 'Failed to fetch isCurrentMemberAdmin');
    }

    this.setState({ cardSyncs, isCurrentMemberAdmin });

    if (cardSyncs.find((sync) => sync.state === SYNC_STATE_INPROGRESS)) {
      // If we stumble upon "in progress" syncs (local, not acknowledged yet by
      // sync-worker-dependent endpoints like `/taskSyncs`), we schedule to try
      // getting the real backend syncs later. This will loop (with backoff):
      // if backend still doesn't have them, we'll retry again later, etc.
      //
      // Delay is exponential in case things go wrong, and doesn't have to be
      // super short, an "in-progress" sync is displayed and functional.
      await delay(2000 * this.inprogressSyncsBackoffFactor);
      await forceBackOfCardUpdate();
      this.inprogressSyncsBackoffFactor *= 2;
    } else {
      this.inprogressSyncsBackoffFactor = 1;
    }
  };

  openRemoveSyncModal = (linkId) => {
    this.setState({
      showRemoveSyncModal: true,
      removeSyncModalLinkId: linkId,
    });
  };

  closeRemoveSyncModal = () => {
    this.setState({
      showRemoveSyncModal: false,
      removeSyncModalLinkId: null,
    });
  };

  deleteSync = async () => {
    const { removeSyncModalLinkId } = this.state;

    this.setState({ removingCardLinkId: removeSyncModalLinkId });
    this.closeRemoveSyncModal();

    try {
      await deleteCardSync(this.props.t, removeSyncModalLinkId);
    } catch (error) {
      reportException(error, 'Error when deleting a card sync');
    } finally {
      this.setState({ removingCardLinkId: null });
    }
  };

  resizeModal = (node) => {
    if (node === null) {
      return;
    }
    // resize the parent iframe to be at least as big as the modal height
    const parentHeight = document.getElementById('root').clientHeight;
    this.props.t.sizeTo(Math.max(node.clientHeight + 40, parentHeight));
  };

  openChoosePlanWindow = async () =>
    getModalForUnitoURL(this.props.t, getUnitoPricingRouteForOrg(this.state.workspaceId));

  openBillingPage = async () => getModalForUnitoURL(this.props.t, getUnitoBillingRouteForOrg(this.state.workspaceId));

  getItemStatusPageHref = () => {
    const { isCurrentMemberAdmin, uniqueId } = this.state;

    if (!isCurrentMemberAdmin || !uniqueId) {
      return null;
    }

    return (
      <Href
        onClick={() => {
          Tracking.trackEvent(Tracking.EVENTS.NEW_SYNC_ACTION, { action_name: 'clicked on see card status' });
        }}
        type="subtle"
        href={`${UNITO_APP_URL}/#/dashboard/items/${uniqueId}/status`}
      >
        See card status
      </Href>
    );
  };
  render() {
    const { errorLoadingSyncs, cardSyncs, isLoading, status } = this.state;

    if (isLoading) {
      return (
        <div>
          {this.getItemStatusPageHref()}
          <LoadingIcon /> Loading...
        </div>
      );
    }

    if (errorLoadingSyncs) {
      return (
        <>
          {this.getItemStatusPageHref()}
          <BackgroundPlaceholder>
            <Text>
              There was a problem loading the list of mirrored cards. Try again later or contact our team at{' '}
              <Href href="mailto: support@unito.io">support@unito.io</Href>
            </Text>
          </BackgroundPlaceholder>
        </>
      );
    }

    if (cardSyncs.length === 0) {
      return <div>This card is not mirrored yet!</div>;
    }

    return (
      <div>
        {this.getItemStatusPageHref()}
        {cardSyncs.map((cardSync) => {
          if (cardSync.linkId === this.state.removingCardLinkId) {
            return <Processing key={cardSync.linkId} />;
          }

          return (
            <div key={cardSync.linkId}>
              <CardSyncItem
                boardName={cardSync.boardName}
                destinationContainerUniqueId={cardSync.destinationContainerUniqueId}
                linkId={cardSync.linkId}
                listName={cardSync.listName}
                removeCard={() => this.openRemoveSyncModal(cardSync.linkId)}
                state={cardSync.state}
                openChoosePlanWindow={this.openChoosePlanWindow}
                openBillingPage={this.openBillingPage}
                subscriptionStatus={status}
                url={cardSync.url}
              />
              <hr />
            </div>
          );
        })}
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showRemoveSyncModal}
          onRequestClose={this.closeRemoveSyncModal}
          contentLabel="Remove Mirror"
          style={customModalStyles}
          contentRef={(node) => this.resizeModal(node)}
        >
          <Header>
            Remove Mirror?
            <IconWrapper>
              <Icon name="times" size="sm" kind="solid" onClick={this.closeRemoveSyncModal} />
            </IconWrapper>
            <hr />
          </Header>
          <Content>
            <Text>
              Removing a sync will leave the mirrored cards untouched, but updates will no longer be synchronized.
            </Text>
          </Content>
          <ButtonWrapper>
            <Button size="block" btnStyle="danger" onClick={this.deleteSync}>
              Remove
            </Button>
          </ButtonWrapper>
        </Modal>
      </div>
    );
  }
}

function BackOfCardSectionWithErrorBoundary(props) {
  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error, { componentStack }, errMessageContext) =>
        backOfCardLogger.reportException(error, error.message, { ...errMessageContext, componentStack })
      }
    >
      <BackOfCardSectionComponent {...props} />
    </ErrorBoundary>
  );
}

export const BackOfCardSection = withStorage(BackOfCardSectionWithErrorBoundary);
