import React, { Component } from 'react';

import { T } from '../util/trello';

export default function withStorage(WrappedComponent) {
  return class Storage extends Component {
    t = T.iframe();

    getDisplayName = (WrappedComponent) => `Storage(${getDisplayName(WrappedComponent)})`;

    render() {
      const props = { t: this.t, ...this.props };
      return <WrappedComponent {...props} />;
    }
  };
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
