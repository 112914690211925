import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../theme';

const BaseStyle = styled.div`
  font-size: ${(props) => (props.size ? fontSize[props.size] : fontSize.medium)};
  font-weight: ${fontWeight.normal};
  color: ${color.dark};
  font: Roboto;
`;

const WarningStyle = styled(BaseStyle)`
  color: ${color.warning};
`;

const BoldStyle = styled(BaseStyle)`
  font-weight: ${fontWeight.bold};
`;

const ErrorStyle = styled(BaseStyle)`
  color: ${color.error};
  font-weight: ${fontWeight.bold};
`;

const TipsStyle = styled(BaseStyle)`
  color: ${color.whisper};
  font-weight: ${fontWeight.normal};
`;

const CenteredStyle = styled(BaseStyle)`
  text-align: center;
  font-weight: ${fontWeight.normal};
`;

const textStyles = {
  centered: CenteredStyle,
  default: BaseStyle,
  error: ErrorStyle,
  tips: TipsStyle,
  warning: WarningStyle,
  bold: BoldStyle,
};

const Text = ({ children, type, ...rest }) => {
  const StyleText = textStyles[type];
  return <StyleText {...rest}>{children}</StyleText>;
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.keys(textStyles)),
};

Text.defaultProps = {
  type: 'default',
};

export default Text;
