import React, { Component } from 'react';
import styled from 'styled-components';

import { Text } from '../index';
import { trackEvent, EVENTS } from '../../util/tracking';
import withStorage from '../withStorage';
import { getPowerUpName } from '../../util/helpers';
import MIRROR_ILLUSTRATION_MOONLIGHT from '../../images/mirror_illustration_moonlight.svg';

const LogoWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
`;

const TextWrapper = styled.div`
  padding: 1.5rem;
`;

const IMG_SIZE = 230;

export class SetupIncompleteComponent extends Component {
  componentDidMount() {
    trackEvent(EVENTS.SIGNUP_START, { label: 'embed-trello' }, this.props.t, this.props.t.arg('source'));
    trackEvent(EVENTS.SIGNUP_BLOCKED, { reason: 'Incomplete setup' }, this.props.t, this.props.t.arg('source'));
  }
  render() {
    const syncAccountDisplayName = this.props.t.arg('syncAccountDisplayName');
    const powerUpName = getPowerUpName(this.props.t.arg('source'));

    return (
      <div>
        <LogoWrapper>
          <img
            style={{ width: `${IMG_SIZE}px`, height: `${IMG_SIZE}px` }}
            width={IMG_SIZE}
            height={IMG_SIZE}
            src={MIRROR_ILLUSTRATION_MOONLIGHT}
            alt="Mirror by Unito"
          />
        </LogoWrapper>
        <TextWrapper>
          <Text type="centered">
            {powerUpName} is not completely setup! The first user to authorize {powerUpName} ({syncAccountDisplayName})
            should complete the setup before others can use it!
          </Text>
        </TextWrapper>
      </div>
    );
  }
}

export const SetupIncompletePopup = withStorage(SetupIncompleteComponent);
