import React, { useEffect } from 'react';

import { logger } from 'src/util/logger';

import * as Trello from '../../util/trello';
import { getQueryParameter } from '../../util/fetch';
import { trackEvent, EVENTS } from '../../util/tracking';
import { POWERUP_CAPABILITIES, INTERNAL_SOURCES, POWERUP_DISPLAY_NAMES } from '../../consts';
import { onShowAuthorization, getFullUrl, onPowerupButtonClick } from '../common';

const getProviderName = () => {
  const providerName = getQueryParameter('provider');
  return providerName?.toLowerCase();
};

const getPowerUpContext = () => {
  return 'Report';
};

const onInitializePowerup = async (t) => {
  if (!t.isMemberSignedIn()) {
    return [];
  }

  const isPowerUpUsableByUser = await Trello.powerUpIsUsableByUser(t);
  if (isPowerUpUsableByUser) {
    await Trello.refreshToken(t);
    await Trello.replicateOrgDataToBoard(t);
    await Trello.copyUnitoUserIdToMemberScope(t);
    await Trello.fetchAndSetWorkspaceInfo(t);
  }

  const provider = getProviderName();
  const contextName = getPowerUpContext();
  const jwtData = await Trello.getJwtData(t);
  const syncAccountData = await Trello.getSyncAccountData(t);

  logger.setContext({
    userId: await t.get('member', 'private', 'unitoUserId'),
    trelloPluginId: jwtData?.idPlugin,
    trelloPowerName: `${contextName} ${provider}`,
    nativeContainerId: jwtData?.idBoard,
    nativeUserId: jwtData?.idMember,
    syncAccountData,
  });

  const unitoReportPowerupButton = {
    text: POWERUP_DISPLAY_NAMES.REPORT,
    callback: async (t) => onPowerupButtonClick(t, POWERUP_DISPLAY_NAMES.REPORT, getProviderName()),
    icon: getFullUrl(`/unito_logo_heavy_stroke.svg`),
    condition: 'edit',
  };

  return [unitoReportPowerupButton];
};

/**
 * Unito/Report Sync Powerup.
 * Embedded version of unito's app, specifically on the Report sync page served in an iframe.
 *
 * When used within trello, this powerup is called Report Sync
 * */
export const AppUnitoReportPowerup = () => {
  useEffect(() => {
    const providerName = getQueryParameter('provider');
    window.TrelloPowerUp.initialize({
      [POWERUP_CAPABILITIES.AUTHORIZATION_STATUS]: Trello.authorizationStatus,
      [POWERUP_CAPABILITIES.ON_DISABLE]: async (t) => {
        trackEvent(
          EVENTS.USER_MENU_ACTION,
          {
            action_name: 'clicked Disable',
            tool_name: providerName,
          },
          t,
          INTERNAL_SOURCES.REPORT,
        );

        // keep here eventho it says mirror, this also handles removing user data, it does everything
        return Trello.onDisableMirrorPowerUp(t, [], true);
      },
      [POWERUP_CAPABILITIES.ON_ENABLE]: (t) => {
        return trackEvent(
          EVENTS.INSTALL_PLUGIN,
          {
            tool_name: providerName,
          },
          t,
          INTERNAL_SOURCES.REPORT,
        );
      },
      [POWERUP_CAPABILITIES.SHOW_AUTHORIZATION]: async (t) => onShowAuthorization(t, INTERNAL_SOURCES.REPORT),
      [POWERUP_CAPABILITIES.BOARD_BUTTONS]: async (t) => onInitializePowerup(t),
      // stubbing because we ask for those capabilities, so those functions need to exist
      [POWERUP_CAPABILITIES.CARD_BUTTONS]: async (t) => undefined,
      [POWERUP_CAPABILITIES.CARDS_BADGES]: async (t) => undefined,
      // We don't use 'card-detail-badges', we just ask for the capability,
      // in case we want to use it in the future. But unlike other capabilities,
      // where asking for a cap. but not implementing it only causes warning
      // `Power-Up <powerUpId> declares capabilities that are not implemented`,
      // this one is called even when undefined, calling error
      // `Power-Up unhandled error responding to request for card-detail-badges`
      // So, we stub it.
      [POWERUP_CAPABILITIES.CARD_DETAIL_BADGES]: () => undefined,
      [POWERUP_CAPABILITIES.CARD_BACK_SECTION]: async (t) => undefined,
      [POWERUP_CAPABILITIES.SHOW_SETTINGS]: Trello.onShowSettings,
    });
    return () => {
      Trello.stopBackGroundFrontOfCardFetcher([]);
    };
  }, []);

  return (
    <div>
      <div>Report Power-Up</div>
      <div>Version: {process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default AppUnitoReportPowerup;
