import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontWeight } from '../../theme';

const LinkIcon = styled.span`
  float: right;
`;

const Link = styled.a`
  text-decoration: underline;
  color: ${(props) => color[TYPE_TO_COLOR[props.type]]};
  font-weight: ${(props) => props.fontWeight}
  &:hover {
    color: ${color.secondaryDarker};
  }
`;

const TYPE_TO_COLOR = {
  error: 'error',
  subtle: 'whisper',
  default: 'secondary',
};

export default class Href extends Component {
  static propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    onClick: PropTypes.func,
    withIcon: PropTypes.bool,
    targetBlank: PropTypes.bool,
    fontWeight: PropTypes.oneOf(Object.keys(fontWeight)),
    type: PropTypes.oneOf(['default', 'subtle', 'error']),
  };

  static defaultProps = {
    withIcon: false,
    targetBlank: true,
    type: 'default',
    fontWeight: 'normal',
  };

  render() {
    const { children, withIcon, targetBlank, ...rest } = this.props;
    const target = targetBlank ? '_blank' : '';

    return (
      <Link target={target} rel="noopener noreferrer" {...rest}>
        {children}
        {withIcon && (
          <LinkIcon>
            <i className="fa-external-link" />
          </LinkIcon>
        )}
      </Link>
    );
  }
}
