import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CheckListItem, LoadingIcon } from '../index';
import { getColorMode } from '../../util/helpers';

const Centered = styled.div`
  margin: 18px auto;
  text-align: center;
`;

export default class FieldsCheckList extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    handleToggle: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  };

  render() {
    const { items, handleToggle, isAdmin } = this.props;
    const colorMode = getColorMode();

    if (items.length === 0) {
      return (
        <Centered>
          <LoadingIcon size="24px" />
        </Centered>
      );
    }

    return (
      <div>
        {items.map(([key, field]) => (
          <CheckListItem
            colorMode={colorMode}
            key={key}
            value={key}
            displayName={field.displayName}
            checked={field.sync}
            disabled={!isAdmin}
            readOnly={field.readOnly}
            handleToggle={handleToggle}
            blocked={field.blocked}
          />
        ))}
      </div>
    );
  }
}
