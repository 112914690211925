import React from 'react';
import styled from 'styled-components';

import { Box, Typography, tokens, Icon, Callout, Bold } from '@unitoio/mimics';

import * as Api from '../util/api';
import * as Trello from '../util/trello';

const Clickable = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const MenuError = (t, workspaceId) => {
  return (
    <Box
      m={[0, 0, tokens.spacing.s4, 0]}
      borderRadius={1}
      borderSize={1}
      borderColor={tokens.colors.strokes.message.warning}
      backgroundColor={tokens.colors.global.primary.light}
    >
      <Callout
        showCloseButton={false}
        level={Callout.level.WARNING}
        size={Callout.size.LARGE}
        image={
          <Icon
            name="triangle-exclamation"
            kind={Icon.KINDS.SOLID}
            color={tokens.colors.content.warning.default}
            size="lg"
          />
        }
      >
        <Box m={[0, 0, 0, tokens.spacing.s4]}>
          <Typography variant={Typography.variants.BODY1}>
            <Bold>There was an error while getting your account information.</Bold>
          </Typography>

          <Typography variant={Typography.variants.BODY1} color={tokens.colors.content.neutral.n40}>
            Retry in a minute or{' '}
            <Clickable
              onClick={() => Trello.getModalForUnitoURL(t, Api.getUnitoAccountRouteForOrgForProjectSync(workspaceId))}
            >
              open the app
            </Clickable>
          </Typography>
        </Box>
      </Callout>
    </Box>
  );
};
