import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  background: #d9d9d9;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
`;

const TooltipPointer = styled.div`
  position: absolute;
  left: 216px;
  top: -4px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #d9d9d9;
`;

const Tooltip = ({ children }) => (
  <TooltipContainer>
    <TooltipPointer />
    {children}
  </TooltipContainer>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
