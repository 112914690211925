import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Box, Typography, tokens, LoadingPlaceholder, Link, Bold } from '@unitoio/mimics';

import * as Api from '../../util/api';
import withStorage from '../withStorage';
import { trackEvent, EVENTS } from '../../util/tracking';
import * as Trello from '../../util/trello';
import { logger } from '../../util/logger';
import { formatNumber, getColorMode } from '../../util/helpers';
import { PLAN_FEATURES } from 'src/consts';
import { MenuError } from '../MenuError';

const ColoredBold = styled(Bold)`
  color: ${(props) => props.color};
`;
const PlaceholderMargin = styled(LoadingPlaceholder)`
  margin-right: ${tokens.spacing.s2};
`;

const ACCOUNT_MENU_ITEMS = {
  ITEMS_IN_SYNC: 'items in sync',
  CHANGES: 'changes',
  PLAN: 'plan',
};

const StyledLink = styled(Link)`
  font-size: ${tokens.fontSize.f6};
  color: ${(props) => props.color};
`;

const renderPlan = (planName, trialUntil, isLoading, textColor, linkColor) => {
  const daysLeft = moment(trialUntil).fromNow(true);
  if (isLoading) {
    return (
      <Box flexDirection="row" m={[tokens.spacing.s4, 0, 0, 0]}>
        <LoadingPlaceholder width="6.375" height=".875rem" borderRadius={tokens.spacing.s4} />
      </Box>
    );
  }
  return (
    <Typography color={textColor} variant={Typography.variants.BODY1}>
      {planName === 'Trial' ? (
        <Box flexDirection="row" justifyContent="space-between">
          <Box flexDirection="row">
            <Typography>
              <Bold>{planName}</Bold> expires in {daysLeft}
            </Typography>
          </Box>
          <StyledLink color={linkColor}>See pricing</StyledLink>
        </Box>
      ) : (
        <Box flexDirection="row" justifyContent="space-between">
          <Bold>{planName}</Bold>
          <StyledLink color={linkColor}>Upgrade</StyledLink>
        </Box>
      )}
    </Typography>
  );
};

const renderItemsInSync = (usage, limit, isLoading, textColor, linkColor) => {
  if (isLoading) {
    return (
      <Box flexDirection="row" m={[tokens.spacing.s2, 0, 0, 0]} alignItems="center">
        <PlaceholderMargin width="6.375" height=".875" borderRadius={tokens.spacing.s4} />
        <Typography color={textColor} variant={Typography.variants.BODY1}>
          {ACCOUNT_MENU_ITEMS.ITEMS_IN_SYNC}
        </Typography>
      </Box>
    );
  }
  let color;

  if (usage > limit) {
    color = `${tokens.colors.content.destructive.default}`;
  }
  if (!isNaN(usage)) {
    usage = formatNumber(usage);
  }

  if (!isNaN(limit)) {
    limit = formatNumber(limit);
  }

  return (
    <>
      <Typography color={textColor} variant={Typography.variants.BODY1}>
        <ColoredBold color={color}>{usage}</ColoredBold> / {limit} items
      </Typography>
      <StyledLink color={linkColor}>See details</StyledLink>
    </>
  );
};

const renderChanges = (changes, isLoading, textColor, linkColor) => {
  if (isLoading) {
    return (
      <Box flexDirection="row" alignItems="center">
        <PlaceholderMargin width="6.375" height=".875" borderRadius={tokens.spacing.s4} />
        <Typography color={textColor} variant={Typography.variants.BODY1}>
          {ACCOUNT_MENU_ITEMS.CHANGES}
        </Typography>
      </Box>
    );
  }

  if (!isNaN(changes)) {
    changes = formatNumber(changes);
  }
  return (
    <>
      <Typography color={textColor}>
        <Bold>{changes}</Bold> / unlimited {ACCOUNT_MENU_ITEMS.CHANGES}
      </Typography>
      <StyledLink color={linkColor}>See {ACCOUNT_MENU_ITEMS.CHANGES}</StyledLink>
    </>
  );
};

function useFetchAccountData(t) {
  const refT = useRef(t);
  const [displayName, setDisplayName] = useState('');
  const [usage, setUsage] = useState();
  const [itemsInSyncLimit, setItemsInSyncLimit] = useState();
  const [trialUntil, setTrialUntil] = useState('');
  const [changesSynced, setChangesSynced] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [planName, setPlanName] = useState();
  const [workspaceId, setWorkspaceId] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { providerIdentityDisplayName, validUntil, workspaceId, planName } = await Trello.getSyncAccountData(
          refT.current,
        );
        const [syncActivity, itemUsage, plan] = await Promise.all([
          Api.getSyncActivity(refT.current, workspaceId),
          Api.getItemsUsage(refT.current, workspaceId),
          Api.getPlan(refT.current, workspaceId),
        ]);
        const { limit } = plan.features.find((feature) => feature.id === PLAN_FEATURES.MAX_ITEMS);
        setWorkspaceId(workspaceId);
        setDisplayName(providerIdentityDisplayName);
        setPlanName(planName);
        setUsage(itemUsage.usage[0].usage);
        setItemsInSyncLimit(limit);
        setChangesSynced(syncActivity.activity[0].countChangesSynced30d);
        setTrialUntil(validUntil);
      } catch (e) {
        let errStringify;
        try {
          errStringify = JSON.stringify(e);
        } catch (err) {
          // it's not an object
        }
        logger.error(`Failed to fetch data in account popup menu Err: ${errStringify ?? e}`);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    displayName,
    usage,
    itemsInSyncLimit,
    planName,
    trialUntil,
    isError,
    changesSynced,
    workspaceId,
    isLoading,
  };
}

const AccountPopupComponent = ({ t }) => {
  const { source, providerName } = t.args[1];
  const { displayName, usage, itemsInSyncLimit, changesSynced, planName, trialUntil, isError, workspaceId, isLoading } =
    useFetchAccountData(t);
  const refT = useRef(t);

  useEffect(() => {
    refT.current.sizeTo('#root');
  }, [isLoading]);

  const textColor = getColorMode();
  const linkColor = getColorMode('link');

  if (isError) {
    return <MenuError t={t} workspaceId={workspaceId} />;
  }
  // TODO add Settings sub menu when the powerup is Board Sync, next PR
  const handleOnClick = async (itemName) => {
    await trackEvent(
      EVENTS.USER_MENU_ACTION,
      { action_name: `clicked on ${itemName}`, selected_tool_name: providerName },
      t,
      source,
    );

    switch (itemName) {
      case ACCOUNT_MENU_ITEMS.ITEMS_IN_SYNC:
        return Trello.getModalForUnitoURL(t, Api.getUnitoAccountItemsInSyncRouteForOrgForProjectSync(workspaceId));
      case ACCOUNT_MENU_ITEMS.CHANGES:
        return Trello.getModalForUnitoURL(t, Api.getUnitoAccountChangesRouteForOrgForProjectSync());
      case ACCOUNT_MENU_ITEMS.PLAN:
        return Trello.getModalForUnitoURL(t, Api.getUnitoPricingRouteForOrgForProjectSync(workspaceId));
      default:
        return;
    }
  };

  return (
    <Box p={[tokens.spacing.s2]}>
      <Box m={[0, 0, tokens.spacing.s4]}>
        <Typography color={textColor} variant={Typography.variants.BODY1}>
          Logged in as {displayName}
        </Typography>
      </Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <Typography color={textColor} variant={Typography.variants.BODY1}>
          <Bold>Usage and Billing</Bold>
        </Typography>
      </Box>
      <Box m={[0, 0, tokens.spacing.s3, 0]} onClick={() => handleOnClick(ACCOUNT_MENU_ITEMS.PLAN)}>
        {renderPlan(planName, trialUntil, isLoading, textColor, linkColor)}
      </Box>
      <Box
        m={[0, 0, tokens.spacing.s3, 0]}
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => handleOnClick(ACCOUNT_MENU_ITEMS.ITEMS_IN_SYNC)}
      >
        {renderItemsInSync(usage, itemsInSyncLimit, isLoading, textColor, linkColor)}
      </Box>
      <Box
        m={[0, 0, tokens.spacing.s3, 0]}
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => handleOnClick(ACCOUNT_MENU_ITEMS.CHANGES)}
      >
        {renderChanges(changesSynced, isLoading, textColor, linkColor)}
      </Box>
    </Box>
  );
};

export const AccountPopup = withStorage(AccountPopupComponent);
