import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../theme';

const H1 = styled.h1`
  font-size: ${fontSize.h1};
  font-weight: ${fontWeight.medium};
  letter-spacing: 0.00735em;
  line-height: 2.5rem;
  margin-bottom: 2rem;
  text-align: ${(props) => props.align};
`;

const H2 = styled.h2`
  font-size: ${fontSize.h2};
  font-weight: ${fontWeight.bold};
  letter-spacing: normal;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  text-align: ${(props) => props.align};
`;

const H3 = styled.h3`
  color: ${color.dark};
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.bold};
  margin: 0;
  text-align: ${(props) => props.align};
`;

const H4 = styled.h4`
  color: ${color.dark};
  font-size: ${fontSize.h4};
  font-weight: ${fontWeight.bold};
  margin: 0;
  text-align: ${(props) => props.align};
`;

const H5 = styled.h5`
  color: ${color.dark};
  font-size: ${fontSize.h5};
  font-weight: ${fontWeight.bold};
  margin: 0;
  text-align: ${(props) => props.align};
`;

const elements = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
};

const alignments = {
  left: 'left',
  right: 'right',
  center: 'center',
  justify: 'justify',
};

export default class Title extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    align: PropTypes.oneOf(Object.keys(alignments)),
    type: PropTypes.oneOf(Object.keys(elements)),
  };

  static defaultProps = {
    type: 'h1',
    align: 'left',
  };

  render() {
    const { children, className, type, ...rest } = this.props;

    const Element = elements[type];

    return (
      <Element className={className} {...rest}>
        {children}
      </Element>
    );
  }
}
