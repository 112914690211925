import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ErrorBoundary, TrackingFunnel } from '@unitoio/sherlock';

import {
  Auth,
  AppMirrorPowerup,
  AppUnitoReportPowerup,
  AppUnitoSyncPowerup,
  BackOfCardSyncForm,
  BackOfCardSection,
  BlockedUserPopup,
  ConfigureAccount,
  DiscoveryModal,
  MirrorSettingsPopup,
  OrgRevivedModal,
  PersonalBoardsModal,
  PowerupBlocker,
  PowerUpUnavailable,
  RedirectTo,
  SetupIncompletePopup,
  UnitoSyncSettingsPopup,
  AccountPopup,
  UnitoReportAndUnitoSyncMenu,
  GetHelpMenu,
  TrialExpired,
} from 'src/components';

const root = createRoot(document.getElementById('root'));
root.render(
  <Router basename="/">
    <TrackingFunnel contextName="mirror-sync">
      <ErrorBoundary fallbackRender={() => null}>
        {/* Mirror routes */}
        <Routes>
          <Route path="/" element={<AppMirrorPowerup />} />
          <Route path="/console" element={<RedirectTo />} />
          <Route path="/sync-setup" element={<BackOfCardSyncForm />} />
          <Route path="/sister-tasks" element={<BackOfCardSection />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/block-powerup" element={<PowerupBlocker />} />
          <Route path="/unavailable-powerup" element={<PowerUpUnavailable />} />
          <Route path="/field-settings" element={<MirrorSettingsPopup />} />
          <Route path="/blocked-user" element={<BlockedUserPopup />} />
          <Route path="/setup-incomplete" element={<SetupIncompletePopup />} />
          <Route path="/configure-account" element={<ConfigureAccount />} />
          <Route path="/personal-boards" element={<PersonalBoardsModal />} />
          <Route path="/org-revived" element={<OrgRevivedModal />} />
        </Routes>
      </ErrorBoundary>
    </TrackingFunnel>
    <TrackingFunnel contextName="board-sync">
      <ErrorBoundary fallbackRender={() => null}>
        {/* Unito/Board Sync routes */}
        <Routes>
          <Route path="/menu" element={<UnitoReportAndUnitoSyncMenu />} />
          <Route path="/get-help" element={<GetHelpMenu />} />
          <Route path="/project-sync" element={<AppUnitoSyncPowerup />} />
          <Route path="/settings" element={<UnitoSyncSettingsPopup />} />
          <Route path="/trial-expired" element={<TrialExpired />} />
          <Route path="/account" element={<AccountPopup />} />
          <Route path="/discover-mirror" element={<DiscoveryModal type="board-sync" />} />
          <Route path="/discover-board-sync" element={<DiscoveryModal type="mirror" />} />
        </Routes>
      </ErrorBoundary>
    </TrackingFunnel>
    <TrackingFunnel contextName="report-sync">
      <ErrorBoundary fallbackRender={() => null}>
        {/* Unito/Report Sync routes */}
        <Routes>
          <Route path="/report-sync" element={<AppUnitoReportPowerup />} />
        </Routes>
      </ErrorBoundary>
    </TrackingFunnel>
  </Router>,
);
