import React, { useEffect, useState } from 'react';
import { Box, tokens, Typography } from '@unitoio/mimics';

import { INTERNAL_SOURCES, POWERUP_DISPLAY_NAMES } from '../consts';
import withStorage from './withStorage';
import { trackEvent, EVENTS } from '../util/tracking';
import * as Trello from '../util/trello';
import * as Api from '../util/api';
import { getColorMode } from 'src/util/helpers';

function getMenuItems({ t, source, workspaceId, providerName, linkCount, isBoardSync, containerId, profileId }) {
  const isReportAndSync = source === INTERNAL_SOURCES.REPORT;
  const menuItems = [
    {
      name: isReportAndSync ? 'Export board to' : 'Create flow',
      onClick: () =>
        Trello.getModalForUnitoURL(
          t,
          isReportAndSync
            ? Api.getUnitoCreateFlowsRouteForReportSync()
            : Api.getUnitoCreateFlowsRouteForProjectSync({ containerId, profileId }),
        ),
    },
    {
      name: isReportAndSync ? 'My exports' : 'My flows',
      onClick: () => Trello.getModalForUnitoURL(t, Api.getUnitoMyFlowsRoute(workspaceId)),
    },
    {
      name: 'Pricing',
      onClick: () => Trello.getModalForUnitoURL(t, Api.getUnitoPricingRouteForOrgForProjectSync(workspaceId)),
    },
    {
      name: 'Account',
      onClick: () => t.popup({ ...Trello.UNITO_SYNC_ACCOUNT_POPUP, args: { source, providerName } }),
    },
    {
      name: 'Get help',
      onClick: () => t.popup({ ...Trello.UNITO_GET_HELP_POPUP, args: { source, providerName } }),
    },
  ];

  isBoardSync &&
    menuItems.push({
      name: 'Settings...',
      onClick: () => t.popup(Trello.UNITO_SYNC_SETTINGS_POPUP),
    });

  if (linkCount === 0) {
    menuItems.splice(1, 1);
  }
  return menuItems;
}

const UnitoReportAndUnitoSyncMenuComponent = (props) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    async function initMenuItems() {
      const { board, member } = props.t.args[0].context;
      const { linkCount, workspaceId, source, providerName, powerupName } = props.t.args[1];
      const isBoardSync = powerupName === POWERUP_DISPLAY_NAMES.BOARDSYNC;

      const menuItems = getMenuItems({
        t: props.t,
        linkCount,
        containerId: Api.boardToUniqueId(board),
        profileId: member,
        workspaceId,
        source,
        providerName,
        isBoardSync,
      });

      setMenuItems(menuItems);
    }

    initMenuItems();
  }, [props.t]);

  const textColor = getColorMode();

  const handleOnClick = async (itemName, onClick) => {
    const { source, providerName } = props.t.args[1];
    await trackEvent(
      EVENTS.USER_MENU_ACTION,
      { action_name: `clicked on ${itemName}`, selected_tool_name: providerName },
      props.t,
      source,
    );
    onClick();
  };

  return (
    <>
      {menuItems.map((item) => (
        <Box key={item.name} m={[0, 0, tokens.spacing.s3]} onClick={() => handleOnClick(item.name, item.onClick)}>
          <Typography color={textColor}>{item.name}</Typography>
        </Box>
      ))}
    </>
  );
};

export const UnitoReportAndUnitoSyncMenu = withStorage(UnitoReportAndUnitoSyncMenuComponent);
