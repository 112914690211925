export class RequestAllowedDelayExceeded extends Error {
  constructor(delay, endpoint) {
    const message = `Request exceeded allowed delay of ${delay} while calling ${endpoint}`;
    super(message);
  }
}

export class TrelloInstanceNotProvided extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}
