import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { tokens } from '@unitoio/mimics';

import { trackEvent, EVENTS } from '../../util/tracking';
import { associateTrelloTeamWithUnitoOrg, createUnitoOrgForTrelloTeam, getPlanInfo } from '../../util/trello';
import { getWorkspaces, getWorkspaceLinkedToAccountId } from '../../util/api';
import { Title, Text, SelectInput, Button, RadioButtonGroup, RadioButton, LoadingIcon } from '../index';
import { INTERNAL_SOURCES, UNITO_WORKSPACE_STATUSES } from '../../consts';
import withStorage from '../withStorage';
import { getPowerUpName } from '../../util/helpers';

const ConfigureAccountWrapper = styled.div`
  margin: 1rem;
`;

const RadioButtonGroupWrapper = styled.div`
  padding: 2rem 6rem 0rem;
`;

const ButtonWrapper = styled.div`
  float: right;
  padding-right: 2rem;
`;

const LoadingDiv = styled.div`
  text-align: center;
  margin-top: 10rem;
`;

const AlreadyLinkedP = styled.p`
  margin: 2rem 1rem;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 0rem 1rem;
`;

const Alert = styled.div`
  background: ${tokens.colors.background.message.destructive};
  padding: ${tokens.spacing.s3} ${tokens.spacing.s4};
  border-radius: ${tokens.spacing.s2};
  border: 1px solid ${tokens.colors.strokes.message.destructive};
`;

export class ConfigureAccountComponent extends Component {
  options = {
    ASSOCIATE: 'associate',
    CREATE: 'create',
  };

  state = {
    orgLinkedToAccountId: null,
    organizationsIsLoading: true,
    organizations: [],
    selectedOrg: null,
    selectedOption: this.options.ASSOCIATE,
    accountIsBeingSetup: false,
  };

  async componentDidMount() {
    trackEvent(EVENTS.CHOOSE_UNITO_ORG_START);

    const orgLinkedToAccountId = await getWorkspaceLinkedToAccountId(this.props.t);

    let organizations = [];
    let selectedOrg = null;

    if (!orgLinkedToAccountId) {
      ({ organizations, selectedOrg } = await this.fetchOrganizations());
    }

    this.setState({
      orgLinkedToAccountId: orgLinkedToAccountId?.id,
      organizationsIsLoading: false,
      organizations,
      selectedOrg,
    });
  }

  async fetchOrganizations() {
    const organizations = await getWorkspaces(this.props.t);
    const orgsAndPlans = (
      await Promise.all(
        organizations.map(async (org) => {
          let nbMirrorsMax;
          try {
            nbMirrorsMax = (await getPlanInfo(this.props.t, org)).nbMirrorsMax;
          } catch (err) {
            return null;
          }
          return {
            ...org,
            nbMirrorsMax,
          };
        }),
      )
    ).filter((orgAndPlan) => !!orgAndPlan);
    const selectedOrg = organizations.length === 1 ? this.getOptions(organizations)[0] : null;

    return { organizations: orgsAndPlans, selectedOrg };
  }

  getOptions(organizations) {
    return organizations.map((org) => {
      return {
        label: this.getOrgDisplayName(org),
        id: org.id,
      };
    });
  }

  isOrgExpired(org) {
    if (!org) {
      return false;
    }

    return [UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED, UNITO_WORKSPACE_STATUSES.CHURNED].includes(org.status);
  }

  getOrgDisplayName(org) {
    const isMirrorPowerup = this.props.t.arg('source') === INTERNAL_SOURCES.MIRROR;

    if (this.isOrgExpired(org)) {
      return `${org.name} (expired)`;
    }

    return `${org.name} ${
      isMirrorPowerup ? `(includes ${org.nbMirrorsMax ? org.nbMirrorsMax : 'unlimited'} mirrors)` : ''
    }`;
  }

  selectOrg = (org) => {
    this.setState({ selectedOrg: org });
  };

  getSelectedOrgData = () => {
    const { organizations, selectedOrg } = this.state;

    if (!selectedOrg) {
      return null;
    }

    return organizations.find((org) => org.id === selectedOrg.id);
  };

  closeModal = () => {
    this.props.t.closeModal();
  };

  handleOnChange = (newValue) => {
    this.setState({ selectedOption: newValue });
  };

  isSetupButtonDisabled = () => {
    const { selectedOption, selectedOrg } = this.state;

    if (selectedOption === this.options.CREATE) {
      return false;
    }

    return !selectedOrg;
  };

  handleOnClick = async () => {
    const { t } = this.props;
    const { selectedOption, selectedOrg } = this.state;
    const selectedOrgData = this.getSelectedOrgData();
    const isSelectedOrgExpired = this.isOrgExpired(selectedOrgData);

    const trackingProps = {
      settings:
        selectedOption === this.options.CREATE
          ? 'create a new Unito organization'
          : 'add to an exiting Unito organization',
    };

    if (selectedOption === this.options.ASSOCIATE && isSelectedOrgExpired) {
      const thirtyDaysAgo = moment.utc().subtract(30, 'days').toDate();
      trackingProps.expired_more_than_30d = new Date(selectedOrgData.expiredAt) < thirtyDaysAgo;
    }

    this.setState({ accountIsBeingSetup: true });

    if (selectedOption === this.options.CREATE) {
      await createUnitoOrgForTrelloTeam(t);
    } else {
      await associateTrelloTeamWithUnitoOrg(t, selectedOrg.id);
    }

    trackEvent(EVENTS.CHOOSE_UNITO_ORG_SUBMIT, trackingProps, t, undefined, () => t.closeModal());
  };

  getOrganizationExpiredMailTo = () => {
    const { selectedOrg } = this.state;

    const supportAddress = 'support@unito.io';
    const subject = `Trello Power-Up: I'd like to reactivate my trial [${selectedOrg.id}]`;

    return `mailto:${supportAddress}?subject=${subject}`;
  };

  getAccountAlreadyLinkedMailTo = () => {
    const { orgLinkedToAccountId } = this.state;
    const { t } = this.props;

    const { organization: accountId } = t.getContext();
    const supportAddress = 'support@unito.io';
    const subject = 'My Trello workspace is already linked to Unito';
    const body = `My Trello workspace ${accountId} is already linked to Unito organization ${orgLinkedToAccountId}`;

    return `mailto:${supportAddress}?subject=${subject}&body=${body}`;
  };

  getAccountAlreadyLinked = () => {
    const powerUpName = getPowerUpName(this.props.t.arg('source'));
    return (
      <ConfigureAccountWrapper id="AccountAlreadyLinkedDiv">
        <Title type="h2" align="center">
          Uh-oh, this Trello workspace is already linked to Unito
        </Title>
        <AlreadyLinkedP>
          The Trello workspace that contains this board has already been linked to a Unito workspace by another user. In
          order to use {powerUpName} on this board, you'll need to <b>move it to another Trello workspace</b> or{' '}
          <b>contact Unito support</b> to help you get access.
        </AlreadyLinkedP>
        <ButtonRow>
          <Button btnStyle="primary" onClick={() => window.open(this.getAccountAlreadyLinkedMailTo())} size="lg">
            Contact Unito support
          </Button>
        </ButtonRow>
      </ConfigureAccountWrapper>
    );
  };

  getOrgExpiredAlert = () => {
    const { selectedOrg } = this.state;

    if (!selectedOrg || !this.isOrgExpired(this.getSelectedOrgData(selectedOrg))) {
      return null;
    }

    return (
      <Alert level="error" showIcon={false} size="sm" showBorder={false}>
        The workspace you've chosen has an expired subscription. Don't worry, the mirrors and flows that were previously
        set up are still saved, but they've been paused. You can{' '}
        <b>
          <Button btnStyle="darkLink" onClick={() => window.open(this.getOrganizationExpiredMailTo())} size="lg">
            contact Unito support
          </Button>{' '}
          for more information about reactivating.
        </b>
      </Alert>
    );
  };

  getConfirmButtonCopy = () => {
    const { selectedOption } = this.state;

    return selectedOption === this.options.CREATE ? 'Setup your account' : 'Link your team';
  };

  render() {
    const {
      accountIsBeingSetup,
      selectedOption,
      organizations,
      organizationsIsLoading,
      orgLinkedToAccountId,
      selectedOrg,
    } = this.state;

    if (organizationsIsLoading) {
      return (
        <LoadingDiv>
          <LoadingIcon size="24px" />
        </LoadingDiv>
      );
    }

    if (orgLinkedToAccountId) {
      this.props.t.sizeTo(220);
      return this.getAccountAlreadyLinked();
    }

    const powerUpName = getPowerUpName(this.props.t.arg('source'));

    if (!!selectedOrg && this.isOrgExpired(organizations.find((org) => org.id === selectedOrg.id))) {
      this.props.t.sizeTo(520);
    } else {
      this.props.t.sizeTo(420); // 420 BLAZE IT!!!
    }

    return (
      <div>
        <ConfigureAccountWrapper>
          <Title type="h2" style={{ textAlign: 'center', marginBottom: '0rem' }}>
            Link your Trello Team with a Unito organization
          </Title>
          <RadioButtonGroupWrapper>
            <RadioButtonGroup name="orgSelector" onChange={this.handleOnChange} selectedValue={selectedOption}>
              <RadioButton
                name="associateOrg"
                label={<Text type="bold">Add to an existing Unito account</Text>}
                value={this.options.ASSOCIATE}
              >
                <Text>
                  You can use the {powerUpName} Power-Up with any subscription and will be able to create as many flows
                  and mirrors as there are included in your plan.
                </Text>
                <SelectInput
                  isLoading={organizationsIsLoading}
                  name="OrganizationPicker"
                  options={this.getOptions(organizations)}
                  placeholder="Type or select your Unito organization"
                  onChange={this.selectOrg}
                  getOptionValue={(opt) => opt.label}
                  value={selectedOrg}
                />
                {this.getOrgExpiredAlert()}
              </RadioButton>
              <RadioButton
                name="createNewOrg"
                label={<Text type="bold">Create a new Unito organization</Text>}
                value={this.options.CREATE}
                style={{ marginTop: '1rem' }}
              >
                <Text>
                  Start a <b>14 day free trial,</b> and find out which {powerUpName} plan is best for your workflow when
                  you’re ready to subscribe.
                </Text>
              </RadioButton>
            </RadioButtonGroup>
          </RadioButtonGroupWrapper>
        </ConfigureAccountWrapper>
        <ButtonWrapper>
          <Button
            size="lg"
            type="button"
            btnStyle="primary"
            disabled={this.isSetupButtonDisabled() || accountIsBeingSetup}
            submitting={accountIsBeingSetup}
            onClick={this.handleOnClick}
          >
            {accountIsBeingSetup ? (
              <span>
                <LoadingIcon /> Setting up...
              </span>
            ) : (
              this.getConfirmButtonCopy()
            )}
          </Button>
        </ButtonWrapper>
      </div>
    );
  }
}

export const ConfigureAccount = withStorage(ConfigureAccountComponent);
