import React, { useEffect, useRef } from 'react';

import { Box, tokens, Typography } from '@unitoio/mimics';

import withStorage from '../withStorage';
import { trackEvent, EVENTS } from '../../util/tracking';
import { getColorMode } from 'src/util/helpers';

function getMenuItems() {
  return [
    {
      name: 'All resources',
      onClick: () => window.open('https://guide.unito.io/unitos-trello-power-ups ', '_blank'),
    },
    {
      name: 'See roadmap',
      onClick: () => window.open('https://unito.canny.io/ ', '_blank'),
    },
    {
      name: 'Send feedback',
      onClick: () => window.open('https://survey.hsforms.com/198mKi0KYQvWP-effYDZuSA1lrbc', '_blank'),
    },
  ];
}

const GetHelpMenuComponent = ({ t }) => {
  const { source, providerName } = t.args[1];
  const menuItems = getMenuItems();
  const refT = useRef(t);

  useEffect(() => {
    refT.current.sizeTo('#root');
  }, []);

  const textColor = getColorMode();

  const handleOnClick = async (itemName, onClick) => {
    await trackEvent(
      EVENTS.USER_MENU_ACTION,
      { action_name: `clicked on ${itemName}`, selected_tool_name: providerName },
      t,
      source,
    );
    onClick();
  };

  return (
    <>
      {menuItems.map((item) => (
        <Box m={[0, 0, tokens.spacing.s3]} onClick={() => handleOnClick(item.name, item.onClick)}>
          <Typography color={textColor}>{item.name}</Typography>
        </Box>
      ))}
    </>
  );
};

export const GetHelpMenu = withStorage(GetHelpMenuComponent);
