import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, borderRadius, fontSize, fontWeight } from '../../theme';

const Base = styled.span`
  padding: 0.2rem 0.5rem;
  border-radius: ${borderRadius.small};
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.small};
`;

const Primary = styled(Base)`
  color: ${color.dark};
  background-color: ${color.success};
`;

const Warning = styled(Base)`
  color: ${color.dark};
  background-color: ${color.warning};
`;

const Error = styled(Base)`
  color: ${color.dark};
  background-color: ${color.error};
`;

const badgeStyles = {
  primary: Primary,
  warning: Warning,
  error: Error,
};

export default class Badge extends Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(Object.keys(badgeStyles)),
  };

  static defaultProps = {
    type: 'primary',
  };

  render() {
    const { children, type } = this.props;
    const StyleBadge = badgeStyles[type];

    return <StyleBadge>{children}</StyleBadge>;
  }
}
