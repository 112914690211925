import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../theme';

const Wrapper = styled.div`
  input {
    margin-right: 4px;
  }
`;

const Label = styled.label`
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.normal};
  color: ${color.dark};
  display: inline-block;
  padding-left: 15px;
  text-indent: -15px;
  line-height: 0;
`;

const Input = styled.input`
  width: 13px;
  height: 13px;
  position: relative;
`;

export default class Checkbox extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
  };

  render() {
    const { isChecked, handleToggle, children, name } = this.props;
    return (
      <Wrapper>
        <Label>
          <Input name={name} type="checkbox" checked={isChecked} onChange={(e) => handleToggle(e, name)} />
          {children}
        </Label>
      </Wrapper>
    );
  }
}
