import * as Storage from './cache'; // for tests
import { delay } from './helpers';
import { reportException } from './logger';

// In order to always present up-to-date data to the user,
// on each `t.set()` call changing board/card data, Trello will call:
//
//  1. BackOfCardSection's `t.render` callback...
//  2. `back-of-card` init...
//  3. ... which will cause `back-of-card` to call `t.render` callback again
//
// So we'll do three backend calls instead of one. It is wasteful and, most
// importantly, breaks create/delete actions, as Trello rate limits (429) us.
//
// There's a tiny bit of room for optimization at Trello, but most of the
// re-calling is legit so, all things considered, handling it is our job.
// Function-level de-bouncing is not an option, since `back-of-card` init and
// BackOfCardSection live an iframe apart.
// So, here we are with this sessionStorage-based cache / debouncer.

// TODO use Jest __mocks__ folder, https://jestjs.io/docs/en/manual-mocks
const mockSessionStorage = (() => {
  const inMemoryStorage = {};
  return {
    getItem: (key) => inMemoryStorage[key],
    setItem: (key, value) => {
      inMemoryStorage[key] = value;
    },
    removeItem: (key) => {
      delete inMemoryStorage[key];
    },
  };
})();

const getStorage = () => {
  try {
    return window.sessionStorage;
  } catch (err) {
    reportException(err, `Couldn't find sessionStorage - cookies not available`);
  }
};
const STORAGE = process.env.NODE_ENV === 'test' ? mockSessionStorage : getStorage();

export const LOCK_MAX_ATTEMPTS = 20;
export const LOCK_MS_BETWEEN_ATTEMPTS = 200;

// Wrapper for sessionStorage.setItem as it can throw an exception when storage is full
// https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
// If that happens, we log it and keep going without cache!
export function setItem(namespace, items) {
  try {
    STORAGE.setItem(namespace, items);
  } catch (err) {
    reportException(err, `Error in session storage for namespace ${namespace} and items ${items}`);
  }
}

export function getAll(namespace) {
  if (!STORAGE) {
    return {};
  }
  const result = STORAGE.getItem(namespace);
  return result ? JSON.parse(result) : {};
}

export function setAll(namespace, items) {
  if (STORAGE) {
    Storage.setItem(namespace, JSON.stringify(items));
  }
}

export function set(namespace, id, data, ttl) {
  const items = getAll(namespace);
  items[id] = {
    data,
    maxAge: new Date().getTime() + ttl,
  };
  setAll(namespace, items);
}

export function get(namespace, key) {
  const itemsFromStorage = getAll(namespace);
  Storage.evictOldEntries(namespace, itemsFromStorage);
  return itemsFromStorage[key] ? itemsFromStorage[key].data : undefined;
}

export function evictOldEntries(namespace, currentData) {
  const now = new Date().getTime();

  /* eslint-disable no-unused-vars */
  for (const id in currentData) {
    const { maxAge } = currentData[id];
    if (maxAge && now > maxAge) {
      delete currentData[id]; // yes in JS it's fine to delete an object key being iterated on
    }
  }
  Storage.setAll(namespace, currentData);
}

export async function acquireLock(lockName) {
  if (!STORAGE) {
    return;
  }

  for (let i = 0; i < LOCK_MAX_ATTEMPTS; i++) {
    const storageLock = STORAGE.getItem(lockName);
    if (!storageLock) {
      Storage.setItem(lockName, true);
      return;
    }
    await delay(LOCK_MS_BETWEEN_ATTEMPTS);
  }
}

export function releaseLock(lockName) {
  if (!STORAGE) {
    return;
  }

  STORAGE.removeItem(lockName);
}

/**
 * Caches a `fn` closure for a `ttlMs` period of time, using browser
 * `sessionStorage` and making concurrent accesses wait, through a basic lock.
 *
 * - Motivation to use sessionStorage: cross iframes.
 *
 * - Motivation to add (basic) locking: sometimes (e.g. when refreshing the
 *   browser while in a card page), Trello will call us several times in a row
 *   at only a few milliseconds of interval, not giving us time to populate the
 *   cache, and causing repeated calls. So, we use a lock to make these
 *   concurrent requests wait for the first one to finish.
 *
 * @param {Function} fn the function to cache
 * @param {string} namespace the key to use in sessionStorage
 * @param {string} key the cache key
 * @param {number} ttlMs the time, in milliseconds, to expiry
 * @returns your result, fetched from your function call or the cache
 */
export async function sessionStorageWithLock(fn, namespace, key, ttlMs) {
  const lockName = `${namespace}_lock`;
  await Storage.acquireLock(lockName);

  const fromStorage = Storage.get(namespace, key);
  if (fromStorage !== undefined) {
    Storage.releaseLock(lockName);
    return fromStorage;
  }

  let result;
  try {
    result = await Storage.sessionStorageRefresh(fn, namespace, key, ttlMs);
  } finally {
    Storage.releaseLock(lockName);
  }

  return result;
}

/**
 * Force invalidation and re-fetch of a piece of cached data.
 *
 * @param {Function} fn the function to refresh
 * @param {string} namespace the key to use in sessionStorage
 * @param {string} key the cache key
 * @param {number} ttlMs the time, in milliseconds, to expiry
 * @returns your result, fetched from your function call
 */
export async function sessionStorageRefresh(fn, namespace, key, ttlMs) {
  const fromBackend = await fn();
  Storage.set(namespace, key, fromBackend, ttlMs);
  return fromBackend;
}
