import React, { Component } from 'react';

import * as Trello from '../../util/trello';
import * as Api from '../../util/api';
import { getMaestroEmbedUrl } from '../../util/fetch';
import { getPageVisibility } from '../../util/visibility';
import { logger, reportException } from '../../util/logger';
import { formatDate, isFeatureValueUnlimited } from '../../util/helpers';
import { trackEvent, EVENTS } from '../../util/tracking';
import {
  POWERUP_CAPABILITIES,
  UNITO_WORKSPACE_STATUSES,
  TASK_COUNT_FETCH_TIMEOUT,
  INTERNAL_SOURCES,
  POWERUP_DISPLAY_NAMES,
} from '../../consts';
import * as Tracking from '../../util/tracking';
import { getFullUrl, onShowAuthorization } from '../common';
import URL_WHITE_ICON from '../../images/mirror_white.svg';

const { visibilityChange } = getPageVisibility();

export const getStatusDependentItems = (t, accountData) => {
  switch (accountData.status) {
    case UNITO_WORKSPACE_STATUSES.PAYING:
    case UNITO_WORKSPACE_STATUSES.DELINQUENT:
      return [
        {
          text: `Change my Plan (${accountData.planName})`,
          callback: (t) => {
            Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked Change my plan' }, t);
            Trello.getModalForUnitoURL(t, Api.getUnitoPricingRouteForOrg(accountData.workspaceId));
          },
        },
        {
          text: `Payment Information`,
          callback: (t) => {
            Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked Payment Information' }, t);
            Trello.getModalForUnitoURL(t, Api.getUnitoBillingRouteForOrg(accountData.workspaceId));
          },
        },
      ];
    case UNITO_WORKSPACE_STATUSES.TRIALING:
      return [
        {
          text: 'See the Pricing',
          callback: (t) => {
            Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked See the Pricing' }, t);
            Trello.getModalForUnitoURL(t, Api.getUnitoPricingRouteForOrg(accountData.workspaceId));
          },
        },
      ];
    case UNITO_WORKSPACE_STATUSES.CANCELED:
      return [
        {
          text: `Billing (Subscription ends on ${formatDate(accountData.validUntil)})`,
          callback: (t) => {
            Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked Payment Information' }, t);
            Trello.getModalForUnitoURL(t, Api.getUnitoBillingRouteForOrg(accountData.workspaceId));
          },
        },
        {
          text: 'See the plans',
          callback: (t) => {
            Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked See the Pricing' }, t);
            Trello.getModalForUnitoURL(t, Api.getUnitoPricingRouteForOrg(accountData.workspaceId));
          },
        },
      ];
    default:
      return [];
  }
};

export const getMirrorPowerupTitle = (t, syncAccountData, isPowerUpUsableByUser) => {
  const { status } = syncAccountData;
  if (!isPowerUpUsableByUser) {
    return POWERUP_DISPLAY_NAMES.MIRROR;
  }
  const displaySubscriptionStatus = Trello.STRIPE_DISPLAY_STATUSES[status];
  return `Mirror${displaySubscriptionStatus ? ` | ${displaySubscriptionStatus}` : ''}`;
};

/**
 * Unito Mirror Powerup
 * Trello specific powerup which allows users to sync cards
 * directly from one board to another.
 * */
// Warning: if calling trello APIs here, you must:
//
// - Call directly `t.something` using the `t` instance passed as callback first param.
//
// - But if encapsulating your `t.something` calls in a helper, you MUST pass the `t`
//   to the helper. Failure to do and calling `T.iframe()` will result in Trello error:
//
//   "Cannot call TrelloPowerUp.iframe() from your index connector where you call
//    TrelloPowerUp.initialize(). TrelloPowerUp.iframe() is only used for secondary
//    iframes you may create or request from Trello during the Power-Up lifecycle."
export default class AppMirrorPowerup extends Component {
  fetchBackendInterval = [];
  isFirstLoad = true;
  trelloClient = null;

  componentDidMount() {
    this.initializeTrello();
    document.addEventListener(
      visibilityChange,
      () => Trello.handlePageVisibilityChange(this.trelloClient, this.fetchBackendInterval),
      false,
    );
  }

  componentWillUnmount() {
    Trello.stopBackGroundFrontOfCardFetcher(this.fetchBackendInterval);
    document.removeEventListener(visibilityChange, () =>
      Trello.handlePageVisibilityChange(this.trelloClient, this.fetchBackendInterval),
    );
  }

  initializeTrelloWithError = async (error) => {
    const buttonCallbackWithError = async (t) => [
      {
        text: 'Mirror - Error',
        icon: this.getFullUrl(URL_WHITE_ICON),
        callback: async (t) =>
          t.popup({
            title: 'Mirror - Error',
            items: [
              {
                text: error,
                url: 'https://guide.unito.io/how-to-enable-third-party-cookies-for-unito-access',
              },
            ],
          }),
      },
    ];
    await Trello.T.initialize({
      [POWERUP_CAPABILITIES.BOARD_BUTTONS]: buttonCallbackWithError,
      [POWERUP_CAPABILITIES.CARD_BUTTONS]: buttonCallbackWithError,
    });
  };

  initializeTrello = async () => {
    const criticalError = await Trello.getCriticalInitializationError();
    if (criticalError) {
      await this.initializeTrelloWithError(criticalError);
      return undefined;
    }

    const t = await Trello.T.initialize({
      [POWERUP_CAPABILITIES.AUTHORIZATION_STATUS]: Trello.authorizationStatus,
      [POWERUP_CAPABILITIES.BOARD_BUTTONS]: this.onInitializePowerup,
      callback: true, // https://developers.trello.com/v1.0/reference#callback
      [POWERUP_CAPABILITIES.CARD_BUTTONS]: Trello.cardButtonsInit,
      [POWERUP_CAPABILITIES.CARDS_BADGES]: Trello.cardBadgesHandler,
      // We don't use 'card-detail-badges', we just ask for the capability,
      // in case we want to use it in the future. But unlike other capabilities,
      // where asking for a cap. but not implementing it only causes warning
      // `Power-Up <powerUpId> declares capabilities that are not implemented`,
      // this one is called even when undefined, calling error
      // `Power-Up unhandled error responding to request for card-detail-badges`
      // So, we stub it.
      [POWERUP_CAPABILITIES.CARD_DETAIL_BADGES]: () => undefined,
      [POWERUP_CAPABILITIES.CARD_BACK_SECTION]: Trello.cardBackSectionInit,
      [POWERUP_CAPABILITIES.ON_DISABLE]: async (t) => {
        trackEvent(
          EVENTS.USER_MENU_ACTION,
          {
            action_name: 'clicked Disable',
          },
          t,
        );
        return Trello.onDisableMirrorPowerUp(t, this.fetchBackendInterval);
      },
      [POWERUP_CAPABILITIES.ON_ENABLE]: this.onEnablePowerUp,
      // WARN: We don't delete the syncAccountData or the current card syncs will be broken
      // If the account that removed the data was the sync account
      // 'remove-data': this.onRemoveData,
      [POWERUP_CAPABILITIES.SHOW_AUTHORIZATION]: (t) => onShowAuthorization(t, INTERNAL_SOURCES.MIRROR),
      [POWERUP_CAPABILITIES.SHOW_SETTINGS]: Trello.onShowSettings,
    });
    const userId = await t.get('member', 'private', 'unitoUserId');
    if (userId) {
      Tracking.identify(userId);
    }
    Tracking.trackPage();
  };

  onShowBoardSyncPowerup = async (t) => {
    const lists = await t.lists('id');
    const { board, member, organization = '' } = t.args[0].context;
    const canWriteToBoard = t.memberCanWriteToModel('board');
    const title = POWERUP_DISPLAY_NAMES.BOARDSYNC;
    const provider = 'trello';
    const maestroUrl = getMaestroEmbedUrl({
      accountId: organization,
      account_id: organization,
      container_id: Api.boardToUniqueId(board),
      other_provider_name: provider,
      setup: provider,
      user_id: member,
    });

    const listCount = lists.length;
    if (listCount === 0 || !canWriteToBoard) {
      const { message, reason } = !canWriteToBoard
        ? {
            message: 'Whoops! You will need to be a member of this board to activate the Power-Up.',
            reason: 'not member of board',
          }
        : {
            message: 'Whoops! If you want to sync this board, it first needs to have a list.',
            reason: 'board has no list',
          };
      return t.popup({
        title,
        url: './unavailable-powerup',
        args: { message, reason },
      });
    }

    return t.modal({
      fullscreen: true,
      title,
      url: maestroUrl,
    });
  };

  onShowBoardSyncMenu = async (t) => {
    if (!t.isMemberSignedIn()) {
      return [];
    }

    const provider = 'trello';
    const jwtData = await Trello.getJwtData(t);
    const syncAccountData = await Trello.getSyncAccountData(t);

    logger.setContext({
      userId: await t.get('member', 'private', 'unitoUserId'),
      trelloPluginId: jwtData.idPlugin,
      trelloPowerName: `Unito Board Sync ${provider}`,
      nativeContainerId: jwtData.idBoard,
      nativeUserId: jwtData.idMember,
    });

    const openBoardSyncMenuItem = {
      text: 'Open Board Sync',
      callback: (t) => {
        Tracking.trackEvent(
          Tracking.EVENTS.USER_MENU_ACTION,
          { action_name: 'clicked Open Board Sync' },
          t,
          INTERNAL_SOURCES.BOARDSYNC,
        );
        return this.onShowBoardSyncPowerup(t);
      },
    };

    const boardSyncSettingsMenuItem = {
      text: 'Settings...',
      callback: (t) => {
        Tracking.trackEvent(
          Tracking.EVENTS.USER_MENU_ACTION,
          { action_name: 'clicked Settings' },
          t,
          INTERNAL_SOURCES.BOARDSYNC,
        );
        return t.popup({ ...Trello.UNITO_SYNC_SETTINGS_POPUP, args: { source: INTERNAL_SOURCES.MIRROR } });
      },
    };

    const getHelpMenuItem = {
      text: 'Get help',
      callback: () => {
        Tracking.trackEvent(
          Tracking.EVENTS.USER_MENU_ACTION,
          { action_name: 'clicked Get Help' },
          t,
          INTERNAL_SOURCES.BOARDSYNC,
        );
        window.open('https://guide.unito.io/the-mirror-power-up-for-trello', '_blank');
      },
    };

    const unitoSyncButtonMenuItems = [openBoardSyncMenuItem, boardSyncSettingsMenuItem, getHelpMenuItem];

    const defaultScreen = {
      title: 'Edit Board Sync',
      items: unitoSyncButtonMenuItems,
    };

    const screenToDisplay = await Trello.getScreenToDisplay(t, syncAccountData, defaultScreen);
    screenToDisplay.screen.args = { source: INTERNAL_SOURCES.BOARDSYNC };

    return t[screenToDisplay.function](screenToDisplay.screen);
  };

  onInitializePowerup = async (t) => {
    this.trelloClient = t;
    if (!t.isMemberSignedIn()) {
      return [];
    }

    const isPowerUpUsableByUser = await Trello.powerUpIsUsableByUser(t);
    if (isPowerUpUsableByUser) {
      await Trello.refreshToken(t);
      await Trello.replicateOrgDataToBoard(t);
      await Trello.copyUnitoUserIdToMemberScope(t);
      await Trello.fetchAndSetWorkspaceInfo(t);
      // The `board-buttons` capability is unrelated to background fetching, but
      // we (ab)use it anyway for that, because it's the only capability that is:
      //  1. at board-level
      //  2. triggered on load
      //  3. passing a `t` object with Trello.getContext
      // It is wrapped in a try/catch to never fail to return the button.
      //
      // If no syncAccountData.workspaceId that means we were unable to retrieve the org tied
      // to this trello team. don't go any further. user will have to "re-authorize" // Should we do that??
      if (this.isFirstLoad && (await Trello.powerUpHasUnitoWorkspace(t))) {
        this.isFirstLoad = false;
        await Trello.setupBackgroundFrontOfCardFetcher(t, this.fetchBackendInterval);
      }
    }

    const jwtData = await Trello.getJwtData(t);
    const syncAccountData = await Trello.getSyncAccountData(t);

    logger.setContext({
      userId: await t.get('member', 'private', 'unitoUserId'),
      trelloPluginId: jwtData.idPlugin,
      trelloPowerName: POWERUP_DISPLAY_NAMES.MIRROR,
      nativeContainerId: jwtData.idBoard,
      nativeUserId: jwtData.idMember,
      syncAccountData,
    });

    const mirrorPowerupTitle = getMirrorPowerupTitle(t, syncAccountData, isPowerUpUsableByUser);

    const mirrorPowerupButton = {
      text: mirrorPowerupTitle,
      icon: getFullUrl(URL_WHITE_ICON),
      callback: (t) => this.onPowerupButtonClick(t, isPowerUpUsableByUser),
      condition: 'edit',
    };

    // by default the mirror power up only shows the 'Mirror' button on top of the Trello
    // page, but if the user enabled board sync in their settings, we also display the Board Sync button.
    let mirrorPowerupButtons = [mirrorPowerupButton];

    // Enable board sync button if enabled in settings
    const boardSyncEnabledFromMirror = await Trello.is2WayBoardSyncEnabled(t);
    const mirrorEnabledFromUnitoSync = await Trello.is2WayMirrorEnabled(t);

    if (mirrorEnabledFromUnitoSync === undefined) {
      await t.set('board', 'shared', 'unitoSync2WayMirrorEnabled', true);
    }

    if (boardSyncEnabledFromMirror && !mirrorEnabledFromUnitoSync) {
      mirrorPowerupButtons = [];
    }

    if (boardSyncEnabledFromMirror) {
      // when enabled from a mirror context, the unito sync is always a Trello Board Sync
      // so initialize with these values hardcoded.
      const boardSyncPowerup = {
        text: POWERUP_DISPLAY_NAMES.BOARDSYNC,
        icon: window.TrelloPowerUp.util.relativeUrl(`/trello_logo.svg`),
        callback: async (t) => await this.onShowBoardSyncMenu(t),
      };
      mirrorPowerupButtons = [...mirrorPowerupButtons, boardSyncPowerup];
    }

    return mirrorPowerupButtons;
  };

  onPowerupButtonClick = async (t, isPowerUpUsableByUser) => {
    const syncAccountData = await Trello.getSyncAccountData(t);
    let menuItems = [
      {
        text: 'Settings...',
        callback: (t) => {
          Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked Settings' }, t);
          t.popup(Trello.MIRROR_SETTINGS_POPUP);
        },
      },
      ...getStatusDependentItems(t, syncAccountData),
      {
        text: 'Get help',
        callback: (t) => {
          Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'clicked Get help' }, t);
          window.open('https://guide.unito.io/the-mirror-power-up-for-trello', '_blank');
        },
      },
    ];

    if (isPowerUpUsableByUser) {
      try {
        const { taskUsage: nbMirrors, taskLimit: nbMirrorsMax } = await Api.getMirrorCountCached(
          t,
          TASK_COUNT_FETCH_TIMEOUT,
        );
        const mirrorTerm = nbMirrors > 1 ? 'Mirrors' : 'Mirror';
        const mirrorText =
          !nbMirrorsMax || isFeatureValueUnlimited(nbMirrorsMax)
            ? `Unlimited Mirrors`
            : `${nbMirrors} / ${nbMirrorsMax} ${mirrorTerm}`;

        menuItems = [...menuItems, { text: mirrorText }];
      } catch (err) {
        reportException(err, 'Failed to get Mirror count');
      }
    }

    const defaultScreen = {
      title: 'Edit Mirror',
      items: menuItems,
    };

    const screenToDisplay = await Trello.getScreenToDisplay(t, syncAccountData, defaultScreen);
    screenToDisplay.screen.args = { ...screenToDisplay.screen.args, source: INTERNAL_SOURCES.MIRROR };

    if (screenToDisplay.screen.title === defaultScreen.title) {
      Tracking.trackEvent(Tracking.EVENTS.USER_MENU_ACTION, { action_name: 'viewed Menu' }, t);
    }

    return t[screenToDisplay.function](screenToDisplay.screen);
  };

  onCardButtonClick = async (t) => {
    const syncAccountData = await Trello.getSyncAccountData(t);
    const screenToDisplay = await Trello.getScreenToDisplay(t, syncAccountData, Trello.MIRROR_CARD_TO_POPUP);
    screenToDisplay.screen.args = { ...screenToDisplay.screen.args, source: INTERNAL_SOURCES.MIRROR };
    return t[screenToDisplay.function](screenToDisplay.screen);
  };

  onEnablePowerUp = async (t) => {
    Tracking.trackEvent(Tracking.EVENTS.INSTALL_PLUGIN, {}, t);
    if (Trello.powerUpIsUsableByUser(t)) {
      await Trello.forceFrontOfCardsUpdate({ t });
    }
  };

  render() {
    return (
      <div>
        <div>Mirror Power-Up</div>
        <div>Version: {process.env.REACT_APP_VERSION}</div>
      </div>
    );
  }
}
