import React, { Component } from 'react';
import { flushSync } from 'react-dom';
import { Form } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, tokens, Typography } from '@unitoio/mimics';

import { Button, Href, LoadingIcon, SelectInput } from '../index';
import withStorage from '../withStorage';
import { API_ERRORS, MIRROR_CREATION } from '../../consts';
import { getContainers, getLists, getMirrorCountCached, getUnitoPricingRouteForOrg } from '../../util/api';
import * as Flags from '../../util/flags';
import { isFeatureValueUnlimited, getColorMode } from '../../util/helpers';
import { reportWithFunnel } from '../../util/logger';
import { trackEvent, EVENTS } from '../../util/tracking';
import {
  fetchAllOrganizationFlags,
  getContext,
  getSyncAccountData,
  getModalForUnitoURL,
  getBoardSyncPowerUpUrl,
  is2WayBoardSyncEnabled,
} from '../../util/trello';

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GroupBadge = styled(Box)`
  background-color: ${tokens.colors.background.neutral.grey};
  border-radius: 2em;
  color: ${tokens.colors.content.neutral.n40};
  display: inline-block;
  font-size: ${tokens.fontSize.f7};
  font-weight: normal;
  line-height: 1;
  min-width: 1px;
  text-align: center;
`;

const mirrorCreationLogger = reportWithFunnel(MIRROR_CREATION);

class CreateSyncInnerForm extends Component {
  static propTypes = {
    errors: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setErrors: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  state = {
    areUncachedContainersLoaded: false,
    boardSyncEnabled: false,
    boardSyncPowerupUrl: null,
    defaultListName: null,
    isLoadingBoards: false,
    isLoadingLists: false,
    isLoadingMirrorCount: false,
    orgContainers: undefined,
    lists: [],
    providerIdentityId: null,
    selectOpened: false,
    shouldFetchCache: undefined,
  };

  async componentDidMount() {
    mirrorCreationLogger.reportInfo(`START ${MIRROR_CREATION} funnel`, { funnel: { action: 'START' } });
    trackEvent(EVENTS.NEW_SYNC_START, { link: { kind: 'taskSync' } });
    const { providerIdentityId, status, workspaceId, defaultListName } = await getSyncAccountData();
    // we redirect from the board so that users can add it right away to their current board
    const boardSyncPowerupUrl = await getBoardSyncPowerUpUrl();
    const boardSyncEnabled = await is2WayBoardSyncEnabled();
    const allOrganizationFlags = await fetchAllOrganizationFlags(this.props.t);
    const exposeBoardSyncFromMirrorEnabled = allOrganizationFlags?.[Flags.CAN_EXPOSE_BOARD_SYNC_FROM_MIRROR] ?? false;
    const shouldFetchCachedContainers = allOrganizationFlags?.[Flags.SHOULD_FETCH_CACHED_CONTAINERS] ?? false;
    const boardId = (await getContext()).board;
    // React 18 changes the way state changes are batched and it seems to have an impact here.
    // Without flushSync, the providerIdentityId is null when getting it from the state in the
    // fetchContainers call below.
    flushSync(() => {
      this.setState({
        boardId,
        providerIdentityId,
        workspaceId,
        status,
        defaultListName,
        boardSyncPowerupUrl,
        boardSyncEnabled,
        exposeBoardSyncFromMirrorEnabled,
        shouldFetchCache: shouldFetchCachedContainers,
      });
    });
    this.fetchMirrorCount();
    this.fetchContainers();
  }

  async componentDidUpdate(prevProps) {
    // This check is necessary to circumvent a resizing bug
    // when opening the selects
    if (!this.state.selectOpened) {
      this.props.t.sizeTo('#root');
    }
    /** Clear board field if the user selected a cached container that's no longer available  */
    const shouldResetBoardField =
      this.state.shouldFetchCache &&
      prevProps.values?.board &&
      !this.state.orgContainers.children?.find((child) => child.id === prevProps.values.board.value);
    if (shouldResetBoardField) {
      await this.props.setFieldValue('board', null);
    }
  }

  setBoard = async (selectedBoard) => {
    trackEvent(EVENTS.NEW_SYNC_ACTION, {
      action_name: 'selected a Destination board',
    });
    await this.props.setFieldValue('board', selectedBoard);
    await this.props.setFieldValue('list', null);
    await this.fetchLists();
  };

  setList = (selectedList) => {
    trackEvent(EVENTS.NEW_SYNC_ACTION, {
      action_name: 'selected a Destination list',
    });
    this.props.setFieldValue('list', selectedList);
  };

  fetchMirrorCount = async () => {
    this.setState({ isLoadingMirrorCount: true });
    const { taskUsage: nbMirrors, taskLimit: nbMirrorsMax } = await getMirrorCountCached(this.props.t);
    this.setState({ isLoadingMirrorCount: false, nbMirrors, nbMirrorsMax });
  };

  fetchContainers = async () => {
    const { areUncachedContainersLoaded, providerIdentityId, shouldFetchCache } = this.state;
    const getCachedContainers = async () => {
      const cachedContainers = await getContainers(this.props.t, providerIdentityId, shouldFetchCache);
      /** We don't want to overwrite uncached data with cached data */
      if (!areUncachedContainersLoaded) {
        this.setState({ orgContainers: cachedContainers });
      }
    };

    const getUncachedContainers = async () => {
      const uncachedContainers = await getContainers(this.props.t, providerIdentityId);
      this.setState({ orgContainers: uncachedContainers, areUncachedContainersLoaded: true });
    };

    try {
      this.setState({ isLoadingBoards: true });
      /** Hackish solution to get both cached and uncached containers for BB.
       * By default, we'll only get uncached containers.
       */
      const getContainersForOrganization = [getUncachedContainers()];
      if (shouldFetchCache) {
        getContainersForOrganization.push(getCachedContainers());
      }
      await Promise.allSettled(getContainersForOrganization);
    } catch (err) {
      mirrorCreationLogger.reportException(err, 'Error while fetching containers', {
        providerIdentityId,
        unitoOrgId: this.state.workspaceId,
      });
      this.props.setErrors({
        message: API_ERRORS.DefaultCreateError.message,
      });
    } finally {
      this.setState({ isLoadingBoards: false });
    }
  };

  fetchLists = async () => {
    let lists = [];
    const { values } = this.props;

    try {
      /** Guard statement in case we clean the board field before calling this function */
      if (!values.board) {
        return;
      }
      this.setState({ isLoadingLists: true });
      lists = await getLists(this.props.t, this.state.providerIdentityId, values.board.value);
      if (this.props.values.board?.value !== values.board.value) {
        /** The board has changed since we started the call */
        this.setState({ isLoadingLists: false });
        return;
      }
      this.setState({ lists });
    } catch (e) {
      mirrorCreationLogger.reportException(e, `Error while fetching lists for containerId: ${values.board.value}`, {
        providerIdentityId: this.state.providerIdentityId,
        unitoOrgId: this.state.workspaceId,
      });
      this.props.setErrors({
        message: API_ERRORS.DefaultCreateError.message,
      });
    } finally {
      this.setState({ isLoadingLists: false });
    }

    const defaultList = this.getDefaultList(lists);
    if (!defaultList) {
      return;
    }

    const defaultListOption = this.buildListOption(defaultList);
    this.props.setFieldValue('list', defaultListOption);
  };

  getDefaultList = (lists) => {
    const { defaultListName } = this.state;

    if (!defaultListName) {
      return lists[0];
    }

    const defaultList = lists.filter((list) => list.displayName.toLowerCase() === defaultListName.toLowerCase());

    if (defaultList.length) {
      return defaultList[0];
    }

    return lists.sort((list1, list2) => list1.position < list2.position)[0];
  };

  getGroupedBoards = () => {
    const { boardId, orgContainers } = this.state;
    if (!orgContainers || !orgContainers.displayName || !orgContainers.children) {
      return [];
    }

    return [
      {
        label: orgContainers.displayName,
        options: orgContainers.children
          .filter((child) => child.id !== `trello||${boardId}`)
          .map((child) => {
            return {
              label: child.displayName,
              value: child.id,
            };
          }),
      },
    ];
  };

  buildListOption = (list) => ({
    label: list.displayName,
    value: list.id,
  });

  getListOptions = () => {
    return this.state.lists.map(this.buildListOption);
  };

  formatBoardGroupLabel = (group) => {
    return (
      <Group>
        <span>{group.label}</span>
        <GroupBadge p={[tokens.spacing.s1, tokens.spacing.s3]}>{group.options.length}</GroupBadge>
      </Group>
    );
  };

  onSelectOpenBoards = () => {
    trackEvent(EVENTS.NEW_SYNC_ACTION, {
      action_name: 'clicked Arrow to select destination board',
    });
    const [boards] = this.getGroupedBoards();
    if (!boards || boards.options.length === 0) {
      trackEvent(EVENTS.NEW_SYNC_BLOCKED, {
        link: {
          kind: 'taskSync',
        },
        reason: 'no boards',
      });
    }
    this.setState({ selectOpened: true });
  };

  onSelectOpenLists = () => {
    trackEvent(EVENTS.NEW_SYNC_ACTION, {
      action_name: 'clicked Arrow to select a destination list',
    });
    const lists = this.getListOptions();
    if (lists.length === 0) {
      trackEvent(EVENTS.NEW_SYNC_BLOCKED, {
        link: {
          kind: 'taskSync',
        },
        reason: 'no lists',
      });
    }
    this.setState({ selectOpened: true });
  };

  onSelectClose = () => {
    this.setState({ selectOpened: false });
  };

  isMirrorLimitReached = () => {
    const { nbMirrorsMax, nbMirrors } = this.state;

    if (!nbMirrorsMax || isFeatureValueUnlimited(nbMirrorsMax)) {
      return false;
    }

    return nbMirrors >= nbMirrorsMax;
  };

  getBoardFieldPlaceholder = () => {
    const { areUncachedContainersLoaded, isLoadingBoards, orgContainers } = this.state;
    /** If boards aren't loaded yet */
    if (isLoadingBoards && !areUncachedContainersLoaded && !orgContainers) {
      return 'Looking for boards';
    }

    return 'Choose or search for a board';
  };

  getListFieldPlaceholder = () => {
    const { isLoadingLists, lists } = this.state;
    /** If lists aren't loaded yet */
    if (isLoadingLists && !lists.length) {
      return 'Looking for lists';
    }

    return 'Choose or search for a list';
  };

  render() {
    const { errors, isSubmitting, values } = this.props;
    const {
      boardSyncPowerupUrl,
      defaultListName,
      isLoadingMirrorCount,
      isLoadingLists,
      isLoadingBoards,
      workspaceId,
      boardSyncEnabled,
      exposeBoardSyncFromMirrorEnabled,
    } = this.state;
    const mirrorLimitReached = this.isMirrorLimitReached();
    const colorMode = getColorMode();

    return (
      <div>
        <Form>
          <Box m={[tokens.spacing.s4, tokens.spacing.s2]}>
            {/* TODO set initial focus */}
            <Typography color={colorMode}>
              <b>Destination Board</b>
            </Typography>
            <SelectInput
              error={errors.board}
              formatGroupLabel={this.formatBoardGroupLabel}
              isLoading={isLoadingBoards}
              name="board"
              onMenuOpen={this.onSelectOpenBoards}
              onMenuClose={this.onSelectClose}
              onChange={this.setBoard}
              options={this.getGroupedBoards()}
              placeholder={this.getBoardFieldPlaceholder()}
              value={values.board}
            />
          </Box>
          <Box>
            <Typography color={colorMode}>
              <b>Destination List</b>
            </Typography>
            <SelectInput
              disabled={!values.board || !!defaultListName}
              error={errors.list}
              isLoading={!!values.board && isLoadingLists}
              name="list"
              onMenuOpen={this.onSelectOpenLists}
              onMenuClose={this.onSelectClose}
              onChange={this.setList}
              options={this.getListOptions()}
              placeholder={this.getListFieldPlaceholder()}
              value={values.list}
            />
          </Box>
          <Box m={[tokens.spacing.s4, tokens.spacing.s2]}>
            {errors.message && (
              <Typography color={tokens.colors.content.destructive.default}>
                <div dangerouslySetInnerHTML={{ __html: errors.message }} />
              </Typography>
            )}
          </Box>

          <Box m={[tokens.spacing.s4, tokens.spacing.s2]}>
            <Button
              size="block"
              disabled={isSubmitting || !values.list || !!errors.message || mirrorLimitReached || isLoadingMirrorCount}
              submitting={isSubmitting}
              type="submit"
              btnStyle="primary"
            >
              {isSubmitting ? (
                <span>
                  <LoadingIcon /> Mirroring...
                </span>
              ) : (
                'Mirror'
              )}
            </Button>
          </Box>

          <Box m={[tokens.spacing.s4, tokens.spacing.s2]}>
            <hr />
            {/* TODO update href to precise **Card Sync** user guide */}

            {!mirrorLimitReached && (
              <>
                <Typography color={colorMode}>
                  Mirroring a card will duplicate it and keep the copy in sync with the original.{' '}
                  <Href
                    href="https://guide.unito.io/how-to-use-the-mirror-power-up-for-trello"
                    type="subtle"
                    onClick={() =>
                      trackEvent(EVENTS.NEW_SYNC_ACTION, {
                        action_name: 'clicked Learn more',
                      })
                    }
                  >
                    Learn More
                  </Href>
                  .
                </Typography>
                <br />
                {boardSyncPowerupUrl && !boardSyncEnabled && exposeBoardSyncFromMirrorEnabled && (
                  <Typography color={colorMode}>
                    Want to mirror cards or entire boards automatically? Check out{' '}
                    <Href
                      href={boardSyncPowerupUrl}
                      type="subtle"
                      onClick={() =>
                        trackEvent(EVENTS.NEW_SYNC_ACTION, {
                          action_name: 'clicked Board Sync powerup link',
                        })
                      }
                    >
                      Board Sync
                    </Href>
                  </Typography>
                )}
              </>
            )}

            {mirrorLimitReached && (
              <Typography color={tokens.colors.content.destructive.default}>
                You have reached your Mirror limit.{' '}
                <Href onClick={() => getModalForUnitoURL(null, getUnitoPricingRouteForOrg(workspaceId))} type="error">
                  Upgrade your plan
                </Href>
                .
              </Typography>
            )}
          </Box>
        </Form>
      </div>
    );
  }
}

export const BackOfCardSyncInner = withStorage(CreateSyncInnerForm);
