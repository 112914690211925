export const fontFamily = {
  primary: 'Roboto',
  secondary: 'Roboto Condensed',
  monospace: 'Roboto Mono',
};

export const fontWeight = {
  light: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};

export const fontSize = {
  h1: '34px',
  h2: '24px',
  h3: '20px',
  h4: '16px',
  h5: '14px',
  subheading: '18px',
  body: '16px',
  body2: '20px',
  medium: '14px',
  small: '12px',
  button: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
  },
};

export const color = {
  primary: '#5aac44',
  primaryDark: '#3f6f21',
  primaryLightDark: '#519839',
  secondary: '#298FCA',
  secondaryDarker: '#0C3953',
  danger: '#cf513d',
  dangerDark: '#6e2f1a',
  dark: '#17394d',
  darker: '#333333',
  success: '#4fd683',
  warning: '#ff981a',
  error: '#f26f86',
  whisper: '#6b808c',
  lightGray: '#dfe3e5',
  light: '#ffffff',
  purple: '#5954BA',
};

export const borderRadius = {
  small: '2px',
  standard: '3px',
  double: '6px',
};
