import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { ErrorBoundary } from '@unitoio/sherlock';
import { Typography, Button, Checkbox, Box, TypographyVariants, tokens, Link } from '@unitoio/mimics';

import { reportException, reportWithFunnel } from '../../util/logger';
import { trackEvent, EVENTS } from '../../util/tracking';
import * as Trello from '../../util/trello';
import { getAuthRoute, setUserKeepInformedMarketing } from '../../util/api';
import withStorage from '../withStorage';
import URL_UNITO_LOGO from '../../images/Unito_logo.svg';
import { INTERNAL_SOURCES, SIGNUP_LOGIN } from 'src/consts';
import { getColorMode, getPowerUpName } from '../../util/helpers';

const Hr = styled.hr`
  width: 100%;
`;

const LOGO_WIDTH = 60;

const signupLoginLogger = reportWithFunnel(SIGNUP_LOGIN);

/* const StyledGif = styled.img`
  width: 150px;
  height: 150px;
`; */

const StyledLink = styled(Link)`
  color: ${(props) => props.color};
`;

function useInitTracking(t) {
  const refT = useRef(t);
  useEffect(() => {
    signupLoginLogger.reportInfo(`START ${SIGNUP_LOGIN} funnel`, { funnel: { action: 'START' } });
    trackEvent(EVENTS.SIGNUP_START, { label: 'embed-trello' }, refT.current, refT.current.arg('source'));
  }, []);
}

function useGetIsCurrentMemberAdmin() {
  const [isCurrentMemberAdmin, setIsCurrentMemberAdmin] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const isAdmin = await Trello.isCurrentMemberAdmin();
        setIsCurrentMemberAdmin(isAdmin);
      } catch (err) {
        signupLoginLogger.reportException(err, 'Failed to check if current member is admin');
      }
    };

    fetchData();
  }, []);
  return isCurrentMemberAdmin;
}

async function onAuthSuccess(authData, t, keepMeInformed) {
  signupLoginLogger.reportInfo(
    `Successful authentication with type=${authData.type} providerIdentity=${authData.providerIdentityId}`,
  );

  try {
    await Trello.initPowerUpDataOnAuth(authData);
  } catch (err) {
    const context = await Trello.getContext();
    signupLoginLogger.reportException(err, 'An error occured while setting auth data', context);
  }
  try {
    await setUserKeepInformedMarketing(t, keepMeInformed);
  } catch (err) {
    reportException(err, 'Could not opt in or out of marketing emails');
  }

  await t.closePopup();
}

function handleMessage(t, keepInformed) {
  return async function (e) {
    if (e.data.type !== 'PROVIDER_IDENTITY') {
      return;
    }

    signupLoginLogger.reportInfo(
      `Handling authentication from postMessage with data: type=${e.data.type} providerIdentityId=${e.data.providerIdentityId}`,
    );

    if (e.data.token) {
      await onAuthSuccess(e.data, t, keepInformed);
    } else {
      signupLoginLogger.reportException(
        `Token missing from auth child window for providerIdentity ${e.data.providerIdentity}`,
      );
    }
  };
}

function handleStorage(t, keepInformed) {
  return async function (e) {
    if (e.key !== 'authData' || !navigator.userAgent.includes('Electron')) {
      return;
    }

    const authDataFromLocalStorage = window.localStorage.getItem('authData');

    if (authDataFromLocalStorage) {
      const authData = JSON.parse(authDataFromLocalStorage);
      signupLoginLogger.reportInfo(
        `Handling authentication from storage with data: type=${authData.type} providerIdentityId=${authData.providerIdentityId}`,
      );
      window.localStorage.removeItem('authData');
      await onAuthSuccess(authData, t, keepInformed);
    }
  };
}
function useAuthEventListeners(t, keepMeInformed) {
  const refT = useRef(t);
  const messageHandler = handleMessage(refT.current, keepMeInformed);
  const storageHandler = handleStorage(refT.current, keepMeInformed);
  useEffect(() => {
    global.window.addEventListener('storage', storageHandler, false);
    global.window.addEventListener('message', messageHandler, false);

    return () => {
      global.window.removeEventListener('storage', storageHandler, false);
      global.window.removeEventListener('message', messageHandler, false);
    };
  }, [keepMeInformed, messageHandler, storageHandler]);
}

function getPopupTopLeftValues(width, height) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

  const w = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width; // eslint-disable-line
  const h = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height; // eslint-disable-line

  const left = w / 2 - width / 2 + dualScreenLeft;
  const top = h / 2 - height / 2 + dualScreenTop;

  return {
    top,
    left,
  };
}

function createWindowOptions() {
  const width = 1000;
  const height = 785;
  const { left, top } = getPopupTopLeftValues(width, height);
  const options = {
    copyhistory: 'no',
    directories: 'no',
    height,
    left,
    location: 'no',
    scrollbars: 'yes',
    status: 'no',
    toolbar: 'no',
    top,
    width,
  };
  return Object.keys(options)
    .map((key) => `${key}=${options[key]}`)
    .join(',');
}

const openPopupWindow = async (t) => {
  trackEvent(EVENTS.SIGNUP_SUBMIT, { label: 'embed-trello' }, t, t.arg('source'));
  const authUrl = await getAuthRoute(t.arg('source'), t.arg('providerName'));
  const options = createWindowOptions();

  const authPopup = window.open(authUrl, 'auth', `${options},resizable`);
  authPopup && authPopup.focus();
};

const handleCheckboxChange = (checked, t, setKeepMeInformed) => {
  trackEvent(
    EVENTS.SIGNUP_ACTION,
    {
      action_name: `turned Keep me informed of product and company updates ${checked ? 'ON' : 'OFF'}`,
    },
    t,
    t.arg('source'),
  );
  setKeepMeInformed(checked);
};

function useSizeTo(t, isCurrentMemberAdmin /* isLoading */) {
  const refT = useRef(t);

  useEffect(() => {
    refT.current.sizeTo('#root');
  }, [isCurrentMemberAdmin /* isLoading */]);
}

export const AuthComponent = ({ t }) => {
  useInitTracking(t);
  /* const [isLoading, setIsLoading] = useState(true); */
  const isCurrentMemberAdmin = useGetIsCurrentMemberAdmin();
  const [keepMeInformed, setKeepMeInformed] = useState(false);
  useAuthEventListeners(t, keepMeInformed);
  useSizeTo(t, isCurrentMemberAdmin /* isLoading */);

  const colorMode = getColorMode();
  const linkColor = getColorMode('link');
  const powerupSource = t.arg('source');
  const powerupName = getPowerUpName(powerupSource);

  return (
    <>
      <Box m={[tokens.spacing.s4, 0, 0, 0]} justifyContent="center">
        <img
          style={{ width: `${LOGO_WIDTH}px`, height: `${LOGO_WIDTH}px` }}
          width={LOGO_WIDTH}
          height={LOGO_WIDTH}
          src={URL_UNITO_LOGO}
          alt="Unito logo"
        />
      </Box>
      <Box m={[tokens.spacing.s4]}>
        <Typography color={colorMode} align="center" variant={TypographyVariants.BODY2}>
          To use the {powerupName} power up, you'll need to Authorize Unito to access your Trello account.{' '}
          {powerupSource === INTERNAL_SOURCES.MIRROR && (
            <StyledLink
              color={linkColor}
              href="https://guide.unito.io/what-is-mirror"
              isExternalLink
              isDisclaimer
              onClick={() =>
                trackEvent(
                  EVENTS.SIGNUP_ACTION,
                  {
                    action_name: 'clicked Learn more',
                  },
                  t,
                  powerupSource,
                )
              }
            >
              Learn more
            </StyledLink>
          )}
        </Typography>
      </Box>

      <Box m={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}>
        <Button block onClick={() => openPopupWindow(t)} size="sm">
          Authorize
        </Button>
        {isCurrentMemberAdmin && (
          <Checkbox
            onChange={(checked) => handleCheckboxChange(checked, t, setKeepMeInformed)}
            name="keepMeInformed"
            value={keepMeInformed}
            label={
              <Typography color={colorMode} variant={TypographyVariants.BODY2}>
                Keep me informed of product updates
              </Typography>
            }
          />
        )}
      </Box>

      <Hr />
      <Box>
        <Typography color={colorMode} align="center" variant={TypographyVariants.BODY2}>
          By clicking Authorize, you agree to Unito's{' '}
          <StyledLink
            color={linkColor}
            isExternalLink
            isDisclaimer
            href="https://unito.io/terms"
            onClick={() =>
              trackEvent(
                EVENTS.SIGNUP_ACTION,
                {
                  action_name: 'clicked Terms of service',
                },
                t,
                powerupSource,
              )
            }
          >
            Terms of Service
          </StyledLink>{' '}
          and{' '}
          <StyledLink
            color={linkColor}
            href="https://unito.io/privacy"
            isExternalLink
            isDisclaimer
            onClick={() =>
              trackEvent(
                EVENTS.SIGNUP_ACTION,
                {
                  action_name: 'clicked Privacy policy',
                },
                t,
                powerupSource,
              )
            }
          >
            Privacy Policy
          </StyledLink>
          .
        </Typography>
      </Box>
    </>
  );
};

function AuthComponentWithErrorBoundary(props) {
  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error, { componentStack }, errMessageContext) =>
        signupLoginLogger.reportException(error, error.message, { ...errMessageContext, componentStack })
      }
    >
      <AuthComponent {...props} />
    </ErrorBoundary>
  );
}

export const Auth = withStorage(AuthComponentWithErrorBoundary);
