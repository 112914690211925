import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import {
  Box,
  Typography,
  tokens,
  LoadingPlaceholder,
  Icon,
  Link,
  Bold,
  Button,
  HorizontalSeparator,
} from '@unitoio/mimics';

import * as Api from '../util/api';
import withStorage from './withStorage';
import { trackEvent, EVENTS } from '../util/tracking';
import * as Trello from '../util/trello';
import { logger } from '../util/logger';
import { formatNumber, getColorMode } from '../util/helpers';
import { TASK_COUNT_FETCH_TIMEOUT } from 'src/consts';
import { MenuError } from './MenuError';

const PlaceholderMargin = styled(LoadingPlaceholder)`
  margin-right: ${tokens.spacing.s2};
`;

const TRIAL_EXPIRED_MENU_ITEMS = {
  ITEMS_IN_SYNC: 'items in sync',
  ACTIVE_FLOWS: 'active flows',
  CHANGES: 'changes',
  HOURS_SAVED: 'hours saved',
  PLAN: 'Explore pricing and plans',
};
const LOADING_ITEMS = [
  TRIAL_EXPIRED_MENU_ITEMS.ITEMS_IN_SYNC,
  TRIAL_EXPIRED_MENU_ITEMS.ACTIVE_FLOWS,
  TRIAL_EXPIRED_MENU_ITEMS.CHANGES,
];

const StyledLink = styled(Link)`
  color: ${(props) => props.color};
`;

const renderItemsInSync = (usage, textColor, linkColor) => {
  return (
    <>
      <Typography color={textColor} variant={Typography.variants.BODY1}>
        <Bold>{usage}</Bold> {TRIAL_EXPIRED_MENU_ITEMS.ITEMS_IN_SYNC}
      </Typography>
      <StyledLink color={linkColor}>See details</StyledLink>
    </>
  );
};

const renderActiveFlows = (activeLinks, textColor, linkColor) => {
  return (
    <>
      <Typography color={textColor} variant={Typography.variants.BODY1}>
        <Bold>{activeLinks}</Bold> {activeLinks === 1 ? 'active flow' : 'active flows'}
      </Typography>
      <StyledLink color={linkColor}>Go to my flows</StyledLink>
    </>
  );
};

const renderChanges = (changes, textColor, linkColor) => {
  if (!isNaN(changes)) {
    changes = formatNumber(changes);
  }

  return (
    <>
      <Typography color={textColor} variant={Typography.variants.BODY1}>
        <Bold>{changes}</Bold> {TRIAL_EXPIRED_MENU_ITEMS.CHANGES} synced
      </Typography>
      <StyledLink color={linkColor}>See {TRIAL_EXPIRED_MENU_ITEMS.CHANGES}</StyledLink>
    </>
  );
};

const renderHoursSaved = (hoursSaved, textColor) => {
  if (hoursSaved < 1) {
    return;
  }
  return (
    <Typography color={textColor} variant={Typography.variants.BODY1}>
      <Icon name="clock" /> <Bold>{hoursSaved}</Bold> hours saved
    </Typography>
  );
};

function useFetchAccountData(t) {
  const refT = useRef(t);
  const [usage, setUsage] = useState();
  const [changesSynced, setChangesSynced] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [planName, setPlanName] = useState();
  const [workspaceId, setWorkspaceId] = useState();
  const [activeLinks, setActiveLinks] = useState();
  const [hoursSaved, setHoursSaved] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { workspaceId, planName } = await Trello.getSyncAccountData(refT.current);
        const [syncActivity, itemUsage, links, metrics] = await Promise.all([
          Api.getSyncActivity(refT.current, workspaceId),
          Api.getItemsUsage(refT.current, workspaceId),
          Api.getLinkCountCached(refT.current, TASK_COUNT_FETCH_TIMEOUT),
          Api.getMetrics(refT.current, workspaceId),
        ]);
        setWorkspaceId(workspaceId);
        setPlanName(planName);
        setUsage(itemUsage.usage[0].usage);
        setActiveLinks(links);

        setHoursSaved(metrics.stats.hoursSaved);
        setChangesSynced(syncActivity.activity[0].countChangesSynced30d);
      } catch (e) {
        logger.error('Failed to fetch data in trial expired menu', e);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    usage,
    planName,
    isError,
    activeLinks,
    changesSynced,
    hoursSaved,
    workspaceId,
    isLoading,
  };
}

function useSizeTo(t, isLoading) {
  const refT = useRef(t);

  useEffect(() => {
    refT.current.sizeTo('#root');
  }, [isLoading]);
}

const TrialExpiredComponent = ({ t }) => {
  const { source, providerName } = t.args[1];
  const { usage, changesSynced, isError, workspaceId, isLoading, activeLinks, hoursSaved } = useFetchAccountData(t);
  useSizeTo(t, isLoading);

  const textColor = getColorMode();
  const linkColor = getColorMode('link');
  if (isError) {
    return <MenuError t={t} workspaceId={workspaceId} />;
  }
  const handleOnClick = async (itemName) => {
    await trackEvent(
      EVENTS.USER_MENU_ACTION,
      { action_name: `clicked on ${itemName}`, selected_tool_name: providerName },
      t,
      source,
    );

    switch (itemName) {
      case TRIAL_EXPIRED_MENU_ITEMS.ITEMS_IN_SYNC:
        return Trello.getModalForUnitoURL(t, Api.getUnitoAccountItemsInSyncRouteForOrgForProjectSync(workspaceId));
      case TRIAL_EXPIRED_MENU_ITEMS.ACTIVE_FLOWS:
        return Trello.getModalForUnitoURL(t, Api.getUnitoAccountItemsInSyncRouteForOrgForProjectSync(workspaceId));
      case TRIAL_EXPIRED_MENU_ITEMS.CHANGES:
        return Trello.getModalForUnitoURL(t, Api.getUnitoAccountChangesRouteForOrgForProjectSync());
      case TRIAL_EXPIRED_MENU_ITEMS.PLAN:
        return Trello.getModalForUnitoURL(t, Api.getUnitoPricingRouteForOrgForProjectSync(workspaceId));
      default:
        return;
    }
  };

  return (
    <Box p={[tokens.spacing.s2]}>
      <Box m={[0, 0, tokens.spacing.s3]}>
        <Typography color={textColor} variant={Typography.variants.BODY1}>
          <Bold>Summary</Bold>
        </Typography>
      </Box>
      {isLoading ? (
        <>
          {LOADING_ITEMS.map((loadingItem) => {
            return (
              <Box flexDirection="row" alignItems="center" m={[0, 0, tokens.spacing.s3, 0]}>
                <PlaceholderMargin
                  width={tokens.spacing.s9}
                  height={tokens.spacing.s4}
                  borderRadius={tokens.spacing.s4}
                />
                <Typography color={textColor} variant={Typography.variants.BODY1}>
                  {loadingItem}
                </Typography>
              </Box>
            );
          })}
        </>
      ) : (
        <>
          <Box
            m={[0, 0, tokens.spacing.s3, 0]}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() => handleOnClick(TRIAL_EXPIRED_MENU_ITEMS.ITEMS_IN_SYNC)}
          >
            {renderItemsInSync(usage, textColor, linkColor)}
          </Box>
          <Box
            m={[0, 0, tokens.spacing.s3, 0]}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() => handleOnClick(TRIAL_EXPIRED_MENU_ITEMS.ACTIVE_FLOWS)}
          >
            {renderActiveFlows(activeLinks, textColor, linkColor)}
          </Box>
          <Box
            m={[0, 0, tokens.spacing.s3, 0]}
            flexDirection="row"
            justifyContent="space-between"
            onClick={() => handleOnClick(TRIAL_EXPIRED_MENU_ITEMS.CHANGES)}
          >
            {renderChanges(changesSynced, textColor, linkColor)}
          </Box>
          <Box m={[0, 0, tokens.spacing.s4, 0]}>{renderHoursSaved(hoursSaved, textColor)}</Box>
        </>
      )}
      <Box m={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}>
        <HorizontalSeparator />
      </Box>

      <Button onClick={() => handleOnClick(TRIAL_EXPIRED_MENU_ITEMS.PLAN)} block size="sm">
        {TRIAL_EXPIRED_MENU_ITEMS.PLAN}
      </Button>
    </Box>
  );
};

export const TrialExpired = withStorage(TrialExpiredComponent);
