import React, { Component } from 'react';
import styled from 'styled-components';

import { Button } from '../index';
import withStorage from '../withStorage';
import { trackEvent, EVENTS } from '../../util/tracking';

import MirrorPromo from '../../images/promotional_mirror.png';
import BoardSyncPromo from '../../images/promotional_boardsync.png';

const Container = styled.div`
  display: flex;
  padding: 1rem;
`;

const HalfContent = styled.div`
  padding: 1rem;
  width: 50%;
`;

const ContentImage = styled.img`
  width: 100%;
`;

const Title = styled.h1`
  text-align: center;
  margin-top: 1rem;
`;

const CONTENT = {
  'board-sync': {
    img: MirrorPromo,
    project_name: 'projectSync',
    content: () => (
      <>
        <p>
          Syncing your Trello boards is a great way to keep everyone on the same page, but what if you just want to sync
          a single card? <b>With 2-Way Card Mirroring</b>, you can mirror individual cards in just a few clicks without
          affecting any of your other cards.
        </p>
        <p>
          Mirror a Trello card to any other board and both cards will be kept updated automatically. All without having
          to build any new flows. By using both Power-Ups, you can always know you have the right tool for the job.
        </p>
        <i>
          <b>Note</b>: If you’ve already created a flow between two boards, you’ll need to delete it before mirroring
          individual cards between them.
        </i>
      </>
    ),
  },
  mirror: {
    img: BoardSyncPromo,
    project_name: 'taskSync',
    content: () => (
      <>
        <p>
          Mirror is great for getting a single card from one board to another, but with Unito's{' '}
          <b>2-Way Board Syncing</b> Power-Up, you can now mirror entire Trello boards!
        </p>
        <p>
          Create workflows that span teams and boards to maximize collaboration. Build a team master board to
          consolidate multiple Trello cards in one place, dispatch work from a central board, and more!
        </p>
        <i>
          <b>Note</b>: If you’ve already mirrored a card between two boards, you’ll need to delete it before creating a
          flow between them.
        </i>
      </>
    ),
  },
};

class DiscoveryModal extends Component {
  componentDidMount() {
    trackEvent(EVENTS.ONBOARDING_TWO_POWERUPS_START, {}, this.props.t, this.props.type);
  }

  handleOnClick = () => {
    trackEvent(EVENTS.ONBOARDING_TWO_POWERUPS_SUBMIT, {}, this.props.t, this.props.type, () =>
      this.props.t.closeModal(),
    );
  };

  render() {
    return (
      <div>
        <Title>Your Power-Up just got an upgrade!</Title>
        <Container>
          <HalfContent>
            <ContentImage alt="discover unito powerups" src={CONTENT[this.props.type].img} />
          </HalfContent>
          <HalfContent>
            <div>{CONTENT[this.props.type].content()}</div>
            <Button btnStyle="primary" onClick={this.handleOnClick}>
              Got it!
            </Button>
          </HalfContent>
        </Container>
      </div>
    );
  }
}

export default withStorage(DiscoveryModal);
