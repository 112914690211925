// import jwtDeode from 'jwt-decode';

import { INTERNAL_SOURCES } from 'src/consts';
import { reportException } from './logger';
import * as Trello from './trello';

const SOURCE = 'embed-trello';
export const PRODUCT_NAMES = {
  TASK_SYNC: 'taskSync',
  PROJECT_SYNC: 'projectSync',
  REPORT_SYNC: 'reportSync',
};

export const EVENTS = {
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_ACTION: 'SIGNUP_ACTION',
  SIGNUP_SUBMIT: 'SIGNUP_SUBMIT',
  SIGNUP_BLOCKED: 'SIGNUP_BLOCKED',

  CHOOSE_UNITO_ORG_START: 'CHOOSE-UNITO-ORG_START',
  CHOOSE_UNITO_ORG_SUBMIT: 'CHOOSE-UNITO-ORG_SUBMIT',

  USER_MENU_ACTION: 'USER-MENU_ACTION',
  USER_MENU_SETTINGS_ACTION: 'USER-MENU_SETTINGS_ACTION',

  NEW_SYNC_START: 'NEW-SYNC_START',
  NEW_SYNC_ACTION: 'NEW-SYNC_ACTION',
  NEW_SYNC_SUBMIT: 'NEW-SYNC_SUBMIT',
  NEW_SYNC_BLOCKED: 'NEW-SYNC_BLOCKED',

  ONBOARDING_TWO_POWERUPS_START: 'ONBOARDING_TWO-POWERUPS_START',
  ONBOARDING_TWO_POWERUPS_SUBMIT: 'ONBOARDING_TWO-POWERUPS_SUBMIT',

  INSTALL_PLUGIN: 'INSTALL_PLUGIN',

  ALREADY_USED_UNITO_START: 'ALREADY_USED_UNITO_START',
  ALREADY_USED_UNITO_SUBMIT: 'ALREADY_USED_UNITO_SUBMIT',
};

const USER_AGENT = navigator?.userAgent.includes('TrelloDesktop') ? 'TrelloDesktop' : 'Browser';

export const identify = (userId) => window.analytics && window.analytics.identify(userId);

export const trackPage = () => window.analytics && window.analytics.page();

export const getProductNameBySource = (source) => {
  let productName;
  switch (source) {
    case INTERNAL_SOURCES.BOARDSYNC:
      productName = PRODUCT_NAMES.PROJECT_SYNC;
      break;
    case INTERNAL_SOURCES.REPORT:
      productName = PRODUCT_NAMES.REPORT_SYNC;
      break;
    default:
      productName = PRODUCT_NAMES.TASK_SYNC;
  }

  return productName;
};

export const trackEvent = async (eventName, attributes, t, type = 'mirror', callback = () => {}) => {
  try {
    const trello = t || Trello.T.iframe();
    const { workspaceId, planId, status } = await Trello.getSyncAccountData(trello);
    const userId = await Trello.loadSecretSafe('providerIdentityUserId', trello);

    const payload = {
      ...attributes,
      context: {
        user_agent: USER_AGENT,
        source: SOURCE,
        product_name: getProductNameBySource(type),
        user: {
          id: userId,
        },
        organization: {
          id: workspaceId,
          planId,
          status,
        },
      },
    };

    window.analytics && window.analytics.track(eventName, { event_name: eventName, ...payload }, callback);
  } catch (e) {
    reportException(e);
  }
};
