import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { color, fontSize, fontWeight } from '../../theme';

const BaseStyle = styled.div`
  color: ${color.whisper};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.bold};
  line-height: 16px;
  display: block;
`;

const Label = ({ children }) => {
  return <BaseStyle>{children}</BaseStyle>;
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Label;
