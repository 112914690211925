import React, { Component } from 'react';
import { logger, reportException } from 'src/util/logger';

import { getPageVisibility } from '../../util/visibility';
import * as Trello from '../../util/trello';
import * as Api from '../../util/api';
import { getQueryParameter } from '../../util/fetch';
import { trackEvent, EVENTS } from '../../util/tracking';
import { isFeatureValueUnlimited } from '../../util/helpers';
import { POWERUP_CAPABILITIES, TASK_COUNT_FETCH_TIMEOUT, INTERNAL_SOURCES, POWERUP_DISPLAY_NAMES } from '../../consts';
import { getMirrorPowerupTitle, getStatusDependentItems } from '../mirror/AppMirrorPowerup';
import URL_WHITE_ICON from '../../images/mirror_white.svg';
import { onShowAuthorization, getFullUrl, onPowerupButtonClick } from '../common';

const { visibilityChange } = getPageVisibility();

/**
 * Unito/Board Sync Powerup.
 * Embedded version of unito's app served in an iframe.
 *
 * When used within trello, this powerup is called Board Sync (since we sync boards)
 * but if the powerup is created with a provider different than trello,
 * the name will fallback to the more generic Unito Sync.
 * */
export default class AppUnitoSyncPowerup extends Component {
  fetchBackendInterval = [];
  isMirrorAlreadyLoaded = false;
  trelloClient = null;

  getProviderName() {
    const providerName = getQueryParameter('provider');
    if (providerName?.toLowerCase() === 'gitlabcom') {
      return 'gitlab';
    }
    return providerName?.toLowerCase();
  }

  // TODO revisit the approach for finding the powerup name
  // we should be able to user the generic helper getPowerUpName or
  // pass the prop directly
  getPowerUpName() {
    const providerName = this.getProviderName();
    if (providerName?.toLowerCase() === 'trello') {
      return POWERUP_DISPLAY_NAMES.BOARDSYNC;
    }
    return POWERUP_DISPLAY_NAMES.UNITOSYNC;
  }

  getModalTitle() {
    const providerName = this.getProviderName();
    if (providerName?.toLowerCase() === 'trello') {
      return 'Unito Board Sync';
    }
    return this.getPowerUpName();
  }

  onShowMirrorSyncMenu = async (t, syncAccountData, isPowerUpUsableByUser) => {
    let menuItems = [
      {
        text: 'Settings...',
        callback: (t) => {
          trackEvent(EVENTS.USER_MENU_ACTION, { action_name: 'clicked Settings' }, t);
          t.popup({ ...Trello.MIRROR_SETTINGS_POPUP, args: { source: INTERNAL_SOURCES.BOARDSYNC } });
        },
      },
      ...getStatusDependentItems(t, syncAccountData),
      {
        text: 'Get help',
        callback: (t) => {
          trackEvent(EVENTS.USER_MENU_ACTION, { action_name: 'clicked Get help' }, t);
          window.open('https://guide.unito.io/the-mirror-power-up-for-trello', '_blank');
        },
      },
    ];

    if (isPowerUpUsableByUser) {
      try {
        const { taskUsage: nbMirrors, taskLimit: nbMirrorsMax } = await Api.getMirrorCountCached(
          t,
          TASK_COUNT_FETCH_TIMEOUT,
        );
        const mirrorTerm = nbMirrors > 1 ? 'Mirrors' : 'Mirror';
        const mirrorText =
          !nbMirrorsMax || isFeatureValueUnlimited(nbMirrorsMax)
            ? `Unlimited Mirrors`
            : `${nbMirrors} / ${nbMirrorsMax} ${mirrorTerm}`;

        menuItems = [...menuItems, { text: mirrorText }];
      } catch (err) {
        reportException(err, 'Failed to get Mirror count');
      }
    }

    const defaultScreen = {
      title: 'Edit Mirror',
      items: menuItems,
    };

    const screenToDisplay = await Trello.getScreenToDisplay(t, syncAccountData, defaultScreen);
    screenToDisplay.screen.args = { ...screenToDisplay.screen.args, source: INTERNAL_SOURCES.BOARDSYNC };

    return t[screenToDisplay.function](screenToDisplay.screen);
  };

  handleVisibilityChange = () => Trello.handlePageVisibilityChange(this.trelloClient, this.fetchBackendInterval);

  onInitializePowerup = async (t) => {
    this.trelloClient = t;

    if (!t.isMemberSignedIn()) {
      return [];
    }

    const isPowerUpUsableByUser = await Trello.powerUpIsUsableByUser(t);
    if (isPowerUpUsableByUser) {
      await Trello.refreshToken(t);
      await Trello.replicateOrgDataToBoard(t);
      await Trello.copyUnitoUserIdToMemberScope(t);
      await Trello.fetchAndSetWorkspaceInfo(t);
    }

    const text = this.getPowerUpName();
    const provider = this.getProviderName();
    const jwtData = await Trello.getJwtData(t);
    const syncAccountData = await Trello.getSyncAccountData(t);

    logger.setContext({
      userId: await t.get('member', 'private', 'unitoUserId'),
      trelloPluginId: jwtData?.idPlugin,
      trelloPowerName: `${text} ${provider}`,
      nativeContainerId: jwtData?.idBoard,
      nativeUserId: jwtData?.idMember,
      syncAccountData,
    });

    const unitoSyncPowerupButton = {
      text: this.getPowerUpName(),
      callback: async (t) => onPowerupButtonClick(t, this.getPowerUpName(), this.getProviderName()),
      icon: getFullUrl(`/unito_logo_heavy_stroke.svg`),
      condition: 'edit',
    };

    // by default the unito sync power up only shows the 'Unito/Board Sync' button on top of the Trello
    // page, but if the user enabled mirror sync in their settings, we also display the Mirror button.
    // Enable board sync button if enabled in settings
    const boardSyncEnabledFromMirror = await Trello.is2WayBoardSyncEnabled(t);
    const mirrorEnabledFromUnitoSync = await Trello.is2WayMirrorEnabled(t);
    let unitoSyncPowerupButtons =
      mirrorEnabledFromUnitoSync && !boardSyncEnabledFromMirror ? [] : [unitoSyncPowerupButton];

    if (!mirrorEnabledFromUnitoSync && this.isMirrorAlreadyLoaded) {
      this.isMirrorAlreadyLoaded = false;
      document.removeEventListener(visibilityChange, this.handleVisibilityChange);
      await Trello.onDisableMirrorPowerUp(t, this.fetchBackendInterval); // can't be called from the settings popup as the interval array is needed
    }

    if (mirrorEnabledFromUnitoSync) {
      const mirrorPowerupButton = {
        text: getMirrorPowerupTitle(t, syncAccountData, isPowerUpUsableByUser),
        icon: window.TrelloPowerUp.util.relativeUrl(URL_WHITE_ICON),
        callback: async (t) => await this.onShowMirrorSyncMenu(t, syncAccountData, isPowerUpUsableByUser),
        condition: 'edit',
      };
      if (!this.isMirrorAlreadyLoaded && isPowerUpUsableByUser) {
        this.isMirrorAlreadyLoaded = true;
        await Trello.setupBackgroundFrontOfCardFetcher(t, this.fetchBackendInterval);
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      }
      unitoSyncPowerupButtons = [...unitoSyncPowerupButtons, mirrorPowerupButton];
    }

    return unitoSyncPowerupButtons;
  };

  componentDidMount() {
    const providerName = getQueryParameter('provider');
    window.TrelloPowerUp.initialize({
      [POWERUP_CAPABILITIES.AUTHORIZATION_STATUS]: Trello.authorizationStatus,
      [POWERUP_CAPABILITIES.ON_DISABLE]: async (t) => {
        trackEvent(
          EVENTS.USER_MENU_ACTION,
          {
            action_name: 'clicked Disable',
            tool_name: providerName,
          },
          t,
          INTERNAL_SOURCES.BOARDSYNC,
        );

        return Trello.onDisableMirrorPowerUp(t, this.fetchBackendInterval, true);
      },
      [POWERUP_CAPABILITIES.ON_ENABLE]: (t) => {
        return trackEvent(
          EVENTS.INSTALL_PLUGIN,
          {
            tool_name: providerName,
          },
          t,
          INTERNAL_SOURCES.BOARDSYNC,
        );
      },
      [POWERUP_CAPABILITIES.SHOW_AUTHORIZATION]: async (t) => onShowAuthorization(t),
      [POWERUP_CAPABILITIES.BOARD_BUTTONS]: async (t) => this.onInitializePowerup(t),
      [POWERUP_CAPABILITIES.CARD_BUTTONS]: async (t) => {
        const mirrorEnabledFromUnitoSync = await Trello.is2WayMirrorEnabled(t);

        if (!mirrorEnabledFromUnitoSync) {
          return undefined;
        }
        return Trello.cardButtonsInit(t);
      },
      [POWERUP_CAPABILITIES.CARDS_BADGES]: async (t) => {
        const mirrorEnabledFromUnitoSync = await Trello.is2WayMirrorEnabled(t);

        if (!mirrorEnabledFromUnitoSync) {
          return undefined;
        }
        return Trello.cardBadgesHandler(t);
      },
      // We don't use 'card-detail-badges', we just ask for the capability,
      // in case we want to use it in the future. But unlike other capabilities,
      // where asking for a cap. but not implementing it only causes warning
      // `Power-Up <powerUpId> declares capabilities that are not implemented`,
      // this one is called even when undefined, calling error
      // `Power-Up unhandled error responding to request for card-detail-badges`
      // So, we stub it.
      [POWERUP_CAPABILITIES.CARD_DETAIL_BADGES]: () => undefined,
      [POWERUP_CAPABILITIES.CARD_BACK_SECTION]: async (t) => {
        const mirrorEnabledFromUnitoSync = await Trello.is2WayMirrorEnabled(t);

        if (!mirrorEnabledFromUnitoSync) {
          return undefined;
        }
        return Trello.cardBackSectionInit(t);
      },
      [POWERUP_CAPABILITIES.SHOW_SETTINGS]: Trello.onShowSettings,
    });
  }

  componentWillUnmount() {
    Trello.stopBackGroundFrontOfCardFetcher(this.fetchBackendInterval);
  }

  render() {
    return (
      <div>
        <div>Board Sync Power-Up</div>
        <div>Version: {process.env.REACT_APP_VERSION}</div>
      </div>
    );
  }
}
