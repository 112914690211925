import React, { Component } from 'react';
import styled from 'styled-components';

import { Box, Button, tokens } from '@unitoio/mimics';

import { trackEvent, EVENTS } from '../util/tracking';
import { ORG_REVIVED_MODAL_TYPES } from '../consts';
import withStorage from './withStorage';

const Title = styled.p`
  font-weight: ${tokens.fontWeight.fw7};
  font-size: ${tokens.fontSize.f4};
`;

const Text = styled.p`
  line-height: ${tokens.lineHeight.lh3};
`;

const ButtonWrapper = styled.div`
  align-self: flex-end;
`;

class OrgRevivedModalComponent extends Component {
  async componentDidMount() {
    const { t } = this.props;
    const modalType = t.arg('modalType');
    await trackEvent(EVENTS.ALREADY_USED_UNITO_START, {
      expired_more_than_30d: modalType === ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG,
    });
  }

  getOrganizationExpiredMailTo = () => {
    const { t } = this.props;
    const expiredOrgId = t.arg('expiredOrgId');

    const supportAddress = 'support@unito.io';
    const subject = `Trello Power-Up: I'd like to reactivate my trial [${expiredOrgId}]`;

    return `mailto:${supportAddress}?subject=${subject}`;
  };

  handleOnClick = async (modalType) => {
    const { t } = this.props;
    await trackEvent(
      EVENTS.ALREADY_USED_UNITO_SUBMIT,
      { expired_more_than_30d: modalType === ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG },
      t,
      undefined,
      () => {
        if (modalType === ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG) {
          t.closeModal();
        }
      },
    );

    if (modalType === ORG_REVIVED_MODAL_TYPES.ADDED_TO_EXPIRED_ORG) {
      window.open(this.getOrganizationExpiredMailTo());
    }
  };

  render() {
    const { t } = this.props;

    const modalType = t.arg('modalType');

    const title =
      modalType === ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG
        ? '14-day free trial unlocked!'
        : 'Looks like this Trello team has already used Unito';
    const text =
      modalType === ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG ? (
        <>
          Somone in this Trello team has already used one of Unito’s Power-Ups and the subscription has expired. Don’t
          worry, we gave <b>you a new 14-day trial</b>. Any mirrors and flows that were previously set up have been
          paused but you will be able to turn them back on by activating their auto-sync toggle. You can find{' '}
          <a
            href="https://guide.unito.io/what-happens-when-you-join-a-workspace-with-an-expired-trial"
            target="_blank"
            rel="noopener noreferrer"
          >
            more information about reactivating an account
          </a>{' '}
          on our guide.
        </>
      ) : (
        <>
          Someone in this Trello team <b>has already used one of Unito’s power-ups and the subscription has expired</b>.
          Don’t worry, the mirrors and flows that were previously set up are still saved, but they’ve been paused. You
          can contact Unito support below for more information about reactivating.
        </>
      );

    const btnCopy =
      modalType === ORG_REVIVED_MODAL_TYPES.REVIVED_EXPIRED_ORG ? 'Start syncing' : 'Contact Unito support';

    if (modalType === ORG_REVIVED_MODAL_TYPES.ADDED_TO_EXPIRED_ORG) {
      t.sizeTo(300);
    }

    return (
      <Box flexDirection="column" alignItems="center" m={[tokens.spacing.s6, tokens.spacing.s8]}>
        <Title>{title}</Title>
        <Box m={[tokens.spacing.s4, 0]}>
          <Text>{text}</Text>
        </Box>
        <ButtonWrapper>
          <Button onClick={async () => this.handleOnClick(modalType)} size="sm" variant="secondary">
            {btnCopy}
          </Button>
        </ButtonWrapper>
      </Box>
    );
  }
}

export const OrgRevivedModal = withStorage(OrgRevivedModalComponent);
