import { PLAN_FEATURE_UNLIMITED, INTERNAL_SOURCES, POWERUP_DISPLAY_NAMES } from '../consts';
import { tokens } from '@unitoio/mimics';

export const delay = (ms) =>
  new Promise((res, rej) => {
    let timeout = setTimeout(() => {
      res();
      clearTimeout(timeout);
    }, ms);
  });

export const parseQueryString = (queryString) => {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    const pairKey = decodeURIComponent(pair[0]);
    const pairValue = parseJSON(decodeURIComponent(pair[1] || ''));
    query[pairKey] = pairValue;
  }
  return query;
};

const parseJSON = (str) => {
  let parsedStr;
  try {
    parsedStr = JSON.parse(str);
  } catch (e) {
    return str;
  }
  return parsedStr;
};

export const formatDate = (dateString, { longFormat } = {}) => {
  // Check if dateString is a valid date object
  if (isNaN(Date.parse(dateString))) {
    return;
  }

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  if (!longFormat) {
    return new Date(dateString).toISOString().slice(0, 10).replace(/-/g, '/');
  }

  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const isFeatureValueUnlimited = (featureValue) =>
  typeof featureValue === 'string' && featureValue.toLowerCase() === PLAN_FEATURE_UNLIMITED;

export const hasAccessToPlanFeature = (feature) => {
  const featureValue = feature?.limit || feature?.value;
  // consider non boolean values as false
  // TODO handle numerical limits with usage
  return isFeatureValueUnlimited(featureValue) || (typeof featureValue === 'boolean' ? featureValue : false);
};

export const getColorMode = (element) => {
  const htmlElement = document.documentElement;
  if (htmlElement && htmlElement.hasAttribute('data-color-mode')) {
    if (htmlElement.getAttribute('data-color-mode') === 'light') {
      switch (element) {
        case 'link':
          return tokens.colors.content.info.default;
        default:
          return tokens.colors.content.neutral.n40;
      }
    }
    switch (element) {
      case 'link':
        return tokens.colors.content.info.disabled;
      default:
        return '#8B98A7';
    }
  }
};

export const getPowerUpName = (sourceName) => {
  if (sourceName === INTERNAL_SOURCES.MIRROR) {
    return POWERUP_DISPLAY_NAMES.MIRROR;
  }
  if (sourceName === INTERNAL_SOURCES.REPORT) {
    return POWERUP_DISPLAY_NAMES.REPORT;
  }
  return POWERUP_DISPLAY_NAMES.BOARDSYNC;
};

export const formatNumber = (num) => {
  if (num % 100 === 0 && num >= 1000) {
    if (num % 1000 === 0) {
      return (num / 1000).toFixed(0) + 'k';
    }
    return (num / 1000).toFixed(1) + 'k';
  } else if (num > 1100) {
    return num.toLocaleString();
  } else {
    return num;
  }
};
