import React, { Component } from 'react';
import styled from 'styled-components';

import { trackEvent, EVENTS } from '../../util/tracking';
import { Button, Href, Text, Title } from '../index';
import withStorage from '../withStorage';
import CREATE_TRELLO_TEAM_IMG from '../../images/mirror_createteam.svg';
import { getPowerUpName } from '../../util/helpers';

const MainContainer = styled.div`
  margin: 1.8em 1.5em;
`;

const EmojiWrapper = styled.span`
  font-size: 18px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const FlexItem = styled.div`
  flex: 1;
  padding: 1em;
`;

const InnerSection = styled.div`
  margin-bottom: 2em;
`;

const Spacer = styled.div`
  margin-bottom: 1em;
`;

const CenteredImg = styled.img`
  display: block;
  margin: auto;
`;

export default class PersonalBoardsModalComponent extends Component {
  async componentDidMount() {
    trackEvent(EVENTS.SIGNUP_START, { label: 'embed-trello' }, this.props.t, this.props.t.arg('source'));
    trackEvent(EVENTS.SIGNUP_BLOCKED, { reason: 'personal board' }, this.props.t, this.props.t.arg('source'));
  }

  render() {
    const powerupName = getPowerUpName(this.props.t.arg('source'));
    return (
      <MainContainer>
        <Title type="h1" align="center">
          We have good news and bad news
        </Title>
        <FlexContainer>
          <FlexItem>
            <CenteredImg src={CREATE_TRELLO_TEAM_IMG} alt="Mirror by Unito" />
          </FlexItem>
          <FlexItem>
            <InnerSection>
              <Title type="h5">
                <EmojiWrapper role="img" aria-label="cry">
                  {/* eslint-disable jsx-a11y/accessible-emoji */}
                  😢
                </EmojiWrapper>
                {powerupName} for Personal Boards is on its way...
              </Title>
              <Spacer />
              <Text>
                We're working on it but our developers need to make some changes to get {powerupName} working properly
                on Personal Boards
              </Text>
            </InnerSection>
            <InnerSection>
              <Title type="h5">
                <EmojiWrapper role="img" aria-label="smile">
                  {/* eslint-disable jsx-a11y/accessible-emoji */}
                  😄
                </EmojiWrapper>
                It's easy to try {powerupName} on a Team board!
              </Title>
              <Spacer />
              <Text>
                If you{' '}
                <Href href="https://trello.com/en/guide/create-a-team" fontWeight="bold">
                  create a team
                </Href>{' '}
                and add the {powerupName} Power-Up to a Team Board, you can take it for a test drive and be ready for
                when we support Personal Boards really soon!
              </Text>
            </InnerSection>
            <FlexContainer>
              <FlexItem />
              <FlexItem />
              <FlexItem>
                <Button type="button" btnStyle="primary" onClick={() => this.props.t.closeModal()}>
                  Sounds good
                </Button>
              </FlexItem>
            </FlexContainer>
          </FlexItem>
        </FlexContainer>
      </MainContainer>
    );
  }
}

export const PersonalBoardsModal = withStorage(PersonalBoardsModalComponent);
