import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from '@unitoio/mimics';

import { SYNC_STATES, UNITO_WORKSPACE_STATUSES } from '../../consts';
import URL_GRAY_ICON from '../../images/unito-mirror-powerup-logo.svg';
import { Badge, Button, Href } from '../index';
import { color, fontSize, fontWeight } from '../../theme';
import { getColorMode } from '../../util/helpers';

const LogoWrapper = styled.div`
  float: left;
  border-radius: 4px;
  background-color: ${color.lightGray};
  padding: 1rem;
  margin-right: 0.7rem;
`;

const Status = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  color: ${color.whisper};
`;

const Section = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const WhisperWrapper = styled.span`
  color: ${color.whisper};
`;

export default class CardSyncItem extends Component {
  static propTypes = {
    boardName: PropTypes.string,
    destinationContainerUniqueId: PropTypes.string.isRequired,
    linkId: PropTypes.string.isRequired,
    listName: PropTypes.string,
    removeCard: PropTypes.func.isRequired,
    state: PropTypes.oneOf(Object.keys(SYNC_STATES)).isRequired,
    openChoosePlanWindow: PropTypes.func.isRequired,
    openBillingPage: PropTypes.func.isRequired,
    subscriptionStatus: PropTypes.oneOf(Object.values(UNITO_WORKSPACE_STATUSES)),
    url: PropTypes.string,
  };

  getStatusPhrase() {
    const { state, subscriptionStatus } = this.props;
    const isPaused = state === 'paused';

    if (!isPaused) {
      return;
    }

    let statusPhrase;
    switch (subscriptionStatus) {
      case UNITO_WORKSPACE_STATUSES.CANCELED:
        statusPhrase = 'Account canceled - ';
        break;
      case UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED:
        statusPhrase = 'Trial expired - ';
        break;
      case UNITO_WORKSPACE_STATUSES.DELINQUENT:
        statusPhrase = 'Last payment failed - ';
        break;
      default:
        statusPhrase = '';
    }

    return <WhisperWrapper>{statusPhrase}</WhisperWrapper>;
  }

  getCTAs() {
    const {
      state,
      subscriptionStatus,
      linkId,
      destinationContainerUniqueId,
      openChoosePlanWindow,
      openBillingPage,
      removeCard,
    } = this.props;
    const isPaused = state === 'paused';

    let cta = () => removeCard(linkId, destinationContainerUniqueId);
    let ctaPhrase = 'Remove Mirror';
    if (isPaused) {
      if (
        subscriptionStatus === UNITO_WORKSPACE_STATUSES.CANCELED ||
        subscriptionStatus === UNITO_WORKSPACE_STATUSES.TRIAL_EXPIRED
      ) {
        cta = openChoosePlanWindow;
        ctaPhrase = 'Choose a plan';
      } else if (subscriptionStatus === UNITO_WORKSPACE_STATUSES.DELINQUENT) {
        cta = openBillingPage;
        ctaPhrase = 'Update my Payment information';
      }
    }

    return (
      <Button btnStyle="link" onClick={cta}>
        {ctaPhrase}
      </Button>
    );
  }

  render() {
    const { boardName, listName, state, url } = this.props;
    const LOGO_WIDTH = 50;
    const colorMode = getColorMode();

    return (
      <div>
        <LogoWrapper>
          <img
            style={{ width: `${LOGO_WIDTH}px`, height: `${LOGO_WIDTH}px` }}
            width={LOGO_WIDTH}
            height={LOGO_WIDTH}
            src={URL_GRAY_ICON}
            alt="Mirror by Unito"
          />
        </LogoWrapper>
        <div>
          <Section>
            <Typography variant="body" color={colorMode}>
              Mirrored with {url ? <Href href={url}>{boardName}</Href> : boardName} in {listName} List
            </Typography>
            {this.getStatusPhrase()}
            {this.getCTAs()}
          </Section>
          <Section>
            <Status>Status</Status>
            <div>
              <Badge type={SYNC_STATES[state].type}>{SYNC_STATES[state].displayName}</Badge>
            </div>
          </Section>
        </div>
      </div>
    );
  }
}
